<template>
  <div class="field">
    <label class="label">{{ label }}</label>
    <div class="control">
      <input
        class="input"
        type="number"
        v-model="content"
        :placeholder="placeholder"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    placeholder: String
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    }
  },
  data() {
    return {
      content: this.value
    };
  },
};
</script>

<style scoped>
</style>