<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <input
      class="input dropdown-input"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
      v-bind:class="inputClasses"
      autocomplete="off"
    />

    <!-- Dropdown Menu -->
    <div class="is-fullwidth dropdown-content" v-show="optionsShown">
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        <div class="columns">
          <div class="column is-1">{{ option.currency }}</div>
          <div class="column is-3 has-text-weight-bold">{{ option.symbol }}</div>
          <div class="column is-6">{{ option.name }}</div>
          <div class="column is-2">{{ option.exchange }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  template: "Dropdown",
  props: {
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: "Options of dropdown. An array of options with id and name",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: "Max items showing",
    },
    inputClasses: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: "",
    };
  },
  created() {
    this.$emit("selected", this.selected);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, "ig");
      for (const option of this.options) {
        if (
          this.searchFilter.length < 1 ||
          option.name.match(regOption) ||
          option.symbol.match(regOption)
        ) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit("selected", this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    reset() {
      this.selected.symbol = "";
      this.exit();
    },
    exit() {
      if (!this.selected.symbol) {
        this.selected = {};
        this.searchFilter = "";
      } else {
        this.searchFilter = this.selected.name;
      }
      this.optionsShown = false;
      this.$emit("selected", this.selected);
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0]) {
        this.selectOption(this.filteredOptions[0]);
      }
    },
  },
  watch: {
    searchFilter() {
      this.$emit("filter", this.searchFilter);
    },
  },
};
</script>


<style scoped>
.dropdown {
  position: relative;
  display: block;
  margin: auto;
}

.dropdown > .dropdown-input {
  /* background: #fff;
  cursor: pointer;
  border: 1px solid #e7ecf5;
  border-radius: 3px;
  color: #333;
  display: block;
  font-size: 0.8em;
  padding: 6px;
  min-width: 250px;
  max-width: 250px; */
}
.dropdown > .dropdown-input:hover {
  background: #f8f8fa;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  width: 100%;
  border: 1px solid #e7ecf5;
  box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  z-index: 1;
}
.dropdown-content > .dropdown-item {
  color: black;
  font-size: 0.7em;
  line-height: 1em;
  padding: 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content > .dropdown-item:hover {
  background-color: #e7ecf5;
}
.dropdown:hover .dropdowncontent {
  display: block;
}
</style>