/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios'
// import { getInstance } from "./../auth/index";
// const authService = getInstance();
import Vue from 'vue'

//import { AuthService } from './zune.auth.service'
import { ZUNE_API_URL } from '../.env'

export class Http {
  constructor (status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL: ZUNE_API_URL
    })

    return this.init()
  }

  init () {
    if (this.isAuth) {
      this.instance.interceptors.request.use(request => {
        return new Vue().$auth.getTokenSilently()
          .then(token => {
            request.headers.authorization = `Bearer ${token}`
            return request;
          }).catch(error => Promise.reject(error));
      }, error => {
        return Promise.reject(error)
      })
    }

    return this.instance
  }
}
