import { Http } from './http.init'
import { ErrorWrapper, ResponseWrapper } from './http.execution'
import { ZUNE_WS_URL } from '../.env'
import { Cache } from './infrastructure/cache'

export class Zune {

  static async trace (route) {
    try {
      const response = await new Http({ auth: true }).get(`trace/${route}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async profile () {
    try {
      const response = await new Http({ auth: true }).get(`profile`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static connect(handlers, session) {
    const wsConnection = new WebSocket(`${ZUNE_WS_URL}ws/QuotesWS?session=` + session)
    wsConnection.onmessage = handlers.onmessage;
    wsConnection.onopen = handlers.onopen;
    wsConnection.onclose = handlers.onclose;
    wsConnection.onerror = handlers.onerror;
    return wsConnection;
  }

  static async fileUpload (files, config) {
    try {
      const response = await new Http({ auth: true }).post(`fileUpload`, files, config)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async portfolio () {
    try {
      const response = await new Http({ auth: true }).get(`portfolio`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async portfolioStats () {
    try {
      const response = await new Http({ auth: true }).get(`portfoliostats`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async portfolioAllocation () {
    try {
      const response = await new Http({ auth: true }).get(`portfoliostats/allocation`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async portfolioHoldingStats () {
    try {
      const response = await new Http({ auth: true }).get(`portfoliostats/performance/12-months`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async publicPortfolio (id) {
    try {
      const response = await new Http({ auth: false }).get(`public/portfolio/${id}`);
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async publicStockList() {
    const europeanDividendStocks = [
      "RDS.A", "UN", "NVS", "NSRGF", "RHHBY", "TOT", "SNY", "SIEGY", "LVMHF", "AZN",
      "SAN", "ENLAY", "BASFY", "NVO", "TEF", "BMWYY", "IMBBY", "GSK", "BTI", "RBS"
    ]
    
    try {
      const response = await new Http({ auth: false }).get(`public/list/${europeanDividendStocks}`);
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  // SCREENER
  static async stockScreener (region, params) {
    try {
      const response = await new Http({ auth: false }).get(`screener/${region}`, { params });
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async stockScreenerMeta (region) {
    try {
      const response = await new Http({ auth: false }).get(`screener/${region}/meta`);
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  // END SCREEENER

  static async gainLossChart () {
    try {
      const response = await new Http({ auth: true }).get(`portfolio/gainLossChart`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async growth (period) {
    try {
      const response = await new Http({ auth: true }).get(`portfolio/growthchart/${period}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async publicgrowth (id, period) {
    try {
      const response = await new Http({ auth: false }).get(`public/portfolio/growthchart/${id}/${period}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async currencyExchangeRate (from, to) {
    try {

      var url = `exchangerate/${from}/${to}`
      var fromCache = Cache.get(url)

      if (fromCache) {
        return fromCache
      }

      const response = await new Http({ auth: true }).get(`exchangerate/${from}/${to}`)
      const wrappedResponse = new ResponseWrapper(response, response.data)

      Cache.set(url, wrappedResponse, wrappedResponse.data, wrappedResponse.data.secondsToExpire)

      return wrappedResponse;
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async savePortfolio (id) {
    try {
      const response = await new Http({ auth: true }).get(`portfolio/${id}/save`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async transactions (id, ticker) {
    try {
      const response = await new Http({ auth: true }).get(`portfolioholding/${id}/${ticker}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async dividends (period, upcoming) {
    try {
      const response = await new Http({ auth: true }).get(`portfolio/dividends/${period}`, { upcoming })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async updateCash (portfolio, cashHolding) {
    try {
      const response = await new Http({ auth: true }).post(`portfoliocash/${portfolio}`, cashHolding)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async removeCash (portfolio, cashHolding) {
    try {
      const response = await new Http({ auth: true }).delete(`portfoliocash/${portfolio}/${cashHolding.currency}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async addRealEstate (portfolio, property) {
    try {
      const response = await new Http({ auth: true }).post(`portfoliorealestate/${portfolio}`, property)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async removeRealEstate (portfolio, property) {
    try {
      const response = await new Http({ auth: true }).delete(`portfoliorealestate/${portfolio}/${property.id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async activity (portfolio, params) {
    try {
      const response = await new Http({ auth: true }).get(`activity/${portfolio}`, { params })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async addActivity (portfolio, activity) {
    try {
      const response = await new Http({ auth: true }).post(`activity/${portfolio}`, activity)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async configureHolding (portfolio, holding) {
    try {
      const response = await new Http({ auth: true }).post(`portfolio/configureHolding/${portfolio}`, holding)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async removeActivity (activity) {
    try {
      const response = await new Http({ auth: true }).delete(`activity/${activity}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async removeActivityGroup (activity) {
    try {
      const response = await new Http({ auth: true }).delete(`activitygroup/${activity}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async company (symbol, period) {
    try {
      const response = await new Http({ auth: true }).get(`stock/${symbol}/company` + (period === "12-months" ? "" : `?period=${period}`))
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async accountSettings () {
    try {
      const response = await new Http({ auth: true }).get(`account`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async deleteSubscription () {
    try {
      const response = await new Http({ auth: true }).delete(`account/subscription`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async deleteAccount () {
    try {
      const response = await new Http({ auth: true }).delete(`account`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async saveAccountSettings (accoutDetails) {
    try {
      const response = await new Http({ auth: true }).put(`account`, accoutDetails)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async publicCountryList (country) {
    try {
      const response = await new Http({ auth: false }).get(`lists/${country}`);
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async publicCompany (symbol, period) {
    try {
      const response = await new Http({ auth: false }).get(`stock/${symbol}?period=`+ (period === "month" ? "1m" : `${period}`));
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async search (query) {
    try {
      const response = await new Http({ auth: true }).get(`search/${query}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async searchMetadata () {
    try {
      const response = await new Http({ auth: true }).get(`search/meta-data`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async earningsCalendar () {
    try {
      const response = await new Http({ auth: true }).get(`calendar`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async addCurrency (currency) {
    try {
      const response = await new Http({ auth: true }).post(`portfolio/currency/${currency}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async feedback (portfolio, message) {
    try {
      const response = await new Http({ auth: true }).post(`portfolio/${portfolio}/feedback`, message)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async session () {
    try {
      const response = await new Http({ auth: true }).get(`session`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async version () {
    try {
      const response = await new Http({ auth: false }).get(`version`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  // // cart
  static async getCartReference (subscription) {
    try {
      const response = await new Http({ auth: true }).post(`cart`, {
        type: 'zunemoney',
        subscription
      })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
  // end cart
}