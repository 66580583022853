<template>
  <section class="section">
    <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
      <div class="loader is-loading"></div>
    </div>

    <div v-if="ready">
      <!-- <div class="tabs">
        <ul>
          <li class="is-active subtitle">🚀 Your Dividend Stats</li>
        </ul>
      </div>
      <div>
        <p class="heading">Portfolio Value</p>
        <p class="title is-size-5-mobile">
          <span class="is-size-5">{{ this.portfolio.currency }}</span>
          {{ toLocaleNumber(this.portfolioActualValue) }}
        </p>
      </div>
      <div>
        <p class="heading">Portfolio Value</p>
        <p class="title is-size-5-mobile">
          <span class="is-size-5">{{ this.portfolio.currency }}</span>
          {{ toLocaleNumber(this.portfolioActualValue) }}
        </p>
      </div>
      <div>
        <p class="heading">Portfolio Value</p>
        <p class="title is-size-5-mobile">
          <span class="is-size-5">{{ this.portfolio.currency }}</span>
          {{ toLocaleNumber(this.portfolioActualValue) }}
          10000
        </p>
      </div> -->

      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="buttons">
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'week' }"
              @click="period('week')">
              {{ $t('app.charts.week') }}
            </button>
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'month' }"
              @click="period('month')">
              {{ $t('app.charts.month') }}
            </button>
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '12-months' }"
              @click="period('12-months')">
              {{ $t('app.charts._12Months') }}
            </button>
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'all' }"
              @click="period('all')">
              {{ $t('app.charts.all') }}
            </button>
          </div>
        </div>
      </div>
      <div class="tabs">
        <ul>
          <!-- <li class="is-active subtitle">📈 Insights</li>
        </ul>
      </div>
      <div>
        - Average dividend yield
        - Total dividend earned
        - Average dividend growth rate
        - Chart dividend growth extrapolation
        - all time total earned
      </div>
      <div class="tabs">
        <ul> -->
          <li class="is-active subtitle">📈 {{ $t('dividends.titles.dividendHistory') }}</li>
        </ul>
      </div>
      <loading-wrapper :loading="dividendsLoading">
        <dividends-bar-chart :dividends="this.dividends" :rates="this.rates" :displayCurrency="this.portfolio.currency"
          v-if="!dividendsLoading"></dividends-bar-chart>
      </loading-wrapper>

      <div class="tabs mt-6">
        <ul>
          <li class="is-active subtitle">👑 {{ $t('dividends.titles.receivedDividendsRanking') }}</li>
        </ul>
      </div>
      <loading-wrapper :loading="dividendsLoading">
        <bar-chart :chartData="this.receivedDividendRanking.data"
          :options="this.receivedDividendRanking.options"></bar-chart>
      </loading-wrapper>
      <!-- <div class="tabs mt-6">
        <ul>
          <li class="is-active subtitle">🔎 Future Dividends - Projected</li>
        </ul>
      </div>
      <loading-wrapper :loading="dividendsLoading">
        <bar-chart :chartData="this.receivedDividendRanking.data"
          :options="this.receivedDividendRanking.options"></bar-chart>
      </loading-wrapper> -->
      <div class="tabs mt-6">
        <ul>
          <li class="is-active subtitle">🔎 {{ $t('dividends.titles.futureDividendsBreakdown') }}</li>
        </ul>
      </div>
      <loading-wrapper :loading="dividendsLoading">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>{{ $t('dividends.fields.company') }}</th>
              <!-- <th>Share Price</th>  REMOVER -->
              <th>{{ $t('dividends.fields.marketYield') }}</th>
              <th>{{ $t('dividends.fields.ownedShares') }}</th>
              <!-- <th>Avg. Price</th> REMOVER -->
              <th>{{ $t('dividends.fields.portfolioYield') }}</th>
              <th class="level-right">{{ $t('dividends.fields.forecast') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="position in dividendPayers" :key="position.symbol">
              <td>
                <router-link :to="{
                  name: 'Company',
                  params: {
                    symbol: position.symbol,
                  },
                }" class="nav-link">
                  <span>{{ position.symbol }}</span>
                </router-link>
              </td>
              <!-- <td>
                {{ toLocaleNumber(position.lastKnownPrice) }}
              </td> -->
              <td>
                {{ toLocaleNumber(position.marketYield) }}
              </td>
              <td>
                {{ position.size }}
              </td>
              <!-- <td>
                {{ toLocaleNumber(position.averagePrice) }}
              </td> -->
              <td>
                {{ toLocaleNumber(position.yieldOnCost) }}
              </td>
              <td class="level-right">
                <span>{{ position.currency }} {{ toLocaleNumber(position.size * position.ttmDividendRate) }}</span>
                <span v-if="portfolio.currency !== position.currency" class="ml-3 is-size-7">({{ portfolio.currency }} {{
                  toLocaleNumber(position.size * position.ttmDividendRate * handleCurrency(position.currency)) }})</span>
              </td>
            </tr>
            <tr v-if="false">
              <td class="has-text-weight-bold">{{ $t('dividends.fields.total') }}</td>
              <td></td>
              <!-- <td></td> -->
              <td></td>
              <!-- <td></td> -->
              <td></td>
              <td class="level-right has-text-weight-bold">
                {{ currency }}
                {{ toLocaleNumber(portfolioTotalReceived) }}
              </td>
              <!-- % dividend contribution to portfolio -->
            </tr>
          </tbody>
        </table>
      </loading-wrapper>
      <!-- <div class="tabs mt-6">
        <ul>
          <li class="is-active subtitle">🔎 Future Dividends - 5 Years Projection</li>
        </ul>
      </div>
      <loading-wrapper :loading="dividendsLoading">
        <bar-chart :chartData="this.receivedDividendRanking.data"
          :options="this.receivedDividendRanking.options"></bar-chart>
      </loading-wrapper> -->
      <div class="tabs mt-6">
        <ul>
          <li class="is-active subtitle">📆 {{ $t('dividends.titles.dividendsForecast') }}</li>
        </ul>
      </div>
      <loading-wrapper :loading="dividendsLoading">
        <table class="table is-fullwidth is-striped">
          <tbody>
            <tr v-for="position in nextDividends" :key="position.positionName">
              <td>
                <router-link :to="{
                  name: 'Company',
                  params: {
                    symbol: position.ticker,
                  },
                }" class="nav-link">
                  <span>{{ position.ticker }}</span>
                </router-link>
                <span class="is-size-7">
                  {{ position.quantity }} x
                  {{ position.currency }}
                  {{ toLocaleNumber(position.amount) }}</span>
              </td>
              <td class="has-text-right">
                {{ toLocaleDate(new Date(position.paymentDate)) }}
              </td>
              <td class="level-right">
                {{ position.currency }}
                {{ toLocaleNumber(position.quantity * position.amount) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="nextDividends.length === 0">
              <td colspan="3">{{ $t('dividends.messages.noExpectedDividends') }}</td>
            </tr>
            <tr v-if="nextDividends.length > 0" class="has-text-weight-bold">
              <td>{{ $t('dividends.fields.total') }}</td>
              <td class="has-text-right"></td>
              <td class="level-right">
                {{ this.portfolio.currency }}
                {{ toLocaleNumber(this.nextDividends.reduce((total, curr) => total + (curr.total *
                  handleCurrency(curr.currency)), 0)) }}
              </td>
            </tr>
          </tfoot>
        </table>
      </loading-wrapper>
    </div>
  </section>
</template>

<script>
import DividendsBarChart from "@/components/dashboardComponents/DividendsBarChart.vue";
import BarChart from "@/components/zuneCharts/BarChart";
import LoadingWrapper from "@/components/dashboardComponents/LoadingWrapper.vue"


import { Zune } from "@/services/zune.service";

export default {
  name: "Home",
  components: {
    DividendsBarChart,
    BarChart,
    LoadingWrapper
  },
  methods: {
    async period(selected) {
      if (selected !== this.selectedPeriod) {
        this.selectedPeriod = selected;
        await this.fetchDividends();
      }
    },
    handleCurrency(fromCurrency) {
      if (fromCurrency == this.portfolio.currency) {
        return 1; // default rate
      }

      return this.rates[fromCurrency + this.portfolio.currency];
    },
    async fetchDividends() {
      this.dividendsLoading = true;
      this.nextDividends.length = 0;

      const responseDividends = await Zune.dividends(this.selectedPeriod || '12-months');
      if (responseDividends.data.message !== "NOT_FOUND") {
        var dividendSummary = responseDividends.data.result;

        this.currency = dividendSummary.portfolioCurrency;
        this.rates = dividendSummary.rates;
        //this.portfolioTotalReceived = dividendSummary.receivedDividends;
        this.dividendPayers = dividendSummary.dividendPayers;
        this.dividends = dividendSummary.dividendPayers.reduce((arr, curr) => [...arr, ...curr.dividends], []);

        var highest = 0;
        var ranking = this.dividends.reduce((acc, curr) => {
          const totalPaid = curr.total * this.handleCurrency(curr.currency);
          if (totalPaid > 0) {
            acc[curr.ticker] = (acc[curr.ticker] || 0) + totalPaid;
          }

          if (acc[curr.ticker] > highest) {
            highest = acc[curr.ticker];
          }

          // dividend forecast
          if (Date.now() < new Date(curr.paymentDate)) {
            // dividend.positionSize = position.quantity;
            // dividend.positionName = position.ticker;
            // dividend.totalAmount = dividend.total;

            this.nextDividends.push(curr);
          }

          return acc;
        }, {});

        this.receivedDividendRanking.data = {
          labels: Object.keys(ranking).map(key => ranking[key] == highest ? key + "🏆" : key),
          datasets: [
            {
              label: "Received Dividends (" + this.portfolio.currency + ")",
              backgroundColor: "#99ccff",
              data: Object.values(ranking),
            },
          ],
        };
        this.receivedDividendRanking.options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              ticks: {
                callback: (value) => {
                  return this.portfolio.currency + ' ' + this.toLocaleNumber(value);
                },
              },
              stacked: true
            }]
          }
        };

      }

      // sort by dividend date
      this.nextDividends.sort((a, b) =>
        a.paymentDate < b.paymentDate
          ? -1
          : Number(a.paymentDate > b.paymentDate)
      );
      this.dividendsLoading = false;
    },
    async fetchMetaData() {
      const portfolioOverview = await Zune.portfolio();

      if (portfolioOverview.data.message !== "NOT_FOUND") {
        this.portfolio = portfolioOverview.data.result;
        this.rates = portfolioOverview.data.result.rates;

        if (this.portfolio.hasHoldings) {
          await this.fetchDividends();

          this.displayPortfolio = true;
        } else {
          this.displayPortfolio = false;
        }
      } else {
        this.displayPortfolio = false;
      }
    },
  },
  async mounted() {
    await this.fetchMetaData();

    this.ready = true;
    this.chartDataReady = true;
  },
  data() {
    return {
      ready: false,
      dividendsLoading: true,
      portfolio: 1,
      currency: '',
      selectedPeriod: '12-months',
      portfolioTotalReceived: 0,
      dividends: [],
      dividendPayers: [],
      dividendsChart: {},
      receivedDividendRanking: {},
      nextDividends: [],
      chartDataReady: true,
      version: '',
    };
  },
};
</script>
