<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <section class="hero bd-hero bd-is-basic">
          <div class="hero-body">
            <div class="bd-hero-body">
              <div class="bd-hero-heading">
                <h1 class="title algolia-lvl0">
                  <p>Top European Dividend Stocks</p>
                </h1>
                <div class="subtitle is-4 algolia-lvl1">
                  <p>
                    A portfolio composed by the top european dividend stocks.<span>
                      Signup to start
                      <a href="https://app.zune.money/signup"
                        >Tracking Your Portfolio🚀 with Zune Money.</a
                      ></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- SUMMARY -->
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Portfolio Value</p>
              <p class="title is-size-5-mobile">
                <span class="is-size-5">{{ this.portfolio.currency }}</span>
                {{ toLocaleNumber(this.portfolioActualValue) }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile">
            <div>
              <p class="heading">Gains</p>
              <p class="title is-size-5-mobile">
                <span
                  :class="{
                    'has-text-success-dark':
                      this.portfolioActualValue - this.portfolioOriginalValue >
                      0,
                    'has-text-danger-dark':
                      this.portfolioActualValue - this.portfolioOriginalValue <
                      0,
                  }"
                  >$
                  {{
                    toLocaleNumber(
                      this.portfolioActualValue - this.portfolioOriginalValue
                    )
                  }}</span
                >
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Return</p>
              <p
                class="title is-size-5-mobile"
                :class="{
                  'has-text-success-dark': this.portfolioAggregatedReturn > 0,
                  'has-text-danger-dark': this.portfolioAggregatedReturn < 0,
                }"
              >
                <span v-if="this.portfolioAggregatedReturn > 0"
                  >+{{
                    parseFloat(this.portfolioAggregatedReturn).toFixed(2)
                  }}%</span
                >
                <span v-if="this.portfolioAggregatedReturn < 0"
                  >{{
                    parseFloat(this.portfolioAggregatedReturn).toFixed(2)
                  }}%</span
                >
                <span v-if="this.portfolioAggregatedReturn == 0">-</span>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile">
            <div>
              <!-- We only calculate the dividends of your current holdings -->
              <p class="heading">Dividends (12-month)</p>
              <p class="title is-size-5-mobile">
                {{ this.dividendCurrency }}
                <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / SUMMARY END -->

    <!-- PORTFOLIO VIEW -->
    <div id="portfolio" v-if="displayPortfolio">
      <!-- PERIOD CHARTS -->
      <!-- <div class="level">
        <div class="level-left"></div>
        <div class="level-right is-hidden-mobile">
          <div class="buttons">
            <button
              type="button"
              class="button is-white"
              :class="{ 'is-light': selectedPeriod == 'week' }"
              @click="period('week')"
            >
              WEEK
            </button>
            <button
              type="button"
              class="button is-white"
              :class="{ 'is-light': selectedPeriod == 'month' }"
              @click="period('month')"
            >
              MONTH
            </button>
            <button
              type="button"
              class="button is-white"
              :class="{ 'is-light': selectedPeriod == '12-months' }"
              @click="period('12-months')"
            >
              12-MONTHS
            </button>
            <button
              type="button"
              class="button is-white"
              :class="{ 'is-light': selectedPeriod == '5-years' }"
              @click="period('5-years')"
            >
              5-YEARS
            </button>
          </div>
        </div>
      </div> -->
      <div class="columns">
        <doughnut-chart
            :totalValue="this.portfolioActualValue"
            :invested="this.portfolioOriginalValue - this.cashTotal - this.realEstateTotal"
            :cash="this.cashTotal"
            :realEstate="this.realEstateTotal"
            :gains="this.portfolioActualValue - this.portfolioOriginalValue"
            :dividends="this.dividendAmount"
            v-if="this.portfolioActualValue"
            class="column is-one-third"
          ></doughnut-chart>
        <div class="column is-hidden-tablet">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Gains</p>
                <p class="title is-size-5-mobile">
                  <span class="is-white"
                    >$
                    {{
                      toLocaleNumber(
                        this.portfolioActualValue - this.portfolioOriginalValue
                      )
                    }}</span
                  >
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <!-- We only calculate the dividends of your current holdings -->
                <p class="heading">Dividends (12-month)</p>
                <p class="title is-size-5-mobile">
                  {{ this.dividendCurrency }}
                  <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <line-chart
          :chartData="this.growthChart.data"
          :options="this.growthChart.options"
          ref="lineChart"
          class="column is-two-thirds"
        ></line-chart>
      </div>
      <!-- / PERIOD CHARTS END -->

      <div class="tabs">
        <ul>
          <li class="is-active subtitle">Dividends</li>
        </ul>
      </div>
      <div class="columns">
        <!-- <div class="columns column is-4">
          <div class="column">
            <div class="card events-card">
              <header class="card-header">
                <p class="card-header-title">Dividend Stats</p>
              </header>
              <div class="card-table">
                <div class="content">
                  <table class="table is-fullwidth is-striped">
                    <tbody>
                      <tr>
                        <td>Current portfolio yield</td>
                        <td
                          class="level-right has-text-weight-bold"
                          v-if="dividendStats && dividendStats.yield"
                        >
                          {{ toLocaleNumber(this.dividendStats.yield) }}%
                        </td>
                        <td class="level-right has-text-weight-bold">-</td>
                      </tr>
                      <tr>
                        <td>Yield on invested capital</td>
                        <td class="level-right has-text-weight-bold">4%</td>
                      </tr>
                      <tr>
                        <td>12-month dividend earnings</td>
                        <td class="level-right has-text-weight-bold">
                          {{ this.dividendCurrency }}
                          <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
                          💰
                        </td>
                      </tr>
                      <tr>
                        <td>Largest dividend payer</td>
                        <td class="level-right has-text-weight-bold">
                          Apple 🏆
                        </td>
                      </tr>
                      <tr>
                        <td>Apple paid you</td>
                        <td class="level-right has-text-weight-bold">
                          USD 150 🤑
                        </td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="column">
          <dividends-bar-chart
            :holdings="this.portfolio.stockHoldings"
              :rates="this.rates"
              :displayCurrency="this.portfolio.currency"
          ></dividends-bar-chart>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="tabs">
            <ul>
              <li class="is-active subtitle">Holdings</li>
            </ul>
          </div>
          <div class="card events-card">
            <div class="card-table">
              <div class="content">
                <table
                  class="
                    table
                    is-fullwidth is-striped is-hoverable is-fullwidth
                  "
                >
                  <thead>
                    <tr class="is-hidden-mobile">
                      <!-- <th></th> -->
                      <th>Symbol</th>
                      <th>Quantity</th>
                      <th>Avg. Price</th>
                      <th>Invested</th>
                      <th>Price</th>
                      <th>Total</th>
                      <th>Gains</th>
                      <th>Relative Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="position in portfolio.stockHoldings"
                      :key="position.ticker"
                    >
                      <td data-label="Symbol">
                        <router-link
                          :to="{
                            name: 'Company',
                            params: {
                              symbol: position.ticker,
                            },
                          }"
                          class="nav-link"
                        >
                          <span>{{ position.ticker }}</span>
                        </router-link>
                      </td>
                      <td data-label="Quantity" class="is-hidden-mobile">
                        {{ position.size }}
                      </td>
                      <td data-label="Average" class="is-hidden-mobile">
                        {{ toLocaleNumber(position.averagePrice) }}
                      </td>
                      <td data-label="Invested" class="is-hidden-mobile">
                        {{ toLocaleNumber(position.totalAmount) }}
                      </td>
                      <!-- <td
                        data-label="Progress"
                        class="is-progress-cell is-hidden-mobile"
                      >
                        <progress
                          max="100"
                          class="progress is-small is-primary"
                          value="79"
                        >
                          79
                        </progress>
                      </td> -->
                      <td data-label="Price" class="has-text-right-mobile">
                        {{ toLocaleNumber(position.lastKnownPrice) }}
                      </td>
                      <td data-label="Total" class="has-text-right-mobile">
                        {{
                          toLocaleNumber(
                            position.size * position.lastKnownPrice
                          )
                        }}
                      </td>
                      <td
                        data-label="Gains"
                        class="is-hidden-mobile"
                        :class="{
                          'has-text-success has-text-weight-bold':
                            position.size * position.lastKnownPrice -
                              position.totalAmount >
                            0,
                          'has-text-danger has-text-weight-bold':
                            position.size * position.lastKnownPrice -
                              position.totalAmount <
                            0,
                        }"
                      >
                        <!-- <span v-if="(position.size * position.lastKnownPrice) - position.totalAmount > 0">+</span> -->
                        <!-- <span v-if="(position.size * position.lastKnownPrice) - position.totalAmount > 0" class="icon"><i class="fa fa-arrow-up"></i></span> -->
                        <!-- <span v-if="(position.size * position.lastKnownPrice) - position.totalAmount > 0" class="icon"><i class="fa fa-arrow-down"></i></span> -->
                        <span :class="{ blink: position.blink }">
                          {{
                            toLocaleNumber(
                              position.size * position.lastKnownPrice -
                                position.totalAmount,
                              {
                                signDisplay: "exceptZero",
                              }
                            )
                          }}
                        </span>
                      </td>
                      <td data-label="Invested" class="is-hidden-mobile">
                        {{
                          toLocaleNumber(
                            ((position.size * position.lastKnownPrice) /
                              portfolioActualValue) *
                              100
                          ) + "%"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <footer class="card-footer">
              <!-- <a href="#" class="card-footer-item">View All</a> -->
            </footer>
          </div>
          <!-- footer spacing -->
          <div class="column pb-6 mb-6" style="visibility: hidden"></div>
          <!-- / footer spacing-->
        </div>
      </div>
    </div>
    <!-- / PORTFOLIO VIEW -->
  </section>
</template>

<script>
import DoughnutChart from "@/components/zuneCharts/DoughnutChart";
import LineChart from "@/components/zuneCharts/LineChart";
import DividendsBarChart from "@/components/dashboardComponents/DividendsBarChart.vue";

import { Zune } from "@/services/zune.service";

export default {
  name: "PublicPortfolio",
  components: {
    DoughnutChart,
    LineChart,
    DividendsBarChart,
  },
  methods: {
    handleCurrency(fromCurrency) {
      if (fromCurrency == this.portfolio.currency) {
        return 1; // default rate
      }

      return this.rates[fromCurrency + this.portfolio.currency];
    },
    async fetchMetaData() {
      const portfolio = await Zune.publicPortfolio(this.$route.params.id || 75);

      if (portfolio.data.message !== "NOT_FOUND") {
        this.portfolio = portfolio.data.result;
        this.timeline = portfolio.data.result.timeline;

        if (this.portfolio.hasHoldings) {
          this.portfolio.stockHoldings.forEach(
            (position) => (position.blink = false)
          );

          this.rates = portfolio.data.result.rates;

          this.dividendStats = portfolio.data.result.dividendStats;

          this.displayPortfolio = true;
        } else {
          this.displayPortfolio = false;
        }
      } else {
        this.displayPortfolio = false;
      }
    },
    async calculateGrowthChart(period = "12-months") {
      var growthChartResponse = await Zune.publicgrowth(this.portfolio.id, period);
      
      const growthChart = growthChartResponse.data;
      this.growthChart.data.labels = [];

      this.growthChart.options.scales.yAxes[0].ticks.min = Number.MAX_VALUE;
      this.growthChart.data.datasets[0].data = [];
      
      Object.keys(growthChart).sort().forEach(key => {
        this.growthChart.data.labels.push(key);
        const entry = growthChart[key].value;

        this.growthChart.data.datasets[0].data.push(entry);
        if (entry < this.growthChart.options.scales.yAxes[0].ticks.min) {
          this.growthChart.options.scales.yAxes[0].ticks.min = entry;
        }
      });

      this.growthChart.options.scales.yAxes[0].ticks.min =
        this.growthChart.options.scales.yAxes[0].ticks.min * 0.95;
      
      this.$refs.lineChart.update();
    },
    async calculatePortfolioOverview() {
      for (const position of this.portfolio.stockHoldings) {

        var currencyRate = this.handleCurrency(position.priceCurrency);

        this.portfolioOriginalValue += position.totalAmount * currencyRate;
        this.portfolioActualValue +=
          position.lastKnownPrice * position.size * currencyRate;

        position.dividends.forEach((dividend) => {
          this.dividendAmount += dividend.amount * position.size * currencyRate;

          if (Date.now() < new Date(dividend.paymentDate)) {
            dividend.positionSize = position.size;
            dividend.positionName = position.ticker;
            dividend.totalAmount = position.size * dividend.amount;

            this.nextDividends.push(dividend);
          }
        });

        // sort by dividend date
        this.nextDividends.sort((a, b) =>
          a.paymentDate < b.paymentDate
            ? -1
            : Number(a.paymentDate > b.paymentDate)
        );
      }

      if (this.portfolio.cashHoldings) {
        for (const cash of this.portfolio.cashHoldings) {
          currencyRate = this.handleCurrency(cash.currency);

          var cashAmount = cash.amount * currencyRate;

          this.portfolioOriginalValue += cashAmount;
          this.portfolioActualValue += cashAmount;
          this.cashTotal += cashAmount;
        }
      }

      if (this.portfolio.realEstateHoldings) {
        for (const realEstate of this.portfolio.realEstateHoldings) {
          currencyRate = this.handleCurrency(realEstate.currency);

          var realEstateValue = realEstate.amount * currencyRate;

          this.portfolioOriginalValue += realEstateValue;
          this.portfolioActualValue += realEstateValue;
          this.realEstateTotal += realEstateValue;
        }
      }

      this.portfolioAggregatedReturn =
        (this.portfolioActualValue / this.portfolioOriginalValue - 1) * 100;
    },
  },
  async mounted() {

    await this.fetchMetaData();

    if (this.displayPortfolio) {
      await this.calculatePortfolioOverview();
      await this.calculateGrowthChart();
    }
  },
  data() {
    var self = this

    return {
      displayPortfolio: false,
      rates: [],
      portfolio: {},
      timeline: {},
      cashTotal: 0,
      realEstateTotal: 0,
      portfolioActualValue: 0,
      portfolioOriginalValue: 0,
      portfolioAggregatedReturn: 0,
      dividendCurrency: "$",
      dividendAmount: 0,
      nextDividends: [],
      dividendStats: {},
      selectedPeriod: "12-months",
      debug: false,
      ready: false,
      growthChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Stocks",
              data: [],
              backgroundColor: "rgba(1, 116, 188, 0.50)",
              borderColor: "rgba(1, 116, 188, 0.50)",
              pointBackgroundColor: "rgba(171, 71, 188, 1)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Stocks",
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  callback: function (value) {
                    return self.toLocaleNumber(value);
                  },
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>
