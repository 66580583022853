var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"card events-card mt-3"},[_vm._m(0),(!_vm.ready)?_c('div',{staticClass:"loader-wrapper columns is-centered is-vcentered is-mobile",staticStyle:{"min-height":"25vh"}},[_c('div',{staticClass:"loader is-loading"})]):_vm._e(),_c('div',{staticClass:"card-table"},[_c('div',{staticClass:"content"},[(_vm.ready && _vm.visible)?_c('table',{staticClass:"table\n                  is-fullwidth is-striped is-hoverable is-fullwidth"},[(this.stats.stocksOriginalAmount)?_c('tr',[_vm._m(1),_c('td',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.stocksOriginalAmount))+" ")]),_c('td')]):_vm._e(),(this.stats.stocksGains)?_c('tr',[_vm._m(2),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber( this.stats.stocksGains ))+" ")])]),_c('td')]):_vm._e(),(this.stats.stocksActualAmount)?_c('tr',[_vm._m(3),_c('td',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.stocksActualAmount))+" ")]),_c('td',[_c('span',{class:{
                                    'has-text-success-dark': this.stats.stocksGainsPercentage > 0,
                                    'has-text-danger-dark': this.stats.stocksGainsPercentage < 0,
                                }},[(this.stats.stocksGainsPercentage > 0)?_c('span',[_vm._v("+"+_vm._s(parseFloat(this.stats.stocksGainsPercentage * 100).toFixed(2))+"%")]):_vm._e(),(this.stats.stocksGainsPercentage < 0)?_c('span',[_vm._v(_vm._s(parseFloat(this.stats.stocksGainsPercentage * 100).toFixed(2))+"%")]):_vm._e()])])]):_vm._e(),(this.stats.realEstateTotalAmount)?_c('tr',[_vm._m(4),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber( this.stats.realEstateTotalAmount ))+" ")])]),_c('td')]):_vm._e(),(this.stats.cashAllocation)?_c('tr',[_c('td',[_vm._v("Cashflow "),(false)?_c('span',[_vm._v("*total deposits minus withdraws from your cashholdings")]):_vm._e()]),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber( this.stats.cashAllocation ))+" ")])]),_c('td')]):_vm._e(),(false && this.stats.realizedAmount)?_c('tr',[_c('td',[_vm._v("Realized")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.realizedAmount))+" ")])]),_c('td',[_c('span',{staticClass:"px-3 is-size-5",class:{
                                    'has-text-success-dark': this.stats.realizedReturn > 0,
                                    'has-text-danger-dark': this.stats.realizedReturn < 0,
                                }},[(this.stats.realizedReturn > 0)?_c('span',[_vm._v("+"+_vm._s(parseFloat(this.stats.realizedReturn).toFixed(2))+"%")]):_vm._e(),(this.stats.realizedReturn < 0)?_c('span',[_vm._v(_vm._s(parseFloat(this.stats.realizedReturn).toFixed(2))+"%")]):_vm._e()])])]):_vm._e(),_c('tr',[_c('td',[_vm._v("5-y Dividends (gross)")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.remoteStats.dividendsAmountGross))+" ")])]),_c('td',[_c('span',{staticClass:"px-3 is-size-5",class:{
                                    'has-text-success-dark': this.stats.dividendsReturn > 0,
                                    'has-text-danger-dark': this.stats.dividendsReturn < 0,
                                }},[(this.stats.dividendsReturn > 0)?_c('span',[_vm._v("+"+_vm._s(parseFloat(this.stats.dividendsReturn).toFixed(2))+"%")]):_vm._e(),(this.stats.dividendsReturn < 0)?_c('span',[_vm._v(_vm._s(parseFloat(this.stats.dividendsReturn).toFixed(2))+"%")]):_vm._e()])])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.totalValue))+" ")]),_c('td')]),(false)?_c('tr',[_c('td',[_vm._v("Total")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.gainsAmount))+" ")])]),_c('td')]):_vm._e(),_c('tr'),(false && this.stats.feesAmount)?_c('tr',[_c('td',[_vm._v("Fees")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.feesAmount))+" ")])]),_c('td')]):_vm._e(),(false)?_c('tr',[_c('td',[_vm._v("Net Total")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(this.stats.currency)+" "+_vm._s(_vm.toLocaleNumber(this.stats.netGainsAmount))+" ")])]),_c('td')]):_vm._e()]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("📈 Stats")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',{},[_vm._v("Stocks:")]),_vm._v(" Total Invested")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',{},[_vm._v("Stocks:")]),_vm._v(" Price Gains")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',{},[_vm._v("Stocks:")]),_vm._v(" Total")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',{},[_vm._v("Real Estate")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',{},[_vm._v("Portfolio:")]),_vm._v(" Total")])}]

export { render, staticRenderFns }