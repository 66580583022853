function sort(sortColumn, sortDirection, data) {
    var sorted = data.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] > b[sortColumn] ? -1 : 1;
      }
    });

    return sorted;
}

export default sort