<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('account.heading') }}
              </p>
              <p class="title is-size-5-mobile">{{ $t('account.title') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FORM -->
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="field">
          <label class="label">{{ $t('account.fields.name') }}</label>
          <div class="control">
            <input class="input" type="text" :placeholder="$t('account.fields.nameInputPlaceHolder')" v-model="account.name" v-bind:class="{
              'is-danger': errors && !account.name,
            }" />
          </div>
        </div>

        <!-- <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left has-icons-right">
              <input
                class="input"
                type="email"
                placeholder="your-email@address.com"
                v-model="account.email"
                v-bind:class="{
                  'is-danger': errors && !account.email,
                }"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
          </div> -->

        <div class="field">
          <label class="label">{{ $t('account.fields.reportSettings') }}</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="account.reportSchedule">
                <option value="weekly">{{ $t('account.fields.reportSettingsOption1') }}</option>
                <option value="daily">{{ $t('account.fields.reportSettingsOption2') }}</option>
                <option value="never">{{ $t('account.fields.reportSettingsOption3') }}</option>
                <!-- <option value="never">End of the month report (PRO)</option> -->
              </select>
            </div>
          </div>
        </div>

        <div class="field" v-if="false">
          <label class="label">{{ $t('account.fields.country') }}</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="account.country">
                <option value="weekly">{{ $t('account.fields.reportSettingsOption1') }}</option>
                <option value="daily">{{ $t('account.fields.reportSettingsOption2') }}</option>
                <option value="never">{{ $t('account.fields.reportSettingsOption3') }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field" v-if="false">
          <label class="label">{{ $t('account.fields.language') }}</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="account.language">
                <option value="weekly">{{ $t('account.fields.reportSettingsOption1') }}</option>
                <option value="daily">{{ $t('account.fields.reportSettingsOption2') }}</option>
                <option value="never">{{ $t('account.fields.reportSettingsOption3') }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field is-grouped" style="justify-content: end">
          <div class="control">
            <button class="button is-primary" :class="{ 'is-loading': loading }" @click="save">Save</button>
          </div>
        </div>

        <div class="tabs" v-if="this.$store.state.accountType === 1">
          <ul>
            <li class="is-active"><a class="is-white">{{ $t('account.section.subscription') }}</a></li>
          </ul>
        </div>
        <div class="field is-grouped level" v-if="this.$store.state.accountType === 1">
          <div class="level-item level-left">
            <p><span>{{ $t('account.fields.currentSubscription') }}: <strong>{{ $t('account.fields.currentSubscriptionPRO') }}</strong></span></p>
          </div>
          <div class="level-item level-right">
            <button class="button is-danger" @click="deleteSubscription">
              <span class="icon is-small">
                <i class="fa fa-trash"></i>
              </span>
              <span>{{ $t('account.buttons.cancel') }}</span>
            </button>
          </div>
        </div>

        <div class="tabs">
          <ul>
            <li class="is-active"><a class="is-white">{{ $t('account.section.delete') }}</a></li>
          </ul>
        </div>
        <div class="field is-grouped" style="justify-content: end">
          <button class="button is-danger" @click="deleteAccount">
            <span class="icon is-small">
              <i class="fa fa-trash"></i>
            </span>
            <span>{{ $t('account.buttons.delete') }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- footer spacing -->
      <div class="column pb-6 mb-6" style="visibility: hidden"></div>
      <!-- / footer spacing-->
    </div>
    <!-- / FORM -->
    <delete-account-confirmation ref="deleteAccountConfirmationModal"></delete-account-confirmation>
    <delete-subscription-confirmation ref="deleteSubscriptionConfirmationModal"></delete-subscription-confirmation>
  </section>
</template>
  
<script>
// @ is an alias to /src
import { Zune } from "@/services/zune.service";
import DeleteAccountConfirmation from "../components/accountSettings/DeleteAccountConfirmation";
import DeleteSubscriptionConfirmation from "../components/accountSettings/DeleteSubscriptionConfirmation";

export default {
  name: "Account",
  components: {
    DeleteAccountConfirmation,
    DeleteSubscriptionConfirmation
  },
  methods: {
    async save() {
      if (
        !this.account.name ||
        // !this.account.email ||
        !this.account.reportSchedule
      ) {
        this.errors = true;
      } else {

        this.errors = false;
        this.loading = true;

        const response = await Zune.saveAccountSettings(this.account);
        this.$store.dispatch('addAccountEditable', this.account);
        this.loading = false;

        if (response.success) {
          this.$emit("notifyMessage", this.$t('account.messages.success'))
        }
      }
    },
    async fetch() {
      const response = await Zune.accountSettings();
      this.account = response.data
    },
    deleteAccount() {
      this.$refs.deleteAccountConfirmationModal.openModal()
    },
    deleteSubscription() {
      this.$refs.deleteSubscriptionConfirmationModal.openModal()
    }
  },
  async mounted() {
    await this.fetch();
  },
  data() {
    return {
      errors: false,
      loading: false,
      account: {
        reportSchedule: "weekly",
        // email: "",
        name: "",
      }
    };
  },
};
</script>