import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      portfolio: 0,
      accountType: 300,
      accountHasHoldings: false,
      account: {
        type: 0
      }
    },
    mutations: {
        storePortfolio,
        storeAccount,
        storeEditable
    },
    actions: {
      addPortfolio(context, portfolio) {
        context.commit('storePortfolio', portfolio)
      },
      addAccount(context, account) {
        context.commit('storeAccount', account)
      },
      addAccountEditable(context, account) {
        context.commit('storeEditable', account)
      }
    }
  })

function storePortfolio(state, portfolio) {
    state.portfolio = portfolio;
}

function storeAccount(state, account) {
  state.accountName = account.name;
  state.accountType = account.type;
  state.accountHasHoldings = account.hasHoldings;
  state.session = account.session;
  state.appVersion = account.appVersion;
}

function storeEditable(state, account) {
  state.accountName = account.name;
}

export default store