<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Manage Real Estate</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <!-- <div class="tabs">
          <ul>
            <li class="is-active"><a>Update Position</a></li>
          </ul>
        </div> -->
        <div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input class="input" type="text" v-model="newTransaction.name" v-bind:class="{
                    'is-danger': errors && !newTransaction.name,
                  }" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Property Type</label>
                <div class="control">
                  <input class="input" type="text" v-model="newTransaction.propertyType" v-bind:class="{
                    'is-danger': errors && !newTransaction.propertyType,
                  }" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Property Value Today</label>
                    <div class="field has-addons">
                      <p class="control">
                        <span class="select">
                          <select v-model="newTransaction.currency">
                            <option value="EUR">
                              EUR
                            </option>
                            <option value="USD">
                              USD
                            </option>
                            <option value="BRL">
                              BRL
                            </option>
                            <option value="CHF">
                              CHF
                            </option>
                            <option value="CNY">
                              CNY
                            </option>
                            <option value="DKK">
                              DKK
                            </option>
                            <option value="INR">
                              INR
                            </option>
                            <option value="NOK">
                              NOK
                            </option>
                            <option value="PLN">
                              PLN
                            </option>
                            <option value="SEK">
                              SEK
                            </option>
                          </select>
                        </span>
                      </p>
                      <p class="control">
                        <input class="input" type="number" step="0.01" v-model="newTransaction.amount" v-bind:class="{
                            'is-danger': errors && !newTransaction.amount,
                          }">
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Purchased At</label>
                    <div class="control">
                      <input class="input" type="date" v-model="newTransaction.executionDate" v-bind:class="{
                        'is-danger': errors && !newTransaction.executionDate,
                      }" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content: end">
        <button class="button" @click="closeModal()">Close</button>
        <button
          class="button is-small is-primary"
          type="button"
          @click="update()"
        >
          UPDATE
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";

export default {
  props: {
    portfolioId: Number
  },
  methods: {
    actionTaken(msg) {
      this.$emit("actionTaken", true);
      this.$emit("notifyMessage", msg);
    },
    async update() {
      const response = await Zune.addRealEstate(
          this.portfolioId,
          {
            id: this.newTransaction.id,
            name: this.newTransaction.name,
            priceCurrency: this.newTransaction.currency,
            price: this.newTransaction.amount,
            quantity: 1,
            executionDate: this.newTransaction.executionDate,
            propertyType: this.newTransaction.propertyType
          }
        );
        this.isReadyBuy = true;

        if (response.success) {
          this.actionTaken(`Added ${this.newTransaction.currency} ${this.newTransaction.amount}`);
          this.closeModal()
        }
    },
    openModal(holding, onModalOpen) {
      this.holding = holding
      this.newTransaction = {
        id: this.holding.id,
        name: this.holding.name,
        propertyType: this.holding.propertyType,
        amount: this.holding.amount,
        executionDate: this.holding.executionDate.split('T')[0],
        currency: this.holding.currency
      }

      this.isActive = true;
      onModalOpen();
    },
    closeModal() {
      this.isActive = false;
      this.holding = {};
      this.newTransaction = {};
      this.errors = false;
    },
  },
  data() {
    return {
      holding: {},
      newTransaction: {},
      isActive: false,
      errors: false,
    };
  },
};
</script>

<style scoped>
</style>