<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Manage Position</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading has-text-weight-bold">Symbol</p>
              <p>
                {{ position.ticker }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading has-text-weight-bold">Shares</p>
              <p>{{ position.size }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading has-text-weight-bold">Avg. Price</p>
              <p>
                {{ position.priceCurrency }}
                {{ toLocaleNumber(position.averagePrice) }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading has-text-weight-bold">Position Value</p>
              <p>
                {{ position.priceCurrency }}
                {{ toLocaleNumber(position.size * position.lastKnownPrice) }}
              </p>
            </div>
          </div>
        </div>
        <div class="tabs">
          <ul>
            <li class="is-active"><a>Add Activity</a></li>
          </ul>
        </div>
        <div class="columns">
          <div class="column">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Quantity</label>
                  <div class="control">
                    <input
                      class="input"
                      type="number"
                      min="1"
                      step="1"
                      v-model="newTransaction.size"
                      v-bind:class="{
                        'is-danger': errors && !newTransaction.size,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Price</label>
                  <div class="control">
                    <input
                      class="input"
                      type="number"
                      step="0.01"
                      v-model="newTransaction.price"
                      v-bind:class="{
                        'is-danger': errors && !newTransaction.price,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Transaction Date</label>
                  <div class="control">
                    <input
                      class="input"
                      type="date"
                      v-model="newTransaction.executionDate"
                      v-bind:class="{
                        'is-danger': errors && !newTransaction.executionDate,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <div class="control buttons is-right">
                    <button
                      class="button is-small is-danger"
                      type="button"
                      @click="sell()"
                    >
                      SELL
                    </button>
                    <button
                      class="button is-small is-primary"
                      type="button"
                      @click="buy()"
                    >
                      BUY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs">
          <ul>
            <li class="is-active"><a>History</a></li>
          </ul>
        </div>
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, index) in transactions"
              :key="transaction.id"
            >
              <td>{{ transaction.type }}</td>
              <td>
                {{ toLocaleDate(new Date(transaction.executionDate)) }}
              </td>
              <td>{{ transaction.quantity }}</td>
              <td>{{ transaction.price }}</td>
              <td>
                {{ transaction.transactionTotalCurrency }}
                {{ transaction.transactionTotal }}
              </td>
              <td class="buttons">
                <button
                  class="button is-small is-danger"
                  data-target="sample-modal"
                  type="button"
                  @click="removeActivity(index)"
                >
                  <span class="icon"><i class="fa fa-trash"></i></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot" style="justify-content: end">
        <button class="button" @click="closeModal()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";

export default {
  methods: {
    async removeActivity(activityIndex) {
      const response = await Zune.removeActivity(
        this.transactions[activityIndex].id
      );

      if (response.success) {
        this.transactions.splice(activityIndex, 1);
        this.actionTaken = true
      }
    },
    async add(activity) {
      if (
        !this.newTransaction.size ||
        !this.newTransaction.price ||
        !this.newTransaction.executionDate
      ) {
        this.errors = true;
      } else {
        const response = await Zune.addActivity(
          this.portfolioId,
          activity
        );

        if (response.success) {
          this.transactions = response.data.transactions || response.data;
          this.newTransaction = {};
          this.errors = false;
        }
      }
    },
    async buy() {
      await this.add({
        // fixed values
        ticker: this.position.ticker,
        exchange: this.position.exchange,
        priceCurrency: this.position.priceCurrency,
        // newTransaction
        price: this.newTransaction.price,
        quantity: this.newTransaction.size,
        executionDate: this.newTransaction.executionDate,
      });
      this.actionTaken = true
    },
    async sell() {
      await this.add({
        // fixed values
        ticker: this.position.ticker,
        exchange: this.position.exchange,
        priceCurrency: this.position.priceCurrency,
        // newTransaction
        quantity: this.newTransaction.size * -1,
        price: this.newTransaction.price,
        executionDate: this.newTransaction.executionDate,
      });
      this.actionTaken = true
    },
    async openModal(ticker, onModalOpen) {
      const response = await Zune.transactions(
        this.portfolioId,
        ticker
      );


      this.transactions = response.data;

      this.position = response.data;
      this.transactions = this.position.transactions;


      this.isActive = true;
      onModalOpen();
    },
    closeModal() {
      this.isActive = false;
      this.position = {};
      this.newTransaction = {};
      this.errors = false;

      if (this.actionTaken) {
        this.$emit("actionTaken", true);
      }
      this.actionTaken = false;
    },
  },
  props: {
    portfolioId: Number,
  },
  data() {
    return {
      actionTaken: false,
      position: {},
      newTransaction: {},
      transactions: [],
      isActive: false,
      errors: false,
    };
  },
};
</script>

<style scoped>
</style>
