<template>
    <loading-wrapper :loading="dividendsLoading">
    <dividends-bar-chart
      :dividends="this.dividends"
      :rates="this.rates"
      :displayCurrency="this.displayCurrency"
      v-if="!dividendsLoading && this.dividends"
    ></dividends-bar-chart>
    <div class="card events-card" if="">
      <header class="card-header">
        <p class="card-header-title">Dividend Forecast</p>
        <a href="#" class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </a>
      </header>
      <div class="card-table">
        <div class="content">
          <table class="table is-fullwidth is-striped">
            <tbody>
              <!-- <td width="5%">ORDER BY DATE</td> -->
              <tr
                v-for="(position, index) in upcomingDividends"
                :key="index + position.ticker"
              >
                <!-- <td width="5%"><i class="fa fa-bell-o"></i></td> -->
                <td>
                  {{ position.ticker }}
                  <span class="is-size-7">
                    {{ position.quantity }} x
                    {{ toLocaleNumber(position.amount) }}</span
                  >
                </td>
                <td class="has-text-right">
                  {{ toLocaleDate(new Date(position.paymentDate)) }}
                </td>
                <td class="level-right">
                  {{ position.currency }}
                  {{ toLocaleNumber(position.quantity * position.amount) }}
                </td>
                <!-- <td class="level-right">
                        <a class="button is-small is-primary" href="#">Action</a>
                      </td> -->
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="upcomingDividends.length === 0">
                <td colspan="3">No dividends declared yet.</td>
              </tr>
              <tr
                v-if="upcomingDividends.length > 0"
                class="has-text-weight-bold"
              >
                <!-- <td>Currency Selector?</td> -->
                <td>Total</td>
                <td class="has-text-right"></td>
                <td class="level-right">
                  {{ this.displayCurrency }}
                  {{ toLocaleNumber(this.aggregatedTotal) }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- <footer class="card-footer">
                <a href="#" class="card-footer-item">View All</a>
              </footer> -->
    </div>
  </loading-wrapper>
</template>

<script>

import DividendsBarChart from "./DividendsBarChart.vue";
import LoadingWrapper from "./LoadingWrapper.vue";

import { Zune } from "@/services/zune.service";

export default {
  props: {
    displayCurrency: String,
    rates: Object
  },
  components: {
    DividendsBarChart,
    LoadingWrapper
  },
  methods: {
    handleCurrency(fromCurrency) {
      if (fromCurrency == this.displayCurrency) {
        return 1; // default rate
      }

      return this.rates[fromCurrency + this.displayCurrency];
    }
  },
  async mounted() {
    this.dividendsLoading = true;
    const responseDividends = await Zune.dividends('12-months');
    if (responseDividends.data.message !== "NOT_FOUND") {

      const dividendSummary = responseDividends.data.result;
      this.dividends = dividendSummary.dividendPayers.reduce((arr, curr) => [...arr, ...curr.dividends], []);
      this.aggregatedTotal = 0;

      for (const dividend of this.dividends) {
        if (Date.now() < new Date(dividend.paymentDate)) {
          this.upcomingDividends.push(dividend);
          if (dividend.currency && dividend.total) {
            this.aggregatedTotal += dividend.total * this.handleCurrency(dividend.currency);
          }
        }
      }
    }

    // sort by dividend date
    this.upcomingDividends.sort((a, b) =>
      a.paymentDate < b.paymentDate
        ? -1
        : Number(a.paymentDate > b.paymentDate)
    );
    this.dividendsLoading = false;
  },
  data() {
    return {
      dividendsLoading: false,
      dividends: [],
      upcomingDividends: [],
      aggregatedTotal: 0
    };
  },
};
</script>

<style scoped>
</style>