<template>
    <div>
      <div
        v-if="loading"
        style="min-height: 25vh"
        class="loader-wrapper columns is-centered is-vcentered is-mobile"
      >
        <div class="loader is-loading"></div>
      </div>
      <slot v-if="!loading"></slot>
    </div>
  </template>
  
  <script>
  
  export default {
    props: {
      loading: Boolean,
    },
  };
  </script>