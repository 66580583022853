<template>
  <nav class="navbar has-shadow">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <!-- <img src="../assets/img/rocket.png" /> -->
        <strong>Zune</strong><span class="is-size-7">Beta</span>
      </router-link>
      <div class="navbar-burger burger" @click="toggleNavbar()" :class="{ 'is-active': isNavbarActive }">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isNavbarActive }">
      <div class="navbar-start">
        <div class="navbar-item">
          <div>
            <!-- <img src="images/type.png" width="250.5" height="21"> -->
            <!-- todo: replace this -->
            <strong>{{ portfolio.name }}</strong>
            <!-- <small class="ml-2">Currency: {{ portfolio.currency }}</small> -->
          </div>
        </div>
        <!-- <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">
            {{ $auth.user.name }}
          </div>
          <div class="navbar-dropdown is-right">
            <a class="navbar-item" @click="logout">
              <div>
                <span class="icon is-small">
                  <i class="fa fa-sign-out"></i>
                </span>
                Sign Out
              </div>
            </a>
          </div>
        </div> -->
      </div>

      <div class="navbar-end" v-if="!$auth.loading && !$auth.isAuthenticated">
        <div class="navbar-item">
          <button class="button is-danger">
            {{ $t('app.buttons.addActivity') }}<i class="ml-2 fa fa-cloud-upload is-size-3 has-text-secondary"></i>
          </button>
        </div>
        <!-- <div class="navbar-item">
          <router-link to="/premium" class="navbar-item">
            <button class="button is-success">
              UPGRADE 🚀<i
                class="ml-2 is-size-3 has-text-secondary"
              ></i>
            </button>
          </router-link>
        </div> -->
        <div class="navbar-item">
          <button class="button" @click="share">
            {{ $t('app.buttons.addActivity') }}<i class="ml-2 fa fa-share-alt is-size-4 has-text-secondary"></i>
          </button>
        </div>
        <!-- <div class="navbar-item">
          <a href="#" @click="share" alt="Share" class="has-text-secondary">
            <i class="ml-2 fa fa-share-alt is-size-3"></i>
          </a>
        </div> -->
        <!-- <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">
            <button class="button is-active" @click="savePortfolio">
              Login
            </button>
          </div>
        </div> -->
      </div>

      <div class="navbar-end" v-if="!$auth.loading && $auth.isAuthenticated">
        <div class="navbar-item">
          <div class="dropdown is-hoverable">
            <div class="dropdown-trigger">
              <button class="button py-3" aria-haspopup="true" aria-controls="dropdown-menu">
                <span>{{ selectedCurrency }}</span>
                <span class="icon is-small"> 💱 </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a @click="swapCurrency(currencyItem)" class="dropdown-item" v-for="currencyItem in supportedCurrencies"
                  :key="currencyItem" v-bind:class="{
                    'is-active': selectedCurrency == currencyItem,
                  }">
                  {{ currencyItem }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-item column is-8">
          <DropDown :options="options" v-on:selected="onSuggestionSelected" v-on:filter="searchInputChaged"
            :disabled="false" ref="suggestionInput" :maxItem="10" placeholder="Look for company or symbol ...">
          </DropDown>
        </div>
        <div class="navbar-item">
          <button class="button is-danger" @click="swapModal('stocks')">
            {{ $t('app.buttons.addActivity') }}<i class="ml-2 fa fa-cloud-upload is-size-3 has-text-secondary"></i>
          </button>
        </div>
        <!-- <div class="navbar-item" v-if="this.$store.state.account.type === 0">
          <router-link to="/premium">
            <button class="button is-warning">
              <strong>{{ $t('app.buttons.becomePro') }}</strong><i class="ml-2 is-size-3 has-text-secondary"></i>
            </button>
          </router-link>
        </div> -->
        <!-- <div
          v-if="isModified"
          class="navbar-item"
        >
          <button class="button is-danger" @click="savePortfolio">
            Save Portfolio
          </button>
        </div> -->
        <div class="navbar-item">
          <!-- <a href="#" @click="share" alt="Share" class="has-text-secondary">
            <i class="ml-2 fa fa-share-alt is-size-3"></i>
          </a> -->
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">
            {{ this.$store.state.accountName }}
          </div>
          <div class="navbar-dropdown is-right">
            <!-- <a class="navbar-item">
              <div>
                <span class="icon is-small">
                  <i class="fa fa-bug"></i>
                </span>
                Report bug
              </div>
            </a> -->
            <router-link to="/account" class="navbar-item">
              <div>
                <span class="icon is-small">
                  <i class="fa fa-cog"></i>
                </span>
                Account Settings
              </div>
            </router-link>
            <a class="navbar-item" @click="logout">
              <div>
                <span class="icon is-small">
                  <i class="fa fa-sign-out"></i>
                </span>
                Sign Out
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <add-activity v-if="activeModal == 'stocks'" @notifyMessage="onNotifyMessage" @actionTaken="onActionTaken"
      @swapModal="swapModal"></add-activity>
    <add-cash :portfolioId="this.portfolio.id" v-if="activeModal == 'cash'" @notifyMessage="onNotifyMessage"
      @actionTaken="onActionTaken" @swapModal="swapModal" :backCurrency="this.portfolio.currency"></add-cash>
    <add-real-estate :portfolioId="this.portfolio.id" v-if="activeModal == 'realestate'" @notifyMessage="onNotifyMessage"
      @actionTaken="onActionTaken" @swapModal="swapModal" :backCurrency="this.portfolio.currency"></add-real-estate>
    <share ref="share"></share>
  </nav>
</template>

<script>
import { Zune } from "@/services/zune.service";
import AddActivity from "./AddActivity";
import AddCash from "./AddCash";
import AddRealEstate from "./AddRealEstate";
import Share from "./Share";
import DropDown from "./custom/DropDown.vue";

export default {
  components: {
    AddActivity,
    AddCash,
    AddRealEstate,
    Share,
    DropDown,
  },
  props: {
    portfolio: Object
  },
  computed: {
    currentCurrency() {
      return this.portfolio.currency;
    },
  },
  watch: {
    currentCurrency() {
      this.selectedCurrency = this.currentCurrency;
    }
  },
  methods: {
    swapModal(selected) {
      this.activeModal = selected;
    },
    async searchInputChaged(text) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.isReady = false;
        let response = await Zune.search(text);
        if (!text) {
          this.options = response.recent || [];
        } else {
          this.options = response.data;
        }
        this.isReady = true;
      }, 400);
    },
    onSuggestionSelected(selected) {
      if (selected.symbol) {
        this.$router.push('/company/' + selected.symbol)
      }
    },
    async onNotifyMessage(msg) {
      this.$emit("notifyMessage", msg);
    },
    async onActionTaken() {
      this.swapModal('')
      this.$emit("actionTaken", true);
    },
    share() {
      this.$refs.share.openModal();
    },
    toggleNavbar() {
      this.isNavbarActive = !this.isNavbarActive;
    },
    clear() {
      this.$refs.suggestionInput.reset();
    },
    async swapCurrency(selected) {

      this.selectedCurrency = selected;

      const response = await Zune.addCurrency(selected);

      this.$emit("actionTaken", true);
      if (response.success) {
        this.$emit("notifyMessage", selected + " currency selected.");
      }
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    // async fetchProfile() {
    //   var response = await Zune.profile();
    //   if (response.success) {
    //     var profile = response.data;
    //     this.$store.dispatch('addAccount', profile);
    //   }
    // }
  },
  data() {
    return {
      activeModal: '',
      isModified: true,
      isAuthenticated: true,
      isNavbarActive: false,
      selectedCurrency: '',
      supportedCurrencies: [
        "BRL",
        "CHF",
        "CNY",
        "DKK",
        "EUR",
        "INR",
        "NOK",
        "PLN",
        "SEK",
        "USD",
      ],
      options: [],
      timer: 0
    };
  },
};
</script>

<style scoped></style>
