import { getInstance } from "./index";

export const signupGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next({ path: '/' }); // should never happen
    }

    if (to.query.email) {
        // Otherwise, log in
        authService.loginWithRedirect({ appState: { targetUrl: '/' }, screen_hint: 'signup', login_hint: to.query.email });
    } else {
        authService.loginWithRedirect({ appState: { targetUrl: '/' }, screen_hint: 'signup' });
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};