<template>
  <div id="app">
    <Navigation ref="navigation" :portfolio="portfolio" @notifyMessage="onNotifyMessage" @actionTaken="onActionTaken"
      v-if="!$auth.loading && $auth.isAuthenticated" />
    <SideBar :key="appContext.actionTaken" v-if="!$auth.loading && $auth.isAuthenticated" class="sidebar" />
    <div v-if="this.$auth.isAuthenticated && this.accountType === 0 && this.$route.name !== 'ProfessionalSetup'" class="columns is-centered notification is-warning">
      <div class="column is-half has-text-centered">
        <div>
          <p>Hey! 👋🏼 Consider supporting us by upgrading to PRO. This way we can keep working on new features while you get access to all the good stuff!</p>
              <button class="button is-success mt-5" :class="{ 'is-loading': isLoadingSubscriptionLink }" @click="getSubscriptionLink">
              <strong>{{ $t('app.buttons.becomePro') }}</strong><i class="ml-2 is-size-3 has-text-secondary"></i>
            </button>
        </div>
      </div>
    </div>
    <router-view v-bind:class="{ dashboard: !$auth.loading && $auth.isAuthenticated }" :appContext="appContext"
      @notifyMessage="onNotifyMessage" @notifyMounted="onNotifyMounted" @swapModal="onSwapModal" @loaded="onLoaded" />
    <Notification v-if="notificationMessage !== ''" :message="notificationMessage" />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import SideBar from "@/components/SideBar.vue";
import Notification from "@/components/infrastructure/Notification.vue";
import { Zune } from "@/services/zune.service";

export default {
  components: {
    Navigation,
    SideBar,
    Notification,
  },
  watch: {
    // mainly used to trigger fetching the session AFTER auth
    async isAuthenticated(newVal) {
      if (newVal) {
        await this.fetchProfile()
      }
    },
    async accountHasHoldings(newVal) {
      if (newVal) {
        await this.fetchProfile()
      }
    }
  },
  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
    accountType() {
      return this.$store.state.accountType
    }
  },
  methods: {
    onSwapModal(selected) {
      this.$refs.navigation.swapModal(selected);
    },
    onNotifyMessage(msg) {
      this.notificationMessage = msg;
      setTimeout(() => (this.notificationMessage = ""), 5000);
    },
    onActionTaken() {
      this.appContext.actionTaken = !this.appContext.actionTaken;
    },
    onNotifyMounted() {
      this.$refs.navigation.clear();
    },
    onLoaded(portfolio) {
      this.portfolio.id = portfolio.id;
      this.portfolio.name = portfolio.name;
      this.portfolio.currency = portfolio.currency;
      this.portfolio.uniqueShareLink = portfolio.uniqueShareLink;

      this.$refs.navigation.clear();
    },
    async fetchProfile() {
      var response = await Zune.profile();
      if (response.success) {
        var profile = response.data;
        this.$store.dispatch('addAccount', profile);
      }
    },
    async getSubscriptionLink() {
      this.isLoadingSubscriptionLink = true;
      const response = await Zune.getCartReference('monthly');

      if (response.success) {
        var clientReference = response.data;
        window.location.href = `https://buy.stripe.com/4gw6st2dZdY78UM5kw?client_reference_id=${clientReference}`;
      }

      this.isLoadingSubscriptionLink = false;
    }
  },
  data() {
    return {
      clientReference: '',
      notificationMessage: "",
      portfolio: {
        name: "",
        currency: "",
        uniqueShareLink: ""
      },
      appContext: {
        actionTaken: Boolean
      }
    };
  },
};
</script>


<style>
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/main.css";
</style>
