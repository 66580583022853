<template>
  <loading-wrapper :loading="monthlyPerformanceChartLoading">
    <line-chart v-if="!monthlyPerformanceChartLoading && growthChart.data.labels.length > 0"
      :chartData="this.growthChart.data" :options="this.growthChart.options" ref="lineChart"></line-chart>
    <div v-if="!monthlyPerformanceChartLoading && growthChart.data.labels.length == 0"
      class="columns is-vcentered has-text-centered">
      <div class="column has-text-centered">
        <span>
          We don't yet have enough data on your portfolio to calculate its daily growth. <br />Please wait a few more days
          or upload individual transactions.
        </span>
      </div>
    </div>
  </loading-wrapper>
</template>

<script>
import { Zune } from "@/services/zune.service";

import LoadingWrapper from "./LoadingWrapper.vue";
import LineChart from "../zuneCharts/LineChart";

export default {
  props: {
    portfolio: Number,
    period: String
  },
  components: {
    LoadingWrapper,
    LineChart
  },
  watch: {
    period() {
      this.calculateGrowthChart(this.period);
    },
  },
  methods: {
    async calculateGrowthChart(period = "12-months") {
      this.monthlyPerformanceChartLoading = true;
      this.monthlyPerformanceChartReady = false;
      var growthChartResponse = await Zune.growth(period);

      const growthChart = growthChartResponse.data;
      this.growthChart.data.labels = [];

      this.growthChart.options.scales.yAxes[0].ticks.min = Number.MAX_VALUE;
      this.growthChart.data.datasets[0].data = [];

      Object.keys(growthChart).sort().forEach(key => {
        this.growthChart.data.labels.push(key);
        const entry = growthChart[key].value;

        this.growthChart.data.datasets[0].data.push(entry);
        if (entry < this.growthChart.options.scales.yAxes[0].ticks.min) {
          this.growthChart.options.scales.yAxes[0].ticks.min = entry;
        }
      });

      this.growthChart.options.scales.yAxes[0].ticks.min =
        this.growthChart.options.scales.yAxes[0].ticks.min * 0.95;

      this.monthlyPerformanceChartReady = true;
      this.monthlyPerformanceChartLoading = false;
    },
  },
  async mounted() {
    await this.calculateGrowthChart(this.period)
  },
  data() {
    const self = this;
    return {
      monthlyPerformanceChartReady: false,
      monthlyPerformanceChartLoading: true,
      growthChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Stock Holdings",
              data: [],
              backgroundColor: "rgba(1, 116, 188, 0.50)",
              borderColor: "rgba(1, 116, 188, 0.50)",
              pointBackgroundColor: "rgba(171, 71, 188, 1)"
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Stock Holdings",
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  callback: function (value) {
                    return self.toLocaleNumber(value);
                  },
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>