<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('account.section.deleteTitle') }}</p>
        <button class="delete" aria-label="close" @click="closeModal()"></button>
      </header>
      <section class="modal-card-body">
        <div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">{{ $t('account.fields.deleteConfirmation') }}</label>
                <div class="control">
                  <input class="input" type="text" v-model="accountDeleteText" v-bind:class="{
                    'is-danger': errors && !accountDeleteText,
                  }" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="confirmDelete()"
          :disabled="!accountDeleteText || accountDeleteText.trim().toLowerCase() !== 'delete'">{{ $t('account.buttons.deleteUpperCase') }}</button>
        <button class="button is-small is-primary" style="justify-content: end" type="button" @click="closeModal()">
          {{ $t('app.buttons.cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>
  
<script>
import { Zune } from "@/services/zune.service";

export default {
  methods: {
    async confirmDelete() {
      const response = await Zune.deleteAccount();
      if (response.success) {
        window.location.href = 'https://zune.money';
      } else {
        alert(this.$t('account.messages.deleteError'))
      }
    },
    openModal() {
      this.isActive = true;
    },
    closeModal() {
      this.errors = false;
      this.isActive = false;
    },
  },
  data() {
    return {
      errors: false,
      isActive: false,
      accountDeleteText: ""
    };
  },
};
</script>
  
<style scoped></style>