<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["options", "totalValue", "invested", "gains", "cash", "realEstate"],
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
  mounted() {
    
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num).toFixed(2)
    }

    const formatedTotalValue = kFormatter(this.totalValue);

    this.addPlugin({
      beforeDraw: function(chart) {
        var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";

        var legendHeight =  chart.legend.height;

        var text = formatedTotalValue,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2 + legendHeight/2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    });

    var labels = [ "Invested", "Gains" ];
    var data = [this.invested, this.gains];
    var colors = ["rgb(255, 99, 132)","rgb(54, 162, 235)"];
    if (this.cash > 0) {
      labels.push("Cash");
      colors.push("rgb(75,0,130)");
      data.push(this.cash);
    }

    if (this.realEstate > 0) {
      labels.push("Real Estate");
      colors.push("rgb(25,0,720)");
      data.push(this.realEstate);
    }

    const chartData = {
        labels: labels,
        datasets: [
          {
            label: "",
            data: data,
            backgroundColor: colors,
            hoverOffset: 4,
          },
        ],
      };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
    };

    this.renderChart(chartData, options);
  },
};
</script>
