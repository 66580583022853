<template>
    <div class="modal" v-bind:class="{ 'is-active': isActive }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Manage Position</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <div>
                    <div>
                        <p class="heading has-text-weight-bold">ISIN</p>
                        <p>
                            {{ productKey }}
                        </p>
                    </div>
                </div>

                <div class="mt-3">
                    <p class="heading has-text-weight-bold">Product</p>
                    <p>
                        {{ productName }}
                    </p>
                </div>
                <div class="tabs mt-5">
                    <ul>
                        <li class="is-active"><a>Find Symbol ...</a></li>
                    </ul>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Symbol</label>
                                    <div class="control" v-bind:class="{ 'is-loading is-large': !isReady }">
                                        <DropDown :options="options" v-on:selected="onSuggestionSelected"
                                            v-on:filter="inputChange" :disabled="false" name="zipcode" :maxItem="30"
                                            placeholder="Search for stock symbol ..." :inputClasses="{
                                                'is-danger':
                                                    errors &&
                                                    (!newHoldingSetup.symbol ||
                                                        !newHoldingSetup.exchange),
                                            }" ref="dropDown">
                                        </DropDown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs">
                            <ul>
                            </ul>
                        </div>
                        <table class="table is-fullwidth is-striped">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(transaction) in transactions" :key="transaction.id">
                                    <td>{{ transaction.type }}</td>
                                    <td>
                                        {{ toLocaleDate(new Date(transaction.executionDate)) }}
                                    </td>
                                    <td>{{ transaction.quantity }}</td>
                                    <td>{{ transaction.price }}</td>
                                    <td>
                                        {{ transaction.transactionTotalCurrency }}
                                        {{ transaction.transactionTotal }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot" style="justify-content: end">
                <button class="button" @click="closeModal()">Close</button>
                <button class="button is-primary" type="button" v-bind:class="{ 'is-loading': isSetupInProgress }" @click="configureHolding()">
                    Confirm
                </button>
            </footer>
        </div>
    </div>
</template>
  
<script>
import { Zune } from "@/services/zune.service";
import DropDown from "../custom/DropDown";

export default {
    components: {
        DropDown
    },
    methods: {
        async configureHolding() {
            this.isSetupInProgress = true;
            if (
                !this.newHoldingSetup.exchange ||
                !this.newHoldingSetup.symbol
            ) {
                this.errors = true;
            } else {
                const response = await Zune.configureHolding(
                    this.portfolioId,
                    {
                        productKey: this.productKey,
                        symbol: this.newHoldingSetup.symbol,
                        exchange: this.newHoldingSetup.exchange,
                        figi: this.newHoldingSetup.figi,
                    },
                );

                if (response.success) {
                    this.actionTaken = true;
                    this.closeModal();
                }
            }
            this.isSetupInProgress = false;
        },
        async inputChange(text) {
            this.isReady = false;

            // js native call
            clearTimeout(this.searchTimeoutId);

            // set a new timeout to call Zune.search after a delay (e.g., 1000 milliseconds)
            this.searchTimeoutId = setTimeout(async () => {
                let response = await Zune.search(text);
                if (!text) {
                    this.options = response.recent || [];
                } else {
                    this.options = response.data;
                }
                this.isReady = true;
            }, 400);
        },
        onSuggestionSelected(item) {
            this.newHoldingSetup.symbol = item.symbol;
            this.newHoldingSetup.name = item.name;
            this.newHoldingSetup.exchange = item.exchange;
            this.newHoldingSetup.priceCurrency = item.currency;
            this.newHoldingSetup.figi = item.figi;
        },
        async openModal(holding, onModalOpen) {
            this.productKey = holding.key;
            this.productName = holding.name;
            this.transactions = holding.transactions;

            this.isActive = true;
            onModalOpen();
        },
        closeModal() {
            this.isActive = false;
            this.position = {};
            this.newHoldingSetup = {};
            this.errors = false;

            if (this.actionTaken) {
                this.$emit("actionTaken", true);
            }
            this.actionTaken = false;
        },
    },
    props: {
        portfolioId: Number,
    },
    data() {
        return {
            actionTaken: false,
            isReady: true,
            productKey: '',
            productName: '',
            transactions: [],
            newHoldingSetup: {
                symbol: '',
                exchange: '',
                name: '',
                priceCurrency: ''
            },
            searchTimeoutId: undefined,
            isSetupInProgress: false,
            options: [],
            isActive: false,
            errors: false,
        };
    },
};
</script>
  
<style scoped></style>
  