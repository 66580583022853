<template>
  <section>
    <loading-wrapper :loading="chartLoading">
      <bar-chart :class="{ componentChart: $store.state.accountType === 0 &&  $store.state.isPremiumEnabled }" :chartData="this.chartSetup.data" :options="this.chartSetup.options"
        v-if="!this.chartLoading && this.chartSetup.data.labels.length > 0"></bar-chart>
    </loading-wrapper>
  </section>
</template>

<script>
import { Zune } from "@/services/zune.service";

import LoadingWrapper from "./LoadingWrapper.vue";
import BarChart from "@/components/zuneCharts/BarChart";
import { Colors } from "../../colors"

export default {
  props: {
    portfolio: Number,
    period: String
  },
  components: {
    LoadingWrapper,
    BarChart,
  },
  methods: {
    async calculateGrowthChart() {
      const gainsForPeriodResponse = await Zune.gainLossChart();
      const gainsForPeriod = gainsForPeriodResponse.data;
      this.gainsForPeriod = gainsForPeriod.gainsForPeriod;
      this.sP500GainsForPeriod = gainsForPeriod.sP500GainsForPeriod;
      this.dowGainsForPeriod = gainsForPeriod.dowGainsForPeriod;
      this.nasdaqGainsForPeriod = gainsForPeriod.nasdaqGainsForPeriod;
    },
    mountChart() {
      var labels = [];
      var portfolio = [];
      var datasets = [];

      Object.keys(this.gainsForPeriod).sort().forEach((key) => {
        labels.push(key.substring(0, 7));
        portfolio.push(this.gainsForPeriod[key].performance * 100);
      });

      if (labels.length == 0) {
        Object.keys(this.sP500GainsForPeriod).sort().forEach((key) => {
          labels.push(key.substring(0, 7));
        });
      }

      if (this.sP500GainsForPeriod) {
        var sp500 = []
        Object.keys(this.sP500GainsForPeriod).sort().forEach((key) => {
          sp500.push(this.sP500GainsForPeriod[key].performance * 100);
        });
        if (sp500 && sp500.length > 0) {
          datasets.push({
            label: "SP500(SPY) Performance",
            backgroundColor: Colors.SECONDARY,
            data: sp500,
          });
        }
      }

      if (this.dowGainsForPeriod) {
        var dow = []
        Object.keys(this.dowGainsForPeriod).sort().forEach((key) => {
          dow.push(this.dowGainsForPeriod[key].performance * 100);
        });
        if (dow && dow.length > 0) {
          datasets.push({
            label: "Dow(DJIA) Performance",
            backgroundColor: Colors.THIRD,
            data: dow,
          });
        }
      }

      if (this.nasdaqGainsForPeriod) {
        var nasdaq = []
        Object.keys(this.nasdaqGainsForPeriod).sort().forEach((key) => {
          nasdaq.push(this.nasdaqGainsForPeriod[key].performance * 100);
        });
        if (nasdaq && nasdaq.length > 0) {
          datasets.push({
            label: "Nasdaq(QQQ) Performance",
            backgroundColor: Colors.FOURTH,
            data: nasdaq,
          });
        }
      }

      this.chartSetup.data = {
        labels,
        datasets: [
          {
            label: "Portfolio Holdings",
            backgroundColor: Colors.PRIMARY,
            data: portfolio,
          },
          ...datasets
        ],
      };
      this.chartSetup.options = {
        responsive: true,
        maintainAspectRatio: false,
        // tooltips: {
        //   callbacks: {
        //     label: function (value) {
        //       //return (value).toFixed(0) + '%';
        //       return value;
        //     },
        //   },
        // },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value) {
                return (value).toFixed(0) + '%';
              },
            }
          }]
        }
      };
    }
  },
  async mounted() {
    this.chartLoading = true;
    await this.calculateGrowthChart();
    this.mountChart();

    console.log(this.chartSetup.data)
    this.chartLoading = false;
  },
  data() {
    return {
      chartLoading: true,
      mounted: false,
      gainsForPeriod: {},
      sP500GainsForPeriod: {},
      chartSetup: {},
    };
  },
};
</script>

<style>
.componentChart > canvas {
  filter: blur(10px);
}
</style>