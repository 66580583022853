import { Http } from './http.init'
import { ErrorWrapper, ResponseWrapper } from './http.execution'

export class Community {

  static async getFeed () {
    try {
      const response = await new Http({ auth: true }).get(`community/recent`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async sendLike (postId) {
    try {
      const response = await new Http({ auth: true }).put(`community/${postId}`, {
        operationId: 1
      })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async sendDislike (postId) {
    try {
      const response = await new Http({ auth: true }).put(`community/${postId}`, {
        operationId: 2
      })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async post (message) {
    try {
      const response = await new Http({ auth: true }).post(`community`, message)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }

  static async remove (postId) {
    try {
      const response = await new Http({ auth: true }).delete(`community/${postId}`, {
        operationId: 2
      })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      return new ErrorWrapper(error)
    }
  }
}