<template>
    <div class="columns" v-if="faq && faq.companyName">
        <section class="column">
            <h2 class="title">{{ faq.symbol }} - FAQ</h2>
            <article if="stockprice" class="mb-4" v-if="faq.currentPriceCurrency && faq.currentPrice">
                <h3 class="subtitle">What is {{ faq.companyName }}'s current stock price?</h3>
                <p>
                    {{ faq.companyName }}'s ({{ faq.symbol }}) current stock price is {{ faq.currentPriceCurrency }} {{
                        faq.currentPrice }}
                    <span v-if="faq.currentPriceCloseTime">as of {{ toLocaleDate(new Date(faq.currentPriceCloseTime))
                    }}</span>. Please note that stock prices can fluctuate throughout the trading day.
                </p>
            </article>
            <article if="dividendyield" class="mb-4" v-if="faq.dividendYield">
                <h3 class="subtitle">What is {{ faq.companyName }}'s dividend yield?</h3>
                <p>
                    {{ faq.companyName }}'s ({{ faq.symbol }}) current dividend yield is {{ toLocaleNumber(faq.dividendYield
                        * 100) + "%" }}. This is calculated by dividing the annual dividend per share by the current stock
                    price."
                </p>
            </article>
            <article if="stockprice" class="mb-4" v-if="faq.currentPriceCurrency && faq.currentPrice">
                <h3 class="subtitle">When is {{ faq.companyName }}'s' next ex dividend date?</h3>
                <p>
                    {{ faq.companyName }}'s ({{ faq.symbol }}) ex-dividend date {{ new Date() > new Date(faq.exDividendDate)
                        && "was" || "is" }} on {{
        toLocaleDate(new Date(faq.exDividendDate)) }}.
                    {{ faq.companyName }} distributed {{ faq.currentPriceCurrency }} {{
                        toLocaleNumber(faq.dividendAmount) }} per
                    share that represents a {{
                        faq.dividendYield
                        ? toLocaleNumber(faq.dividendYield * 100) + "%"
                        : "-"
                    }} dividend yield (TTM).
                </p>
            </article>
        </section>
    </div>
</template>
  
<script>

export default {
    props: {
        faq: Object
    },
    data() {
        return {};
    },
};
</script>