<template>
  <section class="section">
    <section class="columns is-centered">
      <div class="column is-two-thirds">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://zune.money">
              <strong>ZuneMoney</strong><span class="is-size-7">Beta</span></a>
            <!-- <div class="navbar-burger burger"><span></span><span></span><span></span></div> -->
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
              data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
              <a class="navbar-item is-hidden">
                Community
              </a>
              <a class="navbar-item is-hidden">
                Blog
              </a>
              <a class="navbar-item" href="https://zune.money/#dividend-tracker">
                Dividend Tracking
              </a>
              <a class="navbar-item" href="https://zune.money/#portfolio-performance">
                Performance Comparision
              </a>
              <a class="navbar-item" href="https://app.zune.money/demo">
                Demo
              </a>
              <div class="navbar-item has-dropdown is-hoverable is-hidden">
                <a class="navbar-link">
                  Features
                </a>
                <div class="navbar-dropdown">
                  <a class="navbar-item">
                    Dividend Tracking
                  </a>
                  <a class="navbar-item">
                    Performance Comparision
                  </a>
                  <!-- <a class="navbar-item is-hidden">
                                    Dividend Alerts
                                </a> -->
                  <hr class="navbar-divider">
                  <a class="navbar-item">
                    Demo
                  </a>
                </div>
              </div>
            </div>
            <div class="navbar-end">
              <div class="navbar-item">
                <div class="buttons">
                  <a class="button is-primary" href="https://app.zune.money/signup">
                    <strong>Sign up</strong>
                  </a>
                  <a class="button is-light" href="https://app.zune.money">
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <!-- REGISTER -->
        <div class="columns mt-6">
          <div class="column">
            <h2 class="heading is-size-5">Welcome to Your Gateway to Dutch Dividend Stocks</h2>
            <p>At ZuneMoney, we're your trusted resource for exploring dividend-paying stocks in the Netherlands. Whether
              you're a seasoned investor or just beginning your journey, our platform is designed to simplify the process
              of
              discovering and tracking Dutch dividend stocks. The Dutch stock market offers a diverse array of investment
              opportunities, and we're here to help you navigate it. Our goal is to empower you with the insights and
              tools
              you need to make informed investment decisions in the dynamic Dutch market. Join us today and unlock the
              potential of dividend investing in the Netherlands.</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <section class="hero bd-hero bd-is-basic">
              <div class="columns">
                <div class="column">
                  <section class="hero bd-hero is-warning">
                    <div class="hero-body">
                      <div class="bd-hero-body">
                        <div class="bd-hero-heading">
                          <h2 class="heading is-size-5">Discover Dividend-Paying Stocks in the Netherlands</h2>
                          <p>Explore a diverse range of dividend-paying stocks in the Dutch market with ZuneMoney. Our
                            platform provides a comprehensive list of dividend stocks, offering you a wide spectrum of
                            investment options. From high-yield dividend giants to reliable income generators, we present
                            an
                            extensive view of your Dutch dividend stocks. Stay fully informed, spot income-generating
                            trends,
                            and make confident investment decisions with ZuneMoney's dividend tracking data. Start your
                            journey to financial success today.</p>
                        </div>
                      </div>
                    </div>

                    <!-- Hero footer: will stick at the bottom -->
                    <div class="hero-foot">
                      <nav class="tabs is-boxed is-pulled-right">
                        <div class="container">
                          <ul>
                            <li> <a href="https://app.zune.money/signup" class="nav-link"><button
                                  class="button is-success has-text-weight-bold">Try
                                  Now!🚀</button></a></li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
        <!-- / REGISTER -->
        <!-- <div class="buttons" style="visibility:hidden">
      <button type="button" class="button is-white" @click="period('week')"
        :class="{ 'is-light': this.$route.params.country == 'germany' }">
        GERMANY
      </button>
      <button type="button" class="button is-white" @click="period('month')"
        :class="{ 'is-light': this.$route.params.country == 'netherlands' }">
        Netherlands
      </button>
      <button type="button" class="button is-white" @click="period('12-months')"
        :class="{ 'is-light': this.$route.params.country == 'brazil' }">
        Brazil
      </button>
      <button type="button" class="button is-white" @click="period('5-years')"
        :class="{ 'is-light': this.$route.params.country == 'usa' }">
        USA
      </button>
      <button type="button" class="button is-white" @click="period('5-years')"
        :class="{ 'is-light': this.$route.params.country == 'france' }">
        France
      </button>
      <button type="button" class="button is-white" @click="period('5-years')"
        :class="{ 'is-light': this.$route.params.country == 'italy' }">
        Italy
      </button>
    </div> -->
        <!-- DIVIDEND INSIGHTS -->
        <div class="columns">
          <div class="column">
            <h2 class="heading is-size-5">List of Dividend-Paying Stocks in the Netherlands</h2>
            <p>Here, you'll find a curated list of dividend-paying stocks in the Netherlands. Our selection includes a
              variety of companies from different sectors, each offering its unique value proposition to dividend
              investors.
              Explore the list below and click on a stock to start tracking its performance with ZuneMoney's portfolio
              tracking tools.</p>
            <table class="
            table
            is-fullwidth is-striped is-hoverable is-fullwidth mt-6
          ">
              <thead>
                <tr>
                  <th @click="sort('companyName')">
                    Company
                  </th>
                  <th @click="sort('yield')">
                    Yield
                  </th>
                  <th @click="sort('currentPrice')">
                    Price
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="company in sortedData" :key="company.id">
                  <td>
                    <router-link :to="{
                      name: 'Stock',
                      params: {
                        symbol: company.symbol,
                      },
                    }" class="nav-link">
                      <span> {{ company.companyName }}
                      </span>
                    </router-link>
                  </td>
                  <td>
                    {{ company.yield ? toPercentNumber(company.yield) : "" }}
                  </td>
                  <td>
                    <span class="is-size-7" v-if="company.currentPrice">{{ company.currentPriceCurrency
                    }}</span>
                    {{
                      company.currentPrice ? toLocaleNumber(company.currentPrice) : ""
                    }}
                  </td>
                  <td>
                    <router-link :to="{
                          name: 'Stock',
                          params: {
                            symbol: company.symbol,
                          },
                        }" class="nav-link">
                      <span>Details</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- / DIVIDEND INSIGHTS -->

        <!-- <div class="columns" v-if="this.companyInfo.faq">

      <div class="column" v-for="faq in this.companyInfo.faq" :key="faq.id">
        <h2>{{ faq.question }}</h2>
        <p>{{ faq.answer }}</p>
      </div>
    </div> -->

        <div class="columns">
          <div class="column">
            <section class="hero bd-hero bd-is-basic">
              <div class="columns">
                <div class="column">
                  <section class="hero bd-hero is-info">
                    <div class="hero-body">
                      <div class="bd-hero-body">
                        <div class="bd-hero-heading">
                          <h2 class="heading is-size-5"> Ready to Dive into Dutch Dividend Stocks?
                          </h2>
                          <p>Join ZuneMoney today and start exploring the world of dividend investing in The Netherlands.
                            Whether
                            you're
                            a seasoned investor or new to the game, our platform is your gateway to informed
                            decision-making
                            and financial
                            success. Don't miss out on valuable opportunities – take the first step now and unlock your
                            dividend
                            investment
                            potential.</p>
                        </div>
                      </div>
                    </div>

                    <!-- Hero footer: will stick at the bottom -->
                    <div class="hero-foot">
                      <nav class="tabs is-boxed is-pulled-right">
                        <div class="container">
                          <ul>
                            <li> <a href="https://app.zune.money/signup" class="nav-link"><button
                                  class="button is-success has-text-weight-bold">Join
                                  Now!🚀</button></a></li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>

        <!-- <h5 class="title">Disclaimers</h2> -->
        <div class="columns hero bd-hero bd-is-basic">
          <div class="column">
            <small>ZuneMoney and its administrators do not assume any responsibility for the accuracy and completeness of
              the
              information provided.</small>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// @ is an alias to /src

import { Zune } from "@/services/zune.service";
import datasort from "../../ux/datasort"

export default {
  name: "Home",
  metaInfo() {
    return {
      title: `Your Gateway to Dutch Dividend Stocks - ZuneMoney`,
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          name: 'description',
          content: `Explore a diverse range of dividend-paying stocks in the Dutch market with ZuneMoney. Start your journey to financial success today.`
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: `Dutch dividend stocks, Netherlands, ZuneMoney, dividend investing, Dutch stock market`
        }
      ]
    }
  },
  computed: {
    sortedData() {
      return datasort(this.sortColumn, this.sortDirection, this.listings)
    },
  },
  methods: {
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }

      this.listings = this.sortedData
    },
    async getCountryInfo() {
      const response = await Zune.publicCountryList(
        "Netherlands"
      );
      this.listings = response.data;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.getCountryInfo();
  },
  async mounted() {
    await this.getCountryInfo();
  },
  data() {
    return {
      sortColumn: "companyName",
      sortDirection: "asc",
      listings: [],
    };
  },
};
</script>
