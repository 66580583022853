<template>
  <bar-chart
    :chartData="this.chartSetup.data"
    :options="this.chartSetup.options"
    ref="chart"
    v-if="this.ready"
  ></bar-chart>
</template>

<script>
import BarChart from "@/components/zuneCharts/BarChart";

export default {
  props: {
    flows: Object,
  },
  components: {
    BarChart,
  },
  methods: {
    update() {
      this.chartSetup.data.datasets[0].data = [
            this.flows.stocks.amount,
            this.flows.cash.amount,
            this.flows.business.amount,
            this.flows.crypto.amount,
            this.flows.credit.amount,
            this.flows.loans.amount,
            this.flows.other.amount,
          ];
      this.$refs.chart.update();
    },
  },
  mounted() {
    this.ready = false;

    this.chartSetup.data = {
      labels: [
        "Stocks",
        "Cash",
        "Business",
        "Crypto",
        "Credit",
        "Loans",
        "Other",
      ],
      datasets: [
        {
          label: "Cash Flow",
          backgroundColor: [
            "rgba(1, 116, 188, 0.50)",
            "rgba(1, 116, 188, 0.50)",
            "rgba(1, 116, 188, 0.50)",
            "rgba(1, 116, 188, 0.50)",
            "#f87979",
            "#f87979",
            "#f87979",
          ],
          data: [
            this.flows.stocks.amount,
            this.flows.cash.amount,
            this.flows.business.amount,
            this.flows.crypto.amount,
            this.flows.credit.amount,
            this.flows.loans.amount,
            this.flows.other.amount,
          ],
        },
      ],
    };

    this.chartSetup.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              callback: function (value) {
                return value.toFixed(0);
              },
            },
          },
        ],
      },
    };
    this.ready = true;
  },
  data() {
    return {
      ready: false,
      chartSetup: {},
    };
  },
};
</script>

<style scoped>
</style>