<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    chartData: Object,
    options: Object
  },
  methods: {
    update() {
      this.$data._chart.options = this.options;
      // this.$data._chart.data = this.data;
      this.$data._chart.update();
    },
  },
  mixins: [ mixins.reactiveProp ],
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>