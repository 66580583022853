<template>
  <section class="section">
    <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
      <div class="loader is-loading"></div>
    </div>
    <div v-if="ready">
      <!-- HEADER - COMPANY -->
      <div class="columns">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">{{ companyInfo.exchange }}</p>
                <p class="title is-size-5-mobile">
                  {{ companyInfo.companyName }}
                </p>
              </div>
            </div>
          </div>
          <div class="level is-mobile" v-if="false">
            <div class="level-item has-text-centered control buttons">
              <button class="button is-danger" type="button" @click="manage()">
                SELL
              </button>
              <button class="button is-primary" type="button" @click="manage()">
                BUY
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- / HEADER - COMPANY -->

      <!-- BASIC STATS -->
      <div class="columns">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Symbol</p>
                <p class="title is-size-5-mobile">
                  {{ $route.params.symbol }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered is-hidden-mobile">
              <div>
                <p class="heading">Price</p>
                <p class="title">
                  <span class="is-size-6">{{ companyInfo.currentPriceCurrency }}</span>
                  <span class="is-white">{{ toLocaleNumber(companyInfo.currentPrice) }}</span>
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered" v-if="companyInfo.stat">
              <div>
                <p class="heading">PE</p>
                <p class="title is-size-5-mobile">
                  {{ companyInfo.stat.peRatio ? toLocaleNumber(companyInfo.stat.peRatio) : "-" }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered is-hidden-mobile" v-if="companyInfo.stat">
              <div>
                <!-- We only calculate the dividends of your current holdings -->
                <p class="heading">Div. Yield(%)</p>
                <p class="title is-size-5-mobile">
                  {{
      companyInfo.stat.dividendYield ? (toLocaleNumber(companyInfo.stat.dividendYield * 100) + "%") : "-"
    }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered is-hidden-mobile" v-if="companyInfo.extendedStats">
              <div>
                <!-- We only calculate the dividends of your current holdings -->
                <p class="heading">Payout Ratio(%)</p>
                <p class="title is-size-5-mobile">
                  {{
        companyInfo.extendedStats.payoutRatio ? toLocaleNumber(companyInfo.extendedStats.payoutRatio * 100) +
          "%" : "-"
      }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-hidden-tablet">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Price</p>
                <p class="title">
                  <span class="is-size-6">{{ companyInfo.currentPriceCurrency }}</span>
                  <span class="is-white is-size-5-mobile">{{ toLocaleNumber(companyInfo.currentPrice) }}</span>
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered" v-if="companyInfo.stat">
              <div>
                <!-- We only calculate the dividends of your current holdings -->
                <p class="heading">Div. Yield(%)</p>
                <p class="title is-size-5-mobile">
                  {{
      companyInfo.stat.dividendYield ? (toLocaleNumber(companyInfo.stat.dividendYield * 100) + "%") : "-"
    }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / BASIC STATS -->

      <!-- PRICE HISTORY SELECTOR -->
      <div class="level" v-if="false" >
        <div class="level-left"></div>
        <div class="level-right">
          <div class="buttons">
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'week' }"
              @click="period('week')">
              WEEK
            </button>
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'month' }"
              @click="period('month')">
              MONTH
            </button>
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '12-months' }"
              @click="period('12-months')">
              12-MONTHS
            </button>
            <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '5-years' }"
              @click="period('5-years')">
              5-YEARS
            </button>
          </div>
        </div>
      </div>
      <!-- / PRICE HISTORY SELECTOR -->

      <!-- COMPANY SUMMARY & PRICE HISTORY -->
      <div class="columns">
        <div class="column is-one-third" v-if="false">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div class="flex column">
                <p class="heading"></p>
                <p class="title is-size-5-mobile"></p>
                <div class="body">
                  <div class="card events-card">
                    <header class="card-header">
                      <p class="card-header-title">Summary</p>
                    </header>
                    <div class="card-table has-text-left">
                      <div class="content">
                        <table class="table is-fullwidth is-striped">
                          <tbody>
                            <tr v-if="companyInfo.stat != null">
                              <td>
                                <span>52 Weeks Range</span>
                              </td>
                              <td class="level-right">
                                {{
      toLocaleNumber(companyInfo.stat.week52low)
    }}-{{
        toLocaleNumber(companyInfo.stat.week52high)
      }}
                              </td>
                            </tr>
                            <tr v-if="companyInfo.stat != null">
                              <td>
                                <span>PE (TTM)</span>
                              </td>
                              <td class="level-right">
                                {{ companyInfo.stat.peRatio ? toLocaleNumber(companyInfo.stat.peRatio) : "-" }}
                              </td>
                            </tr>
                            <tr v-if="companyInfo.stat != null">
                              <td>
                                <span>EPS (TTM)</span>
                              </td>
                              <td class="level-right">
                                {{ companyInfo.stat.ttmEPS ? toLocaleNumber(companyInfo.stat.ttmEPS) : "-" }}
                              </td>
                            </tr>
                            <tr v-if="companyInfo.stat != null">
                              <td>
                                <span>Employees</span>
                              </td>
                              <td class="level-right">
                                {{ companyInfo.stat.employees || "-" }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Website</span>
                              </td>
                              <td v-if="companyInfo.website" class="level-right">
                                <a :href="companyInfo.website" target="_blank">{{ companyInfo.website }}</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
              </div>
            </div>
          </div>
        </div>
        <line-chart v-if="false" :chartData="this.priceChart.data" :options="this.priceChart.options" class="column is-two-thirds"
          ref="priceChart"></line-chart>
      </div>
      <!-- / COMPANY SUMMARY & PRICE HISTORY -->
      <!-- ABOUT -->
      <div class="columns" v-if="this.companyInfo.description">
        <div class="column">
          <h2 class="title">About {{ this.companyInfo.companyName }}</h2>
          <p>{{ this.companyInfo.description }}</p>
        </div>
      </div>
      <!-- / ABOUT -->

      <!-- DIVIDEND INSIGHTS -->
      <div class="columns" v-if="companyInfo.dividends && companyInfo.dividends.length > 0">
        <div class="column">
          <h2 class="title">Dividend Insights</h2>
          <div class="columns">
            <div class="column is-one-third">
              <div class="level is-mobile">
                <div class="level-item has-text-centered">
                  <div class="flex column">
                    <p class="heading"></p>
                    <p class="title is-size-5-mobile"></p>
                    <div class="body">
                      <div class="card events-card">
                        <!-- RETURNED MONEY -->
                        <div class="card-table has-text-left">
                          <div class="content">
                            <table class="table is-fullwidth is-striped">
                              <tbody>
                                <tr v-if="companyInfo.extendedStats">
                                  <td>
                                    <span>Dividend Rate(TTM)</span>
                                  </td>
                                  <td class="level-right">
                                    {{ toLocaleNumber(companyInfo.extendedStats.ttmDividendRate) }}
                                  </td>
                                </tr>
                                <tr v-if="companyInfo.stat">
                                  <td>
                                    <span>Ex Date</span>
                                  </td>
                                  <td class="level-right">
                                    {{ companyInfo.stat.exDividendDate  || "" }}
                                  </td>
                                </tr>
                                <tr v-if="companyInfo.stat">
                                  <td>
                                    <span>Next Dividend Date</span>
                                  </td>
                                  <td class="level-right">
                                    {{ companyInfo.stat.nextDividendDate  || "" }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Payout Ratio</span>
                                  </td>
                                  <td class="level-right">
                                    {{
      toLocaleNumber(
        companyInfo.extendedStats.payoutRatio * 100
      )
    }}%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>5 Year Growth Rate</span>
                                  </td>
                                  <td class="level-right">Not available</td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Dividend Growth</span>
                                  </td>
                                  <td class="level-right">Not available</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
                  </div>
                </div>
              </div>
            </div>
            <dividends-chart :chartData="this.dividendsChart.data" :options="this.dividendsChart.options"
              ref="dividendsChart" class="column is-two-thirds"></dividends-chart>
          </div>
        </div>
      </div>
      <!-- / DIVIDEND INSIGHTS -->

      <!-- POSITION SUMMARY -->
      <div class="columns" v-if="false && companyInfo.symbolActivity && companyInfo.symbolActivity.length > 0">
        <div class="column">
          <h2 class="title">Holding Insights</h2>
          <div class="columns">
            <div class="column is-one-third">
              <div class="level is-mobile">
                <div class="level-item has-text-centered">
                  <div class="flex column">
                    <p class="heading"></p>
                    <p class="title is-size-5-mobile"></p>
                    <div class="body">
                      <div class="card events-card">
                        <div class="card-table has-text-left">
                          <div class="content">
                            <table class="table is-fullwidth is-striped">
                              <tbody>
                                <tr>
                                  <td>
                                    <span>Your first purchase</span>
                                  </td>
                                  <td class="level-right">
                                    {{
      toLocaleDate(new Date(companyInfo.symbolActivity[0].date))
    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Current Size</span>
                                  </td>
                                  <td class="level-right">
                                    {{
        toLocaleNumber(companyInfo.symbolActivity[companyInfo.symbolActivity.length -
          1].quantity)
      }}
                                  </td>
                                </tr>
                                <tr v-if="companyInfo.currentPrice">
                                  <td>
                                    <span>Current Value</span>
                                  </td>
                                  <td class="level-right">
                                    {{ companyInfo.currentPriceCurrency }}
                                    {{
      toLocaleNumber(companyInfo.symbolActivity[companyInfo.symbolActivity.length -
        1].quantity
        * companyInfo.currentPrice)
    }}
                                  </td>
                                </tr>
                                <tr v-if="companyInfo.currentPrice">
                                  <td>
                                    <span>Initially Invested</span>
                                  </td>
                                  <td class="level-right">
                                    {{ companyInfo.currentPriceCurrency }}
                                    {{
      toLocaleNumber(companyInfo.symbolActivity[companyInfo.symbolActivity.length -
        1].quantity
        * companyInfo.currentPrice)
    }}
                                  </td>
                                </tr>

                                <!-- <tr>
                                  <td>
                                    <span>Invested</span>
                                  </td>
                                  <td class="level-right">
                                    {{ toLocaleNumber(companyInfo.stat.ttmEPS) }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Profit</span>
                                  </td>
                                  <td class="level-right">
                                    {{ companyInfo.stat.employees }}
                                  </td>
                                </tr> -->
                                <!-- <tr>
                                  <td>
                                    <span>Dividends Earned (Estimate)</span>
                                  </td>
                                  <td class="level-right">
                                    {{ companyInfo.website }}
                                  </td>
                                </tr> -->
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
                  </div>
                </div>
              </div>
            </div>
            <line-chart :chartData="this.symbolActivityChart.data" :options="this.symbolActivityChart.options"
              ref="symbolActivityChart" class="column is-two-thirds"></line-chart>
          </div>
        </div>
      </div>
      <!-- / POSITION SUMMARY -->

      <!-- POSITION SUMMARY -->
      <div class="columns" v-if="false">
        <div class="column">
          <div class="loader-wrapper columns is-centered is-vcentered is-mobile" style="min-height: 80vh"
            v-if="loadingActivity">
            <div class="loader is-loading"></div>
          </div>
          <table class="table is-fullwidth is-striped" v-if="!loadingActivity">
            <thead>
              <tr>
                <th>Date</th>
                <th class=""></th>
                <th class="level-right">Total</th>
                <th class=""></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity in activities" :key="activity.id">
                <td style="width:15%">
                  <span class="has-text-weight-bold">{{ activity.ticker }}</span>
                  <p class="is-size-7">
                    {{ toLocaleDate(new Date(activity.executionDate)) }}
                  </p>
                </td>
                <td>
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <div class="columns">
                          <div class="column">
                            <div class="has-text-weight-bold">{{ activity.type }}</div>
                            <div class="">{{ activity.quantity }} shares
                              @ {{ activity.priceCurrency }} {{ toLocaleNumber(activity.price) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="level-right">

                  </div> -->
                  </div>
                </td>
                <td>
                  <div class="level-right has-text-weight-bold">
                    {{ activity.priceCurrency }} {{ toLocaleNumber(activity.price * activity.quantity) }}
                  </div>
                </td>
                <td class="is-actions-cell is-hidden-mobile" style="width:15%">
                  <div class="buttons is-right">
                    <button v-if="!activity.isImportActivityGroup" class="button is-small" type="button" @click="
      deleteActivity(
        activity.id,
        activity.isImportActivityGroup
      )
      ">
                      <span class="icon">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </button>
                    <button v-if="activity.isImportActivityGroup" class="button is-small" type="button" @click="
      deleteActivity(
        activity.id,
        activity.isImportActivityGroup
      )
      ">
                      <span class="icon">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                      </span>
                    </button>
                    <!-- CURRENTLY DISABLED -->
                    <button v-if="!activity.isImportActivityGroup && false" class="button is-small" type="button">
                      <span class="icon">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- / POSITION SUMMARY -->

      <!-- SIMILAR COMPANIES -->
      <div class="columns" v-if="this.companyInfo.peers && this.companyInfo.peers.length > 0">
        <div class="column">
          <h2 class="title">Similar Companies</h2>
          <div class="columns">
            <div class="column" v-for="peer in this.companyInfo.peers" :key="peer.symbol">
              <ul>
                <li><router-link :to="{
      name: 'Company',
      params: {
        symbol: peer.symbol,
      },
    }" class="nav-link">{{ peer.companyName }} ({{ peer.symbol }})
                  </router-link></li>
                <li>{{ peer.exchange }}</li>
                <li>CEO: {{ peer.ceo }}</li>
                <li>Employees: {{ toLocaleNumber(peer.employees) }}</li>
                <li>{{ peer.city }}</li>
                <li>{{ peer.country }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- / SIMILAR COMPANIES -->
      <!-- <progress-bar ref="progressBar"></progress-bar> -->
      <!-- <manage-position ref="positionManager" :portfolioId="portfolio.id"></manage-position> -->
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

import { Zune } from "@/services/zune.service";
import DividendsChart from "@/components/zuneCharts/BarChart";
import LineChart from "@/components/zuneCharts/LineChart";
// import ManagePosition from "../components/ManagePosition";
// import ProgressBar from "../components/ProgressBar.vue";

export default {
  name: "Home",
  components: {
    DividendsChart,
    LineChart,
    // ManagePosition,
    // ProgressBar
  },
  methods: {
    manage() {
      this.$refs.progressBar.start();
      this.$refs.positionManager.openModal(this.$route.params.symbol, async () => {
        this.$refs.progressBar.finish()
        await this.getCompanyInfo();
      });
    },
    refreshCharts() {
      this.$refs.priceChart.update();

      if (this.companyInfo.dividends && this.companyInfo.dividends.length > 0) {
        this.$refs.dividendsChart.update();
      }

      if (this.companyInfo.symbolActivity && this.companyInfo.symbolActivity.length > 0) {
        this.$refs.symbolActivityChart.update();
      }
    },
    async period(option) {
      this.selectedPeriod = option;
      await this.getCompanyInfo();
      this.refreshCharts();
    },
    displayDividendsChart() {
      var payments = {
        labels: [],
        amounts: [],
      };

      for (let i = 0; i < this.companyInfo.dividends.length; i++) {
        // get dividend item
        let dividend =
          this.companyInfo.dividends[this.companyInfo.dividends.length - 1 - i];

        // set label & amount
        payments.labels[i] = dividend.paymentDate;
        payments.amounts[i] = dividend.amount;
      }

      this.dividendsChart.data.labels = payments.labels;
      this.dividendsChart.data.datasets[0].data = payments.amounts;
    },
    displayPriceHistoryChart() {
      var priceSeries = {
        labels: [],
        amounts: []
      };

      if (this.companyInfo.priceHistory) {
        for (let i = 0; i < this.companyInfo.priceHistory.length; i++) {
          // get price item
          let price = this.companyInfo.priceHistory[i];

          // set label & amount
          priceSeries.labels[i] = new Date(price.date).toLocaleDateString();
          priceSeries.amounts[i] = price.close;
        }
      }

      this.priceChart.data.labels = priceSeries.labels;
      this.priceChart.data.datasets[0].data = priceSeries.amounts;
      this.priceChart.data.datasets[0].label = `${this.companyInfo.symbol} price`;
    },
    displaySymbolActivityChart() {
      this.symbolActivityChart.data.labels =
        this.companyInfo.symbolActivity.map((it) => it.date);
      this.symbolActivityChart.data.datasets[0].data =
        this.companyInfo.symbolActivity.map((it) => it.quantity);
    },
    async getPortfolio() {
      const portfolioOverview = await Zune.portfolio();
      if (portfolioOverview.data.message !== "NOT_FOUND") {
        this.portfolio = portfolioOverview.data.result;
      } else {
        //this.displayPortfolio = false;
      }
    },
    async getCompanyInfo() {
      const response = await Zune.company(
        this.$route.params.symbol,
        this.selectedPeriod
      );
      this.companyInfo = response.data;
      // this.displayDividendsChart();
      //this.displayPriceHistoryChart();

      //this.displaySymbolActivityChart();
    },
    async fetch() {
      this.ready = false
      await this.getPortfolio();
      await this.getCompanyInfo();
      this.ready = true
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.fetch();
  },
  updated() {
    // this.refreshCharts();
  },
  async mounted() {
    await this.fetch();
  },
  data() {
    return {
      ready: false,
      portfolio: {},
      companyInfo: {
        stat: {},
        extendedStats: {},
      },
      symbolActivityChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Owned Shares",
              backgroundColor: "#f87979",
              fill: false,
              borderColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              // review
              // beginAtZero: true,
              // suggestedMax: max + (max * 0.2)
            },
          },
        },
      },
      priceChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "price",
              backgroundColor: "#f87979",
              fill: false,
              borderColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              // review
              // beginAtZero: true,
              // suggestedMax: max + (max * 0.2)
            },
          },
        },
      },
      dividendsChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Dividend History",
              backgroundColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      },
      // page settings
      selectedPeriod: "12-months",
    };
  },
};
</script>
