<template>
<div>teste</div>
</template>

<script>
// @ is an alias to /src
import { Zune } from '@/services/zune.service'

export default {
  name: 'Home',
  components: {
  },
  async mounted() {
    var version = await Zune.version();

    if(version.status === 200) {
      this.version = version.data;
    }
  },
  data() {
    return {
      version: ''
    }
  }
}
</script>
