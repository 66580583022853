<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('feedback.title.hint') }}
                <span class="icon is-small"> <i class="fa fa-bug"></i> </span>?
              </p>
              <p class="title is-size-5-mobile">{{ $t('feedback.title') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FORM -->
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="field">
          <label class="label">{{ $t('feedback.fields.name') }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Tell us your name..."
              v-model="message.name"
              v-bind:class="{
                'is-danger': errors && !message.name,
              }"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">{{ $t('feedback.fields.email') }}</label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="email"
              :placeholder="$t('feedback.fields.emailHint')"
              v-model="message.email"
              v-bind:class="{
                'is-danger': errors && !message.email,
              }"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
            <span class="icon is-small is-right">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <!-- <p class="help is-danger">This email is invalid</p> -->
        </div>

        <div class="field">
          <label class="label">{{ $t('feedback.fields.subject') }}</label>
          <div class="control">
            <div class="select">
              <select
                v-model="message.subject"
                v-bind:class="{
                  'is-danger': errors && !message.subject,
                }"
              >
                <option selected></option>
                <option value="feature">{{ $t('feedback.fields.featureRequest') }}</option>
                <option value="bug">{{ $t('feedback.fields.reportBug') }}</option>
                <option value="other">{{ $t('feedback.fields.other') }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">{{ $t('feedback.fields.message') }}</label>
          <div class="control">
            <textarea
              class="textarea"
              :placeholder="$t('feedback.fields.messageHint')"
              v-model="message.text"
              v-bind:class="{
                'is-danger': errors && !message.text,
              }"
            ></textarea>
          </div>
        </div>

        <div class="field is-grouped" style="justify-content: end">
          <div class="control">
            <button class="button is-primary" @click="send">{{ $t('feedback.buttons.send') }}</button>
          </div>
        </div>

      </div>
    </div>

    <div class="columns">
      <!-- footer spacing -->
      <div class="column pb-6 mb-6" style="visibility: hidden"></div>
      <!-- / footer spacing-->
    </div>
    <!-- / FORM -->
  </section>
</template>

<script>
// @ is an alias to /src
import { Zune } from "@/services/zune.service";

export default {
  name: "Feedback",
  components: {},
  methods: {
    async send() {
      if (
        !this.message.name ||
        !this.message.text ||
        !this.message.email ||
        !this.message.subject
      ) {
        this.errors = true;
      } else {
        this.errors = false;
        const response = await Zune.feedback(-1, this.message);

        if (response.success) {
          this.message.subject = "";
          this.message.email = "";
          this.message.name = "";
          this.message.text = "";
          this.actionTaken("Nice! We got your message!👍");
        }
      }
    },
  },
  mounted() {},
  data() {
    return {
      errors: false,
      message: {
        subject: "",
        email: "",
        name: "",
        text: "",
      },
    };
  },
};
</script>
