<template>
    <nav class="pagination" role="navigation" aria-label="pagination">
        <a v-if="selectedPage > 0" class="pagination-previous" @click="paginate(selectedPage - 1)">Previous</a>
        <a v-if="selectedPage < totalPages" class="pagination-next" @click="paginate(selectedPage + 1)">Next page</a>
        <ul class="pagination-list">
            <!-- <li>
                <a class="pagination-link" @click="paginate(selectedPage - 1)" v-if="selectedPage > 0">{{ selectedPage
                }}</a>
            </li> -->
            <li v-if="previousPages.indexOf(0) === -1 && selectedPage !== 0">
                <a class="pagination-link" @click="paginate(0)">1</a>
            </li>
            <li v-if="previousPages.indexOf(0) === -1 && selectedPage !== 0">
                <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li :key="page" v-for="page in previousPages">
                <a class="pagination-link" @click="paginate(page)">{{ page + 1 }}</a>
            </li>
            <li>
                <a class="pagination-link" :class="{ 'is-current': true }">{{ selectedPage + 1 }}</a>
            </li>
            <li :key="page" v-for="page in nextPages">
                <a class="pagination-link" @click="paginate(page)">{{ page + 1 }}</a>
            </li>
            <!-- <li>
                <a class="pagination-link" @click="paginate(selectedPage + 1)"
                    v-if="totalPages > (selectedPage + 1) * pageSize">{{ selectedPage + 2
                    }}</a>
            </li>
            <li>
                <a class="pagination-link" @click="paginate(selectedPage + 2)"
                    v-if="totalPages > (selectedPage + 2) * pageSize">{{ selectedPage + 3
                    }}</a>
            </li>
            <li>
                <span class="pagination-ellipsis" v-if="totalPages > (selectedPage + 3) * pageSize">&hellip;</span>
            </li> -->
            <li v-if="nextPages.indexOf(totalPages - 1) === -1 && selectedPage !== totalPages - 1">
                <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li v-if="nextPages.indexOf(totalPages - 1) === -1 && selectedPage !== totalPages - 1">
                <a class="pagination-link" @click="paginate(totalPages - 1)">{{ totalPages }}</a>
            </li><!--
            <li>
              <a class="pagination-link">45</a>
            </li>
            <li>
              <a class="pagination-link is-current">46</a>
            </li>
            <li>
              <a class="pagination-link">47</a>
            </li>
            <li>
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a class="pagination-link">86</a>
            </li> -->
        </ul>
    </nav>
</template>
<script>
export default {
    methods: {
        paginate(page) {
            this.selectedPage = page;
            this.$emit("pageChanged", page);
        }
    },
    computed: {
        previousPages() {
            if (this.selectedPage < 5) {
                return Array.from({ length: this.selectedPage }, (_, index) => index);
            } else {
                return Array.from({ length: 5 }, (_, index) => this.selectedPage - index - 1).reverse();
            }
        },
        nextPages() {
            if (this.selectedPage + 5 <= this.totalPages) {
                return Array.from({ length: 5 }, (_, index) => this.selectedPage + index + 1);
            } else {
                var next = this.totalPages - this.selectedPage - 1
                return Array.from({ length: next }, (_, index) => this.selectedPage + index + 1);
            }
        },
    },
    props: {
        totalItems: Number,
        currentPage: Number,
        pageSize: Number,
    },
    mounted() {
        this.selectedPage = this.currentPage;
        this.totalPages = Math.ceil(this.totalItems / this.pageSize)
        console.log('total: ' + this.totalPages)
    },
    data() {
        return {
            totalPages: 0,
            selectedPage: 0
        };
    },
};
</script>