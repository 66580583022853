<!-- - holdings
- currencies
- asset classes
- countries
- market cap
- industries
- sectors -->
<template>
    <section class="section">
        <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
            <div class="loader is-loading"></div>
        </div>
        <div class="section" v-if="ready">
            <!-- CURRENCY -->
            <div class="tabs">
                <ul>
                    <li class="is-active subtitle">{{ $t('app.titles.currencies') }}</li>
                </ul>
            </div>
            <div class="columns ">
                <simple-doughnut-chart class="column" v-if="this.allocation.currency"
                    :labels="Object.keys(this.allocation.currency)"
                    :values="Object.values(this.allocation.currency)"></simple-doughnut-chart>
                <div class="column">
                    <table class="table is-fullwidth is-striped">
                        <tbody>
                            <tr v-for="key in Object.keys(this.allocation.currency)" :key="key">
                                <td>
                                    <span>{{ key }}</span>
                                </td>
                                <td>
                                    <span class="is-size-7">{{ currency }}</span> <span>{{ toLocaleNumber(allocation.currency[key]) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- / CURRENCY -->

            <!-- SECTOR -->
            <div class="tabs">
                <ul>
                    <li class="is-active subtitle">{{ $t('app.titles.sectors') }}</li>
                </ul>
            </div>
            <div class="columns">
                <simple-doughnut-chart class="column" v-if="this.allocation.sector"
                    :labels="Object.keys(this.allocation.sector)"
                    :values="Object.values(this.allocation.sector)"></simple-doughnut-chart>
                <div class="column">
                    <table class="table is-fullwidth is-striped">
                        <tbody>
                            <tr v-for="key in Object.keys(this.allocation.sector)" :key="key">
                                <td>
                                    <span>{{ key }}</span>
                                </td>
                                <td>
                                    <span class="is-size-7">{{ currency }}</span> <span>{{ toLocaleNumber(allocation.sector[key]) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <!-- / SECTOR -->

            <!-- COUNTRY -->
            <div class="tabs">
                <ul>
                    <li class="is-active subtitle">{{ $t('app.titles.countries') }}</li>
                </ul>
            </div>
            <div class="columns">
                <simple-doughnut-chart class="column" v-if="this.allocation.country"
                    :labels="Object.keys(this.allocation.country)"
                    :values="Object.values(this.allocation.country)"></simple-doughnut-chart>

                <div class="column">
                    <table class="table is-fullwidth is-striped">
                        <tbody>
                            <tr v-for="key in Object.keys(this.allocation.country)" :key="key">
                                <td>
                                    <span>{{ key }}</span>
                                </td>
                                <td>
                                    <span class="is-size-7">{{ currency }}</span> <span>{{ toLocaleNumber(allocation.country[key]) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <!-- / COUNTRY -->

            <!-- TYPE -->
            <div class="tabs">
                <ul>
                    <li class="is-active subtitle">{{ $t('app.titles.assetTypes') }}</li>
                </ul>
            </div>
            <div class="columns">
                <simple-doughnut-chart class="column" v-if="this.allocation.type"
                    :labels="Object.keys(this.allocation.type)"
                    :values="Object.values(this.allocation.type)"></simple-doughnut-chart>

                <div class="column">
                    <table class="table is-fullwidth is-striped">
                        <tbody>
                            <tr v-for="key in Object.keys(this.allocation.type)" :key="key">
                                <td>
                                    <span>{{ key }}</span>
                                </td>
                                <td>
                                    <span class="is-size-7">{{ currency }}</span> <span>{{ toLocaleNumber(allocation.type[key]) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- / TYPE -->
        </div>
    </section>
</template>
    
<script>
// @ is an alias to /src

import { Zune } from "@/services/zune.service";

import SimpleDoughnutChart from "@/components/zuneCharts/SimpleDoughnutChart";

export default {
    name: "Allocation",
    components: {
        SimpleDoughnutChart
    },
    async mounted() {
        this.ready = false
        var allocationResponse = await Zune.portfolioAllocation();
        if (allocationResponse.success) {
            this.currency = allocationResponse.data.result.currency
            this.allocation = allocationResponse.data.result.allocationDetails
        }

        this.ready = true
    },
    data() {
        return {
            ready: false,
            currency: 'BRL',
            allocation: {}
        };
    },
};
</script>
    