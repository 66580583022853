<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Activity</p>
        <button class="delete" aria-label="close" @click="closeModal()"></button>
      </header>
      <section class="modal-card-body">
        <div class="tabs is-centered">
          <ul>
            <li>
              <a class="button is-white" @click="swapModal('stocks')">📈 Stocks</a>
            </li>
            <li class="is-active">
              <a class="button is-white">💵 Cash</a>
            </li>
            <li>
              <a class="button is-white" @click="swapModal('realestate')">🏘️ Real Estate</a>
            </li>
          </ul>
        </div>
        <div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <div class="label">
                      <label class="label">Currency</label>
                      <div class="control">
                        <div class="select">
                          <select v-model="newTransaction.currency">
                            <option value="EUR">
                              EUR
                            </option>
                            <option value="USD">
                              USD
                            </option>
                            <option value="BRL">
                              BRL
                            </option>
                            <option value="CHF">
                              CHF
                            </option>
                            <option value="CNY">
                              CNY
                            </option>
                            <option value="DKK">
                              DKK
                            </option>
                            <option value="INR">
                              INR
                            </option>
                            <option value="NOK">
                              NOK
                            </option>
                            <option value="PLN">
                              PLN
                            </option>
                            <option value="SEK">
                              SEK
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Amount</label>
                    <div class="control">
                      <input class="input" type="number" step="0.01" v-model="newTransaction.amount" v-bind:class="{
                        'is-danger': errors && !newTransaction.amount,
                      }" />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Transaction Date</label>
                    <div class="control">
                      <input class="input" type="date" v-model="newTransaction.executionDate" v-bind:class="{
                        'is-danger': errors && !newTransaction.executionDate,
                      }" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <button class="button is-secondary" @click="closeModal()">Close</button>
        <button class="button is-primary" type="button" v-bind:class="{ 'is-loading': !isReadyBuy }" @click="add()">
          ADD CASH
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";

export default {
  props: {
    backCurrency: String,
    portfolioId: Number
  },
  methods: {
    swapModal(val) {
      this.$emit("swapModal", val);
    },
    actionTaken(msg) {
      this.$emit("actionTaken", true);
      this.$emit("notifyMessage", msg);
    },
    async add() {
      if (
        !this.newTransaction.currency ||
        !this.newTransaction.amount ||
        !this.newTransaction.executionDate
      ) {
        this.errors = true;
      } else {
        this.errors = false;
        this.isReadyBuy = false;
        const response = await Zune.updateCash(
          this.portfolioId,
          {
            ticker: 'CASH',
            exchange: 'ZUNE',
            priceCurrency: this.newTransaction.currency,
            price: this.newTransaction.amount,
            quantity: 1,
            executionDate: this.newTransaction.executionDate,
            type: 'CASH'
          }
        );
        this.isReadyBuy = true;

        if (response.success) {
          this.actionTaken(`Added ${this.newTransaction.currency} ${this.newTransaction.amount}`);
        }
      }
    },
    closeModal() {
      this.swapModal('');
    },
  },
  data() {
    return {
      isReady: true,
      isReadyBuy: true,
      errors: false,
      isManualInput: true,
      newTransaction: {
        currency: this.backCurrency,
      }
    };
  },
};
</script>