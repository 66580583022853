export const COUNTRIES = [
            {
              "code": "US",
              "name": "United States"
            },
            {
              "code": "NL",
              "name": "Netherlands"
            },
            {
              "code": "AE",
              "name": "United Arab Emirates"
            },
            {
              "code": "AM",
              "name": "Armenia"
            },
            {
              "code": "AR",
              "name": "Argentina"
            },
            {
              "code": "AU",
              "name": "Australia"
            },
            {
              "code": "AZ",
              "name": "Azerbaijan"
            },
            {
              "code": "BA",
              "name": "Bosnia and Herzegovina"
            },
            {
              "code": "BB",
              "name": "Barbados"
            },
            {
              "code": "BD",
              "name": "Bangladesh"
            },
            {
              "code": "BE",
              "name": "Belgium"
            },
            {
              "code": "BG",
              "name": "Bulgaria"
            },
            {
              "code": "BH",
              "name": "Bahrain"
            },
            {
              "code": "BM",
              "name": "Bermuda"
            },
            {
              "code": "BO",
              "name": "Bolivia"
            },
            {
              "code": "BR",
              "name": "Brazil"
            },
            {
              "code": "BS",
              "name": "Bahamas"
            },
            {
              "code": "BW",
              "name": "Botswana"
            },
            {
              "code": "BY",
              "name": "Belarus"
            },
            {
              "code": "CA",
              "name": "Canada"
            },
            {
              "code": "CH",
              "name": "Switzerland"
            },
            {
              "code": "CI",
              "name": "Côte d'Ivoire"
            },
            {
              "code": "CL",
              "name": "Chile"
            },
            {
              "code": "CN",
              "name": "China"
            },
            {
              "code": "CO",
              "name": "Colombia"
            },
            {
              "code": "CR",
              "name": "Costa Rica"
            },
            {
              "code": "CV",
              "name": "Cape Verde"
            },
            {
              "code": "CY",
              "name": "Cyprus"
            },
            {
              "code": "CZ",
              "name": "Czech Republic"
            },
            {
              "code": "DE",
              "name": "Germany"
            },
            {
              "code": "DK",
              "name": "Denmark"
            },
            {
              "code": "DZ",
              "name": "Algeria"
            },
            {
              "code": "EC",
              "name": "Ecuador"
            },
            {
              "code": "EE",
              "name": "Estonia"
            },
            {
              "code": "EG",
              "name": "Egypt"
            },
            {
              "code": "ES",
              "name": "Spain"
            },
            {
              "code": "FI",
              "name": "Finland"
            },
            {
              "code": "FJ",
              "name": "Fiji"
            },
            {
              "code": "FR",
              "name": "France"
            },
            {
              "code": "GB",
              "name": "United Kingdom"
            },
            {
              "code": "GE",
              "name": "Georgia"
            },
            {
              "code": "GG",
              "name": "Guernsey"
            },
            {
              "code": "GH",
              "name": "Ghana"
            },
            {
              "code": "GR",
              "name": "Greece"
            },
            {
              "code": "GT",
              "name": "Guatemala"
            },
            {
              "code": "HK",
              "name": "Hong Kong"
            },
            {
              "code": "HR",
              "name": "Croatia"
            },
            {
              "code": "HU",
              "name": "Hungary"
            },
            {
              "code": "ID",
              "name": "Indonesia"
            },
            {
              "code": "IE",
              "name": "Ireland"
            },
            {
              "code": "IL",
              "name": "Israel"
            },
            {
              "code": "IN",
              "name": "India"
            },
            {
              "code": "IQ",
              "name": "Iraq"
            },
            {
              "code": "IR",
              "name": "Iran"
            },
            {
              "code": "IS",
              "name": "Iceland"
            },
            {
              "code": "IT",
              "name": "Italy"
            },
            {
              "code": "JM",
              "name": "Jamaica"
            },
            {
              "code": "JO",
              "name": "Jordan"
            },
            {
              "code": "JP",
              "name": "Japan"
            },
            {
              "code": "KE",
              "name": "Kenya"
            },
            {
              "code": "KG",
              "name": "Kyrgyzstan"
            },
            {
              "code": "KH",
              "name": "Cambodia"
            },
            {
              "code": "KR",
              "name": "South Korea"
            },
            {
              "code": "KW",
              "name": "Kuwait"
            },
            {
              "code": "KY",
              "name": "Cayman Islands"
            },
            {
              "code": "KZ",
              "name": "Kazakhstan"
            },
            {
              "code": "LA",
              "name": "Laos"
            },
            {
              "code": "LB",
              "name": "Lebanon"
            },
            {
              "code": "LK",
              "name": "Sri Lanka"
            },
            {
              "code": "LT",
              "name": "Lithuania"
            },
            {
              "code": "LU",
              "name": "Luxembourg"
            },
            {
              "code": "LV",
              "name": "Latvia"
            },
            {
              "code": "LY",
              "name": "Libya"
            },
            {
              "code": "MA",
              "name": "Morocco"
            },
            {
              "code": "MD",
              "name": "Moldova"
            },
            {
              "code": "ME",
              "name": "Montenegro"
            },
            {
              "code": "MK",
              "name": "North Macedonia"
            },
            {
              "code": "MN",
              "name": "Mongolia"
            },
            {
              "code": "MT",
              "name": "Malta"
            },
            {
              "code": "MU",
              "name": "Mauritius"
            },
            {
              "code": "MW",
              "name": "Malawi"
            },
            {
              "code": "MX",
              "name": "Mexico"
            },
            {
              "code": "NA",
              "name": "Namibia"
            },
            {
              "code": "NG",
              "name": "Nigeria"
            },
            {
              "code": "NO",
              "name": "Norway"
            },
            {
              "code": "NP",
              "name": "Nepal"
            },
            {
              "code": "OM",
              "name": "Oman"
            },
            {
              "code": "PA",
              "name": "Panama"
            },
            {
              "code": "PE",
              "name": "Peru"
            },
            {
              "code": "PK",
              "name": "Pakistan"
            },
            {
              "code": "PL",
              "name": "Poland"
            },
            {
              "code": "PS",
              "name": "Palestine"
            },
            {
              "code": "PT",
              "name": "Portugal"
            },
            {
              "code": "PY",
              "name": "Paraguay"
            },
            {
              "code": "QA",
              "name": "Qatar"
            },
            {
              "code": "RS",
              "name": "Serbia"
            },
            {
              "code": "RU",
              "name": "Russia"
            },
            {
              "code": "RW",
              "name": "Rwanda"
            },
            {
              "code": "SA",
              "name": "Saudi Arabia"
            },
            {
              "code": "SC",
              "name": "Seychelles"
            },
            {
              "code": "SE",
              "name": "Sweden"
            },
            {
              "code": "SI",
              "name": "Slovenia"
            },
            {
              "code": "SK",
              "name": "Slovakia"
            },
            {
              "code": "SV",
              "name": "El Salvador"
            },
            {
              "code": "SY",
              "name": "Syria"
            },
            {
              "code": "SZ",
              "name": "Eswatini"
            },
            {
              "code": "TN",
              "name": "Tunisia"
            },
            {
              "code": "TR",
              "name": "Turkey"
            },
            {
              "code": "TT",
              "name": "Trinidad and Tobago"
            },
            {
              "code": "TW",
              "name": "Taiwan"
            },
            {
              "code": "TZ",
              "name": "Tanzania"
            },
            {
              "code": "UA",
              "name": "Ukraine"
            },
            {
              "code": "UG",
              "name": "Uganda"
            },
            {
              "code": "UY",
              "name": "Uruguay"
            },
            {
              "code": "UZ",
              "name": "Uzbekistan"
            },
            {
              "code": "VE",
              "name": "Venezuela"
            },
            {
              "code": "VN",
              "name": "Vietnam"
            },
            {
              "code": "ZA",
              "name": "South Africa"
            },
            {
              "code": "ZM",
              "name": "Zambia"
            },
            {
              "code": "ZW",
              "name": "Zimbabwe"
            }
          ]
