var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('feedback.title.hint'))+" "),_vm._m(0),_vm._v("? ")]),_c('p',{staticClass:"title is-size-5-mobile"},[_vm._v(_vm._s(_vm.$t('feedback.title')))])])])])])]),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('feedback.fields.name')))]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.name),expression:"message.name"}],staticClass:"input",class:{
              'is-danger': _vm.errors && !_vm.message.name,
            },attrs:{"type":"text","placeholder":"Tell us your name..."},domProps:{"value":(_vm.message.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "name", $event.target.value)}}})])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('feedback.fields.email')))]),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.email),expression:"message.email"}],staticClass:"input",class:{
              'is-danger': _vm.errors && !_vm.message.email,
            },attrs:{"type":"email","placeholder":_vm.$t('feedback.fields.emailHint')},domProps:{"value":(_vm.message.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "email", $event.target.value)}}}),_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('feedback.fields.subject')))]),_c('div',{staticClass:"control"},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.subject),expression:"message.subject"}],class:{
                'is-danger': _vm.errors && !_vm.message.subject,
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.message, "subject", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":""}}),_c('option',{attrs:{"value":"feature"}},[_vm._v(_vm._s(_vm.$t('feedback.fields.featureRequest')))]),_c('option',{attrs:{"value":"bug"}},[_vm._v(_vm._s(_vm.$t('feedback.fields.reportBug')))]),_c('option',{attrs:{"value":"other"}},[_vm._v(_vm._s(_vm.$t('feedback.fields.other')))])])])])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('feedback.fields.message')))]),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.text),expression:"message.text"}],staticClass:"textarea",class:{
              'is-danger': _vm.errors && !_vm.message.text,
            },attrs:{"placeholder":_vm.$t('feedback.fields.messageHint')},domProps:{"value":(_vm.message.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "text", $event.target.value)}}})])]),_c('div',{staticClass:"field is-grouped",staticStyle:{"justify-content":"end"}},[_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-primary",on:{"click":_vm.send}},[_vm._v(_vm._s(_vm.$t('feedback.buttons.send')))])])])])]),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-bug"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-envelope"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small is-right"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column pb-6 mb-6",staticStyle:{"visibility":"hidden"}})])}]

export { render, staticRenderFns }