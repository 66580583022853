<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://zune.money">
              <strong>ZuneMoney</strong><span class="is-size-7">Beta</span></a>
            <!-- <div class="navbar-burger burger"><span></span><span></span><span></span></div> -->
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
              data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
              <a class="navbar-item is-hidden">
                Community
              </a>
              <a class="navbar-item is-hidden">
                Blog
              </a>
              <a class="navbar-item" href="https://zune.money/#dividend-tracker">
                Dividend Tracking
              </a>
              <a class="navbar-item" href="https://zune.money/#portfolio-performance">
                Performance Comparision
              </a>
              <a class="navbar-item" href="https://app.zune.money/demo">
                Demo
              </a>
              <div class="navbar-item has-dropdown is-hoverable is-hidden">
                <a class="navbar-link">
                  Features
                </a>
                <div class="navbar-dropdown">
                  <a class="navbar-item">
                    Dividend Tracking
                  </a>
                  <a class="navbar-item">
                    Performance Comparision
                  </a>
                  <!-- <a class="navbar-item is-hidden">
                                    Dividend Alerts
                                </a> -->
                  <hr class="navbar-divider">
                  <a class="navbar-item">
                    Demo
                  </a>
                </div>
              </div>
            </div>
            <div class="navbar-end">
              <div class="navbar-item">
                <div class="buttons">
                  <a class="button is-primary" href="https://app.zune.money/signup">
                    <strong>Sign up</strong>
                  </a>
                  <a class="button is-light" href="https://app.zune.money">
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <section class="hero bd-hero is-warning mt-3">
          <div class="hero-body">
            <div class="bd-hero-body">
              <div class="bd-hero-heading">
                <h2 class="heading is-size-5">Start Tracking {{ companyInfo.symbol }} Stock</h2>
                <p>Unlock invaluable insights into {{ companyInfo.companyName }}'s performance with ZuneMoney's portfolio
                  tracking tools. Our platform seamlessly integrates precision tracking and in-depth analytics to offer
                  you a comprehensive view of your {{ companyInfo.companyName }} stock shares. Stay fully informed, spot
                  emerging trends, and make well-informed, strategic decisions with confidence, all supported by
                  ZuneMoney's stock tracking data.</p>
              </div>
            </div>
          </div>


          <!-- Hero footer: will stick at the bottom -->
          <div class="hero-foot">
            <nav class="tabs is-boxed is-pulled-right">
              <div class="container">
                <ul>
                  <li> <a href="https://app.zune.money/signup" class="nav-link"><button
                        class="button is-success has-text-weight-bold">Try
                        Now!🚀</button></a></li>
                </ul>
              </div>
            </nav>
          </div>
        </section>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ companyInfo.exchange }}</p>
              <h1 class="title is-size-5-mobile">
                {{ companyInfo.symbol }} - {{ companyInfo.companyName }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <h2 class="heading">Symbol</h2>
              <p class="title is-size-5-mobile">
                {{ companyInfo.symbol }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile">
            <div>
              <h2 class="heading">Price</h2>
              <p class="title is-size-5-mobile">
                <span class="is-size-6">{{ companyInfo.currentPriceCurrency }}</span>
                <span class="is-white">{{ toLocaleNumber(companyInfo.currentPrice) }}</span>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered" v-if="companyInfo.stat">
            <div>
              <h2 class="heading">PE</h2>
              <p class="title is-size-5-mobile">
                {{
                  companyInfo.stat.peRatio
                  ? toLocaleNumber(companyInfo.stat.peRatio)
                  : "-"
                }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile" v-if="companyInfo.stat">
            <div>
              <!-- We only calculate the dividends of your current holdings -->
              <h2 class="heading">Div. Yield(%)</h2>
              <p class="title is-size-5-mobile">
                {{
                  companyInfo.stat.dividendYield
                  ? toLocaleNumber(companyInfo.stat.dividendYield * 100) + "%"
                  : "-"
                }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile" v-if="companyInfo.extendedStats">
            <div>
              <!-- We only calculate the dividends of your current holdings -->
              <h2 class="heading">Payout Ratio(%)</h2>
              <p class="title is-size-5-mobile">
                {{
                  companyInfo.extendedStats.payoutRatio
                  ? toLocaleNumber(
                    companyInfo.extendedStats.payoutRatio * 100
                  ) + "%"
                  : "-"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-hidden-tablet">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <h2 class="heading">Price</h2>
              <p class="title is-size-5-mobile">
                <span class="is-white">{{
                  toLocaleNumber(companyInfo.currentPrice)
                }}</span>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered" v-if="companyInfo.stat">
            <div>
              <!-- We only calculate the dividends of your current holdings -->
              <h2 class="heading">Div. Yield(%)</h2>
              <p class="title is-size-5-mobile">
                {{
                  companyInfo.stat.dividendYield
                  ? toLocaleNumber(companyInfo.stat.dividendYield * 100) + "%"
                  : "-"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPANY SUMMARY -->
    <div class="columns">
      <div class="column is-one-third has-text-centered">
        <img v-if="companyInfo.website" :src="'https://logo.clearbit.com/' + companyInfo.website"
          :alt="companyInfo.companyName">
        <div class="level is-mobile" v-if="companyInfo.stat">
          <div class="level-item has-text-centered">
            <div class="flex column">
              <p class="heading"></p>
              <p class="title is-size-5-mobile"></p>
              <div class="body">
                <div class="card events-card">
                  <header class="card-header">
                    <p class="card-header-title">Summary</p>
                  </header>
                  <div class="card-table has-text-left" v-if="companyInfo.stat != null">
                    <div class="content">
                      <table class="table is-fullwidth is-striped">
                        <tbody>
                          <tr>
                            <td>
                              <span>52 Weeks Range</span>
                            </td>
                            <td class="level-right">
                              {{
                                toLocaleNumber(companyInfo.stat.week52low)
                              }}-{{
  toLocaleNumber(companyInfo.stat.week52high)
}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>PE (TTM)</span>
                            </td>
                            <td class="level-right">
                              {{
                                companyInfo.stat.peRatio
                                ? toLocaleNumber(companyInfo.stat.peRatio)
                                : "-"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>EPS (TTM)</span>
                            </td>
                            <td class="level-right">
                              {{
                                companyInfo.stat.ttmEPS
                                ? toLocaleNumber(companyInfo.stat.ttmEPS)
                                : "-"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Employees</span>
                            </td>
                            <td class="level-right">
                              {{ companyInfo.stat.employees || "-" }}
                            </td>
                          </tr>
                          <tr v-if="companyInfo.website">
                            <td>
                              <span>Website</span>
                            </td>
                            <td class="level-right">
                              <a :href="companyInfo.website" target="_blank">{{ companyInfo.website }}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right is-pulled-right">
            <div class="buttons">
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'week' }"
                @click="period('week')">
                WEEK
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'month' }"
                @click="period('month')">
                MONTH
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '12-months' }"
                @click="period('12-months')">
                12-MONTHS
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '5-years' }"
                @click="period('5-years')">
                5-YEARS
              </button>
            </div>
          </div>
        </div>
        <!-- <line-chart v-if="false" :chartData="this.priceChart.data" :options="this.priceChart.options" ref="priceChart"></line-chart> -->
      </div>
    </div>
    <!-- / COMPANY SUMMARY -->

    <!-- ABOUT -->
    <div class="columns" v-if="this.companyInfo.description">
      <div class="column">
        <h2 class="title">About {{ this.companyInfo.companyName }}</h2>
        <p>{{ this.companyInfo.description }}</p>
      </div>
    </div>
    <!-- / ABOUT -->

    <!-- DIVIDEND INSIGHTS -->

    <div class="columns" v-if="companyInfo.dividends && companyInfo.dividends.length > 0">
      <div class="column">
        <h2 class="title">{{ companyInfo.symbol }} - Dividend History</h2>
        <div class="columns" v-if="companyInfo.dividends && companyInfo.dividends.length > 0">
          <dividends-chart :chartData="this.dividendsChart.data" :options="this.dividendsChart.options"
            ref="dividendsChart" class="column"></dividends-chart>
        </div>

        <h3 class="title">{{ companyInfo.symbol }} - Dividend History Payments</h3>
        <table class="
            table
            is-fullwidth is-striped is-hoverable is-fullwidth
          ">
          <thead>
            <tr class="is-hidden-mobile">
              <th>
                Declared
              </th>
              <th>
                Record
              </th>
              <th>
                Payable
              </th>
              <th>
                Amount
              </th>
              <th>
                Frequency
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dividend in companyInfo.dividends" :key="dividend.subkey">
              <td>
                <span v-if="dividend.declaredDate">{{ dividend.declaredDate }}</span>
                <span v-if="!dividend.declaredDate">-</span>
              </td>
              <td>
                <span v-if="dividend.recordDate">{{ dividend.recordDate }}</span>
                <span v-if="!dividend.recordDate">-</span>
              </td>
              <td>
                {{ dividend.paymentDate }}
              </td>
              <td>
                {{ dividend.currency }}
                {{ toLocaleNumber(dividend.amount) }}
              </td>
              <td>
                <span v-if="dividend.frequency">{{ dividend.frequency }}</span>
                <span v-if="!dividend.frequency">-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="columns" v-if="false && companyInfo.dividends && companyInfo.dividends.length > 0">
      <div class="column">
        <h2 class="title">{{ companyInfo.symbol }} - Dividend Insights</h2>
        <div class="card events-card">
          <div class="card-table has-text-left">
            <div class="content">
              <table class="table is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>
                      <span>Ex Date</span>
                    </td>
                    <td class="level-right">
                      {{ companyInfo.stat.exDividendDate }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Next Dividend Date</span>
                    </td>
                    <td class="level-right">
                      {{ companyInfo.stat.nextDividendDate || "N/A" }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Payout Ratio</span>
                    </td>
                    <td class="level-right">
                      {{
                        toLocaleNumber(
                          companyInfo.extendedStats.payoutRatio * 100
                        )
                      }}%
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>
                      <span>5 Year Growth Rate</span>
                    </td>
                    <td class="level-right"></td>
                  </tr>
                  <tr>
                    <td>
                      <span>Dividend Growth</span>
                    </td>
                    <td class="level-right"></td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / DIVIDEND INSIGHTS -->

    <stock-faq v-if="companyInfo.faq" :faq="companyInfo.faq"></stock-faq>

    <!-- <div class="columns" v-if="this.companyInfo.faq">

      <div class="column" v-for="faq in this.companyInfo.faq" :key="faq.id">
        <h2>{{ faq.question }}</h2>
        <p>{{ faq.answer }}</p>
      </div>
    </div> -->

    <!-- SIMILAR COMPANIES -->
    <div class="columns" v-if="this.companyInfo.peers && this.companyInfo.peers.length > 0">
      <div class="column">
        <h2 class="title">{{ companyInfo.symbol }} - Similar Companies</h2>
        <div class="columns">
          <div class="column" v-for="peer in this.companyInfo.peers" :key="peer.symbol">
            <ul>
              <li><router-link :to="{
                name: 'Stock',
                params: {
                  symbol: peer.symbol,
                },
              }" class="nav-link">{{ peer.companyName }} ({{ peer.symbol }})
                </router-link></li>
              <li>{{ peer.exchange }}</li>
              <li>CEO: {{ peer.ceo }}</li>
              <li>Employees: {{ toLocaleNumber(peer.employees) }}</li>
              <li>{{ peer.city }}</li>
              <li>{{ peer.country }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- / SIMILAR COMPANIES -->

    <!-- POSITION SUMMARY -->
    <div class="columns" v-if="false && companyInfo.symbolActivity && companyInfo.symbolActivity.length > 0">
      <div class="column is-one-third">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div class="flex column">
              <p class="heading"></p>
              <p class="title is-size-5-mobile"></p>
              <div class="body">
                <div class="card events-card">
                  <header class="card-header">
                    <p class="card-header-title">Holding Insights</p>
                  </header>
                  <div class="card-table has-text-left">
                    <div class="content">
                      <table class="table is-fullwidth is-striped">
                        <tbody>
                          <tr>
                            <td>
                              <span>Size</span>
                            </td>
                            <td class="level-right">
                              {{
                                toLocaleNumber(
                                  companyInfo.symbolActivity[
                                    companyInfo.symbolActivity.length - 1
                                  ].quantity
                                )
                              }}
                            </td>
                          </tr>
                          <tr v-if="companyInfo.currentPrice">
                            <td>
                              <span>Value</span>
                            </td>
                            <td class="level-right">
                              {{
                                toLocaleNumber(
                                  companyInfo.symbolActivity[
                                    companyInfo.symbolActivity.length - 1
                                  ].quantity * companyInfo.currentPrice
                                )
                              }}
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>
                              <span>Invested</span>
                            </td>
                            <td class="level-right">
                              {{ toLocaleNumber(companyInfo.stat.ttmEPS) }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Profit</span>
                            </td>
                            <td class="level-right">
                              {{ companyInfo.stat.employees }}
                            </td>
                          </tr> -->
                          <!-- <tr>
                            <td>
                              <span>Dividends Earned (Estimate)</span>
                            </td>
                            <td class="level-right">
                              {{ companyInfo.website }}
                            </td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <line-chart v-if="false" :chartData="this.symbolActivityChart.data" :options="this.symbolActivityChart.options"
        ref="symbolActivityChart" class="column is-two-thirds"></line-chart>
    </div>
    <!-- / POSITION SUMMARY -->

    <section class="hero bd-hero is-info mt-3">
      <div class="hero-body">
        <div class="bd-hero-body">
          <div class="bd-hero-heading">
            <h2 class="heading is-size-5">Ready to Take Control of Your Investments?</h2>
            <p>Join ZuneMoney today to explore more stocks, track your portfolio, and make data-driven investment
              decisions. We're here to empower your financial success. Don't miss out – start your journey now!</p>
          </div>
        </div>
      </div>
      <div class="hero-foot mb-4">
        <nav class="tabs is-boxed is-pulled-right">
          <div class="container">
            <ul>
              <li> <a href="https://app.zune.money/signup" class="nav-link"><button
                    class="button is-success has-text-weight-bold">Try
                    Now!🚀</button></a></li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
    <!-- <h5 class="title">Disclaimers</h2> -->
    <div class="columns">
      <div class="column">
        <small>ZuneMoney and its administrators do not assume any responsibility for the accuracy and completeness of the
          information provided.</small>
      </div>
    </div>
    <div class="columns" v-if="companyInfo.website">
      <div class="column">
        <a href="https://clearbit.com">Logos provided by Clearbit</a>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

import { Zune } from "@/services/zune.service";
import DividendsChart from "@/components/zuneCharts/BarChart";
import LineChart from "@/components/zuneCharts/LineChart";
import StockFaq from "@/components/StockFaq";

export default {
  name: "Home",
  metaInfo() {
    let pageTitle = ''
    if (this.companyInfo.dividends && this.companyInfo.dividends.length > 0) {
      pageTitle = `${this.companyInfo.symbol}: ${this.companyInfo.companyName} Dividend Date & History`
    } else {
      pageTitle = `${this.companyInfo.symbol}: ${this.companyInfo.companyName} Stock Price & Overview`
    }
    
    return {
      title: pageTitle,
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          name: 'description',
          content: `${this.companyInfo.companyName} (${this.companyInfo.symbol}) stock quote, historical dividend yield and charts to track your portfolio and stock trades.`
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: `${this.companyInfo.symbol}, ${this.companyInfo.symbol} chart, ${this.companyInfo.symbol} price chart, ${this.companyInfo.symbol} dividends, ${this.companyInfo.companyName} dividends, ${this.companyInfo.companyName} chart, ${this.companyInfo.symbol} analyst estimates, ${this.companyInfo.symbol} earnings estimates, ${this.companyInfo.symbol} share estimates, ${this.companyInfo.symbol} analyst recommendations, ${this.companyInfo.symbol} ratings, ${this.companyInfo.companyName} analyst estimates, ${this.companyInfo.companyName} earnings estimates, ${this.companyInfo.companyName} analyst recommendations, ${this.companyInfo.companyName} analyst ratings.`
        }
      ]
    }
  },
  components: {
    DividendsChart,
    LineChart,
    StockFaq
  },
  methods: {
    refreshCharts() {
      this.$refs.priceChart.update();

      if (this.companyInfo.dividends && this.companyInfo.dividends.length > 0) {
        this.$refs.dividendsChart.update();
      }

      if (
        this.companyInfo.symbolActivity &&
        this.companyInfo.symbolActivity.length > 0
      ) {
        this.$refs.symbolActivityChart.update();
      }
    },
    async period(option) {
      this.selectedPeriod = option;
      await this.getCompanyInfo();
      this.refreshCharts();
    },
    displayDividendsChart() {
      var payments = {
        labels: [],
        amounts: [],
      };

      for (let i = 0; i < this.companyInfo.dividends.length; i++) {
        // get dividend item
        let dividend =
          this.companyInfo.dividends[this.companyInfo.dividends.length - 1 - i];

        // set label & amount
        payments.labels[i] = dividend.paymentDate;
        payments.amounts[i] = dividend.amount;
      }

      this.dividendsChart.data.labels = payments.labels;
      this.dividendsChart.data.datasets[0].data = payments.amounts;
    },
    displayPriceHistoryChart() {
      var priceSeries = {
        labels: [],
        amounts: [],
      };

      if (this.companyInfo.priceHistory) {
        for (let i = 0; i < this.companyInfo.priceHistory.length; i++) {
          // get price item
          let price = this.companyInfo.priceHistory[i];

          // set label & amount
          priceSeries.labels[i] = new Date(price.date).toLocaleDateString();
          priceSeries.amounts[i] = price.close;
        }
      }

      this.priceChart.data.labels = priceSeries.labels;
      this.priceChart.data.datasets[0].data = priceSeries.amounts;
    },
    async getCompanyInfo() {
      const response = await Zune.publicCompany(
        this.$route.params.symbol,
        this.selectedPeriod
      );
      this.companyInfo = response.data;
      // this.displayDividendsChart();
      // this.displayPriceHistoryChart();

      // document.title = `${this.companyInfo.symbol}: ${this.companyInfo.companyName}. Dividend Tracking and Stock Price`;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.getCompanyInfo();
  },
  updated() {
    //this.refreshCharts();
  },
  async mounted() {
    await this.getCompanyInfo();
  },
  data() {
    return {
      companyInfo: {
        stat: {},
        extendedStats: {},
        faq: {},
      },
      symbolActivityChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Owned Shares",
              backgroundColor: "#f87979",
              fill: false,
              borderColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              // review
              // beginAtZero: true,
              // suggestedMax: max + (max * 0.2)
            },
          },
        },
      },
      priceChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Price History",
              backgroundColor: "#f87979",
              fill: false,
              borderColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              // review
              // beginAtZero: true,
              // suggestedMax: max + (max * 0.2)
            },
          },
        },
      },
      dividendsChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Dividend History",
              backgroundColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      },
      // page settings
      selectedPeriod: "month",
    };
  },
};
</script>
