<template>
  <section class="section">
    <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
      <div class="loader is-loading m-5"></div>
      <p>Almost there ... 🚀</p>
    </div>
    <div v-if="ready">
      <div class="columns">
        <div class="column">
          <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
              <a class="navbar-item" href="https://zune.money">
                <strong>ZuneMoney</strong><span class="is-size-7">Beta</span></a>
              <!-- <div class="navbar-burger burger"><span></span><span></span><span></span></div> -->
              <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
                data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div id="navbarBasicExample" class="navbar-menu">
              <div class="navbar-start">
                <a class="navbar-item is-hidden">
                  Community
                </a>
                <a class="navbar-item is-hidden">
                  Blog
                </a>
                <a class="navbar-item" href="https://zune.money/#dividend-tracker">
                  Dividend Tracking
                </a>
                <a class="navbar-item" href="https://zune.money/#portfolio-performance">
                  Performance Comparision
                </a>
                <a class="navbar-item" href="https://app.zune.money/demo">
                  Demo
                </a>
                <div class="navbar-item has-dropdown is-hoverable is-hidden">
                  <a class="navbar-link">
                    Features
                  </a>
                  <div class="navbar-dropdown">
                    <a class="navbar-item">
                      Dividend Tracking
                    </a>
                    <a class="navbar-item">
                      Performance Comparision
                    </a>
                    <!-- <a class="navbar-item is-hidden">
                                    Dividend Alerts
                                </a> -->
                    <hr class="navbar-divider">
                    <a class="navbar-item">
                      Demo
                    </a>
                  </div>
                </div>
              </div>
              <div class="navbar-end">
                <div class="navbar-item">
                  <div class="buttons">
                    <a class="button is-primary" href="https://app.zune.money/signup">
                      <strong>Sign up</strong>
                    </a>
                    <a class="button is-light" href="https://app.zune.money">
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <section class="hero bd-hero bd-is-basic mt-3">
            <div class="hero-body">
              <div class="bd-hero-body">
                <div class="bd-hero-heading">
                  <h1 class="title algolia-lvl0">
                    My Dividend Portfolio
                  </h1>
                  <div class="subtitle is-4 algolia-lvl1">
                    <p>
                      A portfolio focused on growth and dividend returns.<span>
                        Signup to start
                        <a href="https://app.zune.money/signup">Tracking Your Portfolio🚀 with Zune Money.</a></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- SUMMARY -->
      <div class="columns">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Portfolio Value</p>
                <p class="title is-size-5-mobile">
                  <span class="is-size-5">{{ this.portfolio.currency }}</span>
                  {{ toLocaleNumber(this.portfolioActualValue) }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered is-hidden-mobile">
              <div>
                <p class="heading">Gains</p>
                <p class="title is-size-5-mobile">
                  <span :class="{
                    'has-text-success-dark':
                      this.portfolioActualValue - this.portfolioOriginalValue >
                      0,
                    'has-text-danger-dark':
                      this.portfolioActualValue - this.portfolioOriginalValue <
                      0,
                  }">
                    {{ this.portfolio.currency }}
                    {{
                      toLocaleNumber(
                        this.portfolioActualValue - this.portfolioOriginalValue
                      )
                    }}</span>
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Return</p>
                <p class="title is-size-5-mobile" :class="{
                      'has-text-success-dark': this.portfolioAggregatedReturn > 0,
                      'has-text-danger-dark': this.portfolioAggregatedReturn < 0,
                    }">
                  <span v-if="this.portfolioAggregatedReturn > 0">+{{
                    parseFloat(this.portfolioAggregatedReturn).toFixed(2)
                  }}%</span>
                  <span v-if="this.portfolioAggregatedReturn < 0">{{
                    parseFloat(this.portfolioAggregatedReturn).toFixed(2)
                  }}%</span>
                  <span v-if="this.portfolioAggregatedReturn == 0">-</span>
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered is-hidden-mobile">
              <div>
                <!-- We only calculate the dividends of your current holdings -->
                <p class="heading">Dividends (12-month)</p>
                <p class="title is-size-5-mobile">
                  {{ this.portfolio.currency }}
                  <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / SUMMARY END -->

      <!-- PORTFOLIO VIEW -->
      <div id="portfolio" v-if="displayPortfolio">
        <!-- PERIOD CHARTS -->
        <!-- <div class="level">
          <div class="level-left"></div>
          <div class="level-right is-hidden-mobile">
            <div class="buttons">
              <button
                type="button"
                class="button is-white"
                :class="{ 'is-light': selectedPeriod == 'week' }"
                @click="period('week')"
              >
                WEEK
              </button>
              <button
                type="button"
                class="button is-white"
                :class="{ 'is-light': selectedPeriod == 'month' }"
                @click="period('month')"
              >
                MONTH
              </button>
              <button
                type="button"
                class="button is-white"
                :class="{ 'is-light': selectedPeriod == '12-months' }"
                @click="period('12-months')"
              >
                12-MONTHS
              </button>
              <button
                type="button"
                class="button is-white"
                :class="{ 'is-light': selectedPeriod == '5-years' }"
                @click="period('5-years')"
              >
                5-YEARS
              </button>
            </div>
          </div>
        </div> -->
        <div class="columns">
          <doughnut-chart :totalValue="this.portfolioActualValue"
            :invested="this.portfolioOriginalValue - this.cashTotal - this.realEstateTotal" :cash="this.cashTotal"
            :realEstate="this.realEstateTotal" :gains="this.portfolioActualValue - this.portfolioOriginalValue"
            :dividends="this.dividendAmount" v-if="this.portfolioActualValue"
            class="column is-one-third"></doughnut-chart>
          <div class="column is-hidden-tablet">
            <div class="level is-mobile">
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Gains</p>
                  <p class="title is-size-5-mobile">
                    <span class="is-white">
                      {{ this.portfolio.currency }}
                      {{
                        toLocaleNumber(
                          this.portfolioActualValue - this.portfolioOriginalValue
                        )
                      }}</span>
                  </p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <!-- We only calculate the dividends of your current holdings -->
                  <p class="heading">Dividends (12-month)</p>
                  <p class="title is-size-5-mobile">
                    {{ this.portfolio.currency }}
                    <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <line-chart :chartData="this.growthChart.data" :options="this.growthChart.options" ref="lineChart"
            class="column is-two-thirds"></line-chart>
        </div>
        <!-- / PERIOD CHARTS END -->

        <div class="tabs">
          <ul>
            <li class="is-active subtitle">📈 Dividends Tracking & Forecast</li>
          </ul>
        </div>
        <div class="columns">
          <!-- <div class="columns column is-4">
            <div class="column">
              <div class="card events-card">
                <header class="card-header">
                  <p class="card-header-title">Dividend Stats</p>
                </header>
                <div class="card-table">
                  <div class="content">
                    <table class="table is-fullwidth is-striped">
                      <tbody>
                        <tr>
                          <td>Current portfolio yield</td>
                          <td
                            class="level-right has-text-weight-bold"
                            v-if="dividendStats && dividendStats.yield"
                          >
                            {{ toLocaleNumber(this.dividendStats.yield) }}%
                          </td>
                          <td class="level-right has-text-weight-bold">-</td>
                        </tr>
                        <tr>
                          <td>Yield on invested capital</td>
                          <td class="level-right has-text-weight-bold">4%</td>
                        </tr>
                        <tr>
                          <td>12-month dividend earnings</td>
                          <td class="level-right has-text-weight-bold">
                            {{ this.dividendCurrency }}
                            <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
                            💰
                          </td>
                        </tr>
                        <tr>
                          <td>Largest dividend payer</td>
                          <td class="level-right has-text-weight-bold">
                            Apple 🏆
                          </td>
                        </tr>
                        <tr>
                          <td>Apple paid you</td>
                          <td class="level-right has-text-weight-bold">
                            USD 150 🤑
                          </td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="column">
            <dividends-bar-chart :dividends="this.dividends" :rates="this.rates"
              :displayCurrency="this.portfolio.currency"></dividends-bar-chart>
          </div>
        </div>
        <div class="tabs mt-6">
          <ul>
            <li class="is-active subtitle">👑 Dividends Ranking</li>
          </ul>
        </div>
        <bar-chart :chartData="this.dividendRanking.data" :options="this.dividendRanking.options"></bar-chart>
        <div class="tabs mt-6">
          <ul>
            <li class="is-active subtitle">📆 Dividends Forecast</li>
          </ul>
        </div>
        <table class="table is-fullwidth is-striped">
          <tbody>
            <tr v-for="position in nextDividends" :key="Math.floor(Math.random() * position.quantity)">
              <td>
                {{ position.ticker }}
                <span class="is-size-7">
                  {{ position.quantity }} x
                  {{ position.currency }}
                  {{ position.amount }}</span>
              </td>
              <td class="has-text-right">
                {{ toLocaleDate(new Date(position.paymentDate)) }}
              </td>
              <td class="level-right">
                {{ position.currency }}
                {{ toLocaleNumber(position.quantity * position.amount) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="nextDividends.length === 0">
              <td colspan="3">No expected future dividends yet.</td>
            </tr>
            <tr v-if="nextDividends.length > 0" class="has-text-weight-bold">
              <td>Total</td>
              <td class="has-text-right"></td>
              <td class="level-right">
                {{ this.portfolio.currency }}
                {{ toLocaleNumber(this.nextDividends.reduce((total, curr) => total + (curr.total *
                  handleCurrency(curr.currency)), 0)) }}
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="columns">
          <div class="column">
            <div class="tabs">
              <ul>
                <li class="is-active subtitle">🚀 Stock Holdings</li>
              </ul>
            </div>
            <div class="card events-card">
              <div class="card-table">
                <div class="content">
                  <table class="
                      table
                      is-fullwidth is-striped is-hoverable is-fullwidth
                    ">
                    <thead>
                      <tr class="is-hidden-mobile">
                        <!-- <th></th> -->
                        <th>Symbol</th>
                        <th>Quantity</th>
                        <th>Avg. Price</th>
                        <th>Invested</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th>Gains</th>
                        <th class="has-text-centered">Relative Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="position in portfolio.stockHoldings" :key="position.ticker">
                        <td data-label="Symbol">
                          <router-link :to="{
                            name: 'Company',
                            params: {
                              symbol: position.ticker,
                            },
                          }" class="nav-link">
                            <span>{{ position.ticker }}</span>
                          </router-link>
                        </td>
                        <td data-label="Quantity" class="is-hidden-mobile">
                          {{ position.size }}
                        </td>
                        <td data-label="Average" class="is-hidden-mobile">
                          <span class="is-size-7">{{ position.priceCurrency }}</span>
                          {{ toLocaleNumber(position.averagePrice) }}
                        </td>
                        <td data-label="Invested" class="is-hidden-mobile">
                          <span class="is-size-7">{{ position.priceCurrency }}</span>
                          {{ toLocaleNumber(position.totalAmount) }}
                        </td>
                        <td data-label="Price" class="has-text-right-mobile">
                          <span class="is-size-7">{{ position.priceCurrency }}</span>
                          {{ toLocaleNumber(position.lastKnownPrice) }}
                        </td>
                        <td data-label="Total" class="has-text-right-mobile">
                          <span class="is-size-7">{{ position.priceCurrency }}</span>
                          {{
                            toLocaleNumber(
                              position.size * position.lastKnownPrice
                            )
                          }}
                        </td>
                        <td data-label="Gains" class="is-hidden-mobile" :class="{
                              'has-text-success has-text-weight-bold':
                                position.size * position.lastKnownPrice -
                                position.totalAmount >
                                0,
                              'has-text-danger has-text-weight-bold':
                                position.size * position.lastKnownPrice -
                                position.totalAmount <
                                0,
                            }">
                          <!-- <span v-if="(position.size * position.lastKnownPrice) - position.totalAmount > 0">+</span> -->
                          <!-- <span v-if="(position.size * position.lastKnownPrice) - position.totalAmount > 0" class="icon"><i class="fa fa-arrow-up"></i></span> -->
                          <!-- <span v-if="(position.size * position.lastKnownPrice) - position.totalAmount > 0" class="icon"><i class="fa fa-arrow-down"></i></span> -->
                          <span class="is-size-7">{{ position.priceCurrency }}</span>
                          <span :class="{ blink: position.blink }">
                            {{
                              toLocaleNumber(
                                position.size * position.lastKnownPrice -
                                position.totalAmount,
                                {
                                  signDisplay: "exceptZero",
                                }
                              )
                            }}
                          </span>
                        </td>
                        <td data-label="RelativeSize" class="is-hidden-mobile has-text-centered">
                          {{
                            position.lastKnownPrice &&
                            toLocaleNumber(
                              (handleCurrency(position.priceCurrency) * (position.size * position.lastKnownPrice) * 100) /
                              stocksTotal) + "%"
                          }}
                          <progress v-if="position.lastKnownPrice" class="progress is-small"
                            :value="(handleCurrency(position.priceCurrency) * (position.size * position.lastKnownPrice) * 100) / stocksTotal"
                            max="100"></progress>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <footer class="card-footer">
                <!-- <a href="#" class="card-footer-item">View All</a> -->
              </footer>
            </div>
          </div>
        </div>

        <div class="columns">
          <!-- footer spacing -->
          <div class="column" style="visibility: hidden"></div>
          <!-- / footer spacing-->
        </div>
      </div>
      <!-- / PORTFOLIO VIEW -->
    </div>
  </section>
</template>

<script>
import DoughnutChart from "@/components/zuneCharts/DoughnutChart";
import LineChart from "@/components/zuneCharts/LineChart";
import DividendsBarChart from "@/components/dashboardComponents/DividendsBarChart.vue";
import BarChart from "@/components/zuneCharts/BarChart";

import { Zune } from "@/services/zune.service";

export default {
  name: "PublicPortfolio",
  components: {
    DoughnutChart,
    LineChart,
    DividendsBarChart,
    BarChart
  },
  methods: {
    handleCurrency(fromCurrency) {
      if (fromCurrency == this.portfolio.currency) {
        return 1; // default rate
      }

      return this.rates[fromCurrency + this.portfolio.currency];
    },
    async fetchMetaData() {
      const portfolio = await Zune.publicPortfolio(this.$route.params.id || 75);

      if (portfolio.data.message !== "NOT_FOUND") {
        this.portfolio = portfolio.data.result;
        this.timeline = portfolio.data.result.timeline;

        if (this.portfolio.hasHoldings) {
          this.portfolio.stockHoldings.forEach(
            (position) => (position.blink = false)
          );

          this.dividends = this.portfolio.stockHoldings.reduce((acc, curr) => [...acc, ...curr.dividends], [])

          this.rates = portfolio.data.result.rates;

          this.dividendStats = portfolio.data.result.dividendStats;

          var highest = 0;
          var ranking = this.dividends.reduce((acc, curr) => {
            const totalPaid = curr.total * this.handleCurrency(curr.currency);
            if (totalPaid > 0) {
              acc[curr.ticker] = (acc[curr.ticker] || 0) + totalPaid;
            }

            if (acc[curr.ticker] > highest) {
              highest = acc[curr.ticker];
            }

            // dividend forecast
            if (Date.now() < new Date(curr.paymentDate)) {
              this.nextDividends.push(curr);
            }

            return acc;
          }, {});

          this.dividendRanking.data = {
            labels: Object.keys(ranking).map(key => ranking[key] == highest ? key + "🏆" : key),
            datasets: [
              {
                label: "Expected Dividends (" + this.portfolio.currency + ")",
                backgroundColor: "#99ccff",
                data: Object.values(ranking),
              },
            ],
          };
          this.dividendRanking.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                ticks: {
                  callback: (value) => {
                    return this.portfolio.currency + ' ' + this.toLocaleNumber(value);
                  },
                },
                stacked: true
              }]
            }
          };

          this.displayPortfolio = true;
        } else {
          this.displayPortfolio = false;
        }
      } else {
        this.displayPortfolio = false;
      }
    },
    async getGrowthChartData(period = "12-months") {
      this.growthChartData = await Zune.publicgrowth(this.$route.params.id || 75, period);
    },
    calculateGrowthChart() {
      const growthChart = this.growthChartData.data;
      this.growthChart.data.labels = [];

      this.growthChart.options.scales.yAxes[0].ticks.min = Number.MAX_VALUE;
      this.growthChart.data.datasets[0].data = [];

      Object.keys(growthChart).sort().forEach(key => {
        this.growthChart.data.labels.push(key);
        const entry = growthChart[key].value;

        this.growthChart.data.datasets[0].data.push(entry);
        if (entry < this.growthChart.options.scales.yAxes[0].ticks.min) {
          this.growthChart.options.scales.yAxes[0].ticks.min = entry;
        }
      });

      this.growthChart.options.scales.yAxes[0].ticks.min =
        this.growthChart.options.scales.yAxes[0].ticks.min * 0.95;
    },
    calculatePortfolioOverview() {
      for (const position of this.portfolio.stockHoldings) {

        var currencyRate = this.handleCurrency(position.priceCurrency);

        this.portfolioOriginalValue += position.totalAmount * currencyRate;

        var stockTotal = position.lastKnownPrice * position.size * currencyRate;
        this.portfolioActualValue += stockTotal;
        this.stocksTotal += stockTotal;

        position.dividends.forEach((dividend) => {
          this.dividendAmount += dividend.amount * position.size * currencyRate;

          if (Date.now() < new Date(dividend.paymentDate)) {
            dividend.positionSize = position.size;
            dividend.positionName = position.ticker;
            dividend.totalAmount = position.size * dividend.amount;
          }
        });
      }

      this.portfolioAggregatedReturn =
        (this.portfolioActualValue / this.portfolioOriginalValue - 1) * 100;
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchMetaData(),
      this.getGrowthChartData()
    ])

    this.ready = true;

    if (this.displayPortfolio) {
      this.calculatePortfolioOverview();
      this.calculateGrowthChart();
    }
  },
  data() {
    var self = this

    return {
      displayPortfolio: false,
      growthChartData: {},
      rates: [],
      portfolio: {},
      timeline: {},
      cashTotal: 0,
      stocksTotal: 0,
      realEstateTotal: 0,
      portfolioActualValue: 0,
      portfolioOriginalValue: 0,
      portfolioAggregatedReturn: 0,
      dividendAmount: 0,
      nextDividends: [],
      dividendStats: {},
      dividends: [],
      selectedPeriod: "12-months",
      debug: false,
      ready: false,
      dividendRanking: {},
      growthChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Stocks",
              data: [],
              backgroundColor: "rgba(1, 116, 188, 0.50)",
              borderColor: "rgba(1, 116, 188, 0.50)",
              pointBackgroundColor: "rgba(171, 71, 188, 1)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Stocks",
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  callback: function (value) {
                    return self.toLocaleNumber(value);
                  },
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>
