<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <section class="section">
        <progress class="progress is-small is-primary" max="100"></progress>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    start() {
      this.isActive = true;
    },
    finish() {
      this.isActive = false;
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped>
.progress {
  --progress-indeterminate-duration: 0.3s
}
</style>