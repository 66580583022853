<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Share Portfolio</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="field has-addons is-centered">
          <div class="field-label">
            <label class="label">Share Link</label>
          </div>
          <div class="field-body">
            <div class="control">
              <input
                class="input"
                type="text"
              />
            </div>
            <div class="control">
              <a class="button is-info" title="Share portfolio link">
                <i class="fa fa-copy"></i><span class="ml-2">Copy</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";

export default {
  methods: {
    async openModal() {
      this.isActive = true;
      this.dragAndDropCapable = false;
      this.selectedFile = "";
      this.files = [];
    },
    closeModal() {
      this.isActive = false;
    },
    handleFileUpload() {
      let uploadedFiles = this.$refs.files.files;

      if (uploadedFiles.length > 0) {
        this.selectedFile = uploadedFiles[0].name;
      } else {
        this.selectedFile = "";
      }

      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]); // todo: validate files
      }
    },
    submitFiles() {
      /*
        Initialize the form data
        */
      let formData = new FormData();

      /*
        Iteate over any file sent over appending the files
        to the form data.
        */
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i], this.files[i].name);
      }

      //formData.append("files", this.files);
      formData.append("clientid", "local-debug");
      formData.append("portfolio", this.$store.state.portfolio.id);

      Zune.fileUpload(formData, {
        onUploadProgress: function (progressEvent) {
          //this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          console.log(progressEvent);
        }.bind(this),
      })
        .then(
          function (response) {
            this.files = [];
            // this.onupload(response.data);
            console.log(response.success);
            alert("upload success, refresh the page!");
          }.bind(this)
        )
        .catch(
          function (error) {
            this.files = [];
            console.log(JSON.stringify(error));
          }.bind(this)
        );
    },
  },
  props: {},
  data() {
    return {
      isActive: false,
      dragAndDropCapable: false,
      selectedFile: "",
      files: [],
    };
  },
};
</script>

<style scoped>
</style>