import Vue from 'vue'

Vue.mixin({
methods: {
    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
    toPercentNumber(portion) {
        return this.toLocaleNumber((portion * 100)) + '%';
    }, 
    toLocaleNumber(num, options) {
        return Number(num).toLocaleString(undefined, {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            ...options
        });
    }, 
    toLocaleDate(dt) {

        // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        // return dt.toLocaleDateString(dt, options);

        return  dt.toLocaleDateString("en-US", { year: 'numeric' }) + "-" +
         dt.toLocaleDateString("en-US", { month: 'numeric' }) + "-" +
         dt.toLocaleDateString("en-US", { day: 'numeric' });
    }, 
    toLocaleDateTime(dt) {
        return  dt.toLocaleDateString() + " " + dt.toLocaleTimeString('en-US');
    },
    toLocaleTime(dt) {
        return  dt.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
    }
}
});