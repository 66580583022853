<template>
  <section class="section">
    In construction.
  </section>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Markets',
  components: {

  },
  async mounted() {

  },
  data() {
    return {

    }
  }
}
</script>
