import { render, staticRenderFns } from "./UpcomingDividendsPanel.vue?vue&type=template&id=4b2e89aa&scoped=true&"
import script from "./UpcomingDividendsPanel.vue?vue&type=script&lang=js&"
export * from "./UpcomingDividendsPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2e89aa",
  null
  
)

export default component.exports