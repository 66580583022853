<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('addActivity.title') }}</p>
        <button class="delete" aria-label="close" @click="closeModal()"></button>
      </header>
      <section class="modal-card-body">
        <div class="tabs is-centered" v-if="!(!isManualInput && this.profile.type === 0)">
          <ul>
            <li class="is-active">
              <a class="button is-white">📈 {{ $t('app.stocks') }}</a>
            </li>
            <li>
              <a class="button is-white" @click="swapModal('cash')">💵 {{ $t('app.cash') }}</a>
            </li>
            <li :class="{ 'is-active': selected == 'realEstate' }">
              <a class="button is-white" @click="swapModal('realestate')">🏘️ {{ $t('app.realEstate') }}</a>
            </li>
            <!-- <li :class="{ 'is-active': selected == 'other' }">
              <a class="button is-white" @click="swapModal('other')">🪙 Other</a>
            </li> -->
          </ul>
        </div>
        <div class="tabs is-fullwidth" v-if="!(!isManualInput && this.profile.type === 0)">
          <ul>
            <li :class="{ 'is-active': isManualInput }">
              <a @click="toggleTab()">
                {{ $t('addActivity.addManually') }}<i class="ml-2 fa fa-edit is-size-4"></i>
              </a>
            </li>
            <li :class="{ 'is-active': !isManualInput }">
              <a @click="toggleTab()">
                {{ $t('addActivity.uploadFile') }}<i class="ml-2 fa fa-cloud-upload is-size-4"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="manual-input" v-if="isManualInput">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">{{ $t('app.symbol') }}</label>
                <div class="control" v-bind:class="{ 'is-loading is-large': !isReady }">
                  <DropDown :options="options" v-on:selected="onSuggestionSelected" v-on:filter="inputChange"
                    :disabled="false" name="zipcode" :maxItem="30" :placeholder="$t('addActivity.searchBarText')"
                    :inputClasses="{
          'is-danger':
            errors &&
            (!newTransaction.symbol ||
              !newTransaction.name ||
              !newTransaction.exchange),
        }" ref="dropDown">
                  </DropDown>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">{{ $t('app.quantity') }}</label>
                    <div class="control">
                      <input class="input" type="number" min="1" step="1" v-model="newTransaction.size" v-bind:class="{
          'is-danger': errors && !newTransaction.size,
        }" />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">{{ $t('app.price') }}</label>
                    <div class="control">
                      <input class="input" type="number" step="0.01" v-model="newTransaction.price" v-bind:class="{
          'is-danger': errors && !newTransaction.price,
        }" />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">{{ $t('app.transactionDate') }}</label>
                    <div class="control">
                      <input class="input" type="date" v-model="newTransaction.executionDate" v-bind:class="{
          'is-danger': errors && !newTransaction.executionDate,
        }" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="import-file" v-if="!isManualInput && this.profile.type === 1">
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="label">
                  <label class="label">{{ $t('app.broker') }}</label>
                  <div class="control">
                    <div class="select">
                      <select class="" v-model="uploadInformation.broker">
                        <option value="degiro">Degiro</option>
                        <option value="trading212">Trading212</option>
                        <!-- <option value="unknown">Can't find my broker</option> -->
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <div class="label">
                  <label class="label">{{ $t('app.fileType') }}</label>
                  <div class="control">
                    <div class="select">
                      <select class="" v-model="uploadInformation.fileType">
                        <option value="csvtransactions">
                          {{ $t('app.csvTransactions') }}
                        </option>
                        <!-- <option value="csvportfolio">
                            CSV Portfolio Summary
                          </option> -->
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="label">
                  <label class="label">{{ $t('app.csvFile') }}</label>
                </div>
                <div class="field-body">
                  <div class="file is-right is-info has-name">
                    <label class="file-label">
                      <input class="file-input" type="file" ref="files" name="resume"
                        v-on:change="handleFileUpload()" />
                      <span class="file-cta">
                        <span class="file-icon">
                          <i class="fa fa-upload"></i>
                        </span>
                        <span class="file-label"> {{ $t('app.buttons.upload') }} </span>
                      </span>
                      <span class="file-name">
                        {{
          uploadInformation.selectedFile ||
          $t('app.buttons.uploadMessage')
        }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="column">
              <div class="field">
                <div class="label">
                  <label class="label">Allow Override?</label>
                  <div class="control">
                    <div class="select">
                      <select class="">
                        <option value="1" selected>Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="label">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" v-model="uploadInformation.skipAlreadyImported"
                        v-bind:id="uploadInformation.skipAlreadyImported" />
                      <label class="label">{{ $t('app.skipAlreadyImported') }}</label>
                    </label>
                    <small>{{ $t('app.skipAlreadyImportedNotes') }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="import-file" v-if="!isManualInput && this.profile.type !== 1">
          <div class="columns is-centered">
            <div class="column">
              <div class="level is-mobile">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="title is-size-5-mobile">📈 Unlock PRO-Level Features</p>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <span class="icon is-small has-text-success">
                    <i class="fa fa-check"></i>
                  </span> Upload multiple transactions via <strong>Degiro's CSV transactions file</strong>
                </li>
                <li>
                  <span class="icon is-small has-text-success">
                    <i class="fa fa-check"></i>
                  </span> Early access to new features
                </li>
                <li>
                  <span class="icon is-small has-text-success">
                    <i class="fa fa-check"></i>
                  </span> Add unlimited assets to your portfolio.
                </li>
                <li>
                  <span class="icon is-small has-text-success">
                    <i class="fa fa-check"></i>
                  </span> Access all charts: Sectors, Currencies, Countries, Swings, and Yearly charts.
                </li>
                <li>
                  <span class="icon is-small has-text-success">
                    <i class="fa fa-check"></i>
                  </span> Priority support.
                </li>
              </ul>

              <div class="columns">
                <!-- Left column with price and descriptions -->
                <div class="column has-text-centered">
                  <div class="content is-size-4">
                    <strong>€{{ toLocaleNumber(view.monthlyPrice) }}/Month</strong>
                    <p>7 days free - <small>Cancel anytime</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <button v-if="(this.profile.type === 1 && !isManualInput) || isManualInput" class="button is-secondary"
          @click="closeModal()">Close</button>
        <button v-if="this.profile.type === 1 && !isManualInput" class="button is-primary"
          v-bind:class="{ 'is-loading': !isReadyUpload }" @click="submitFiles()">
          {{ $t('app.buttons.upload') }}
        </button>
        <button class="button is-danger" type="button" v-bind:class="{ 'is-loading': !isReadySell }"
          v-if="isManualInput" @click="sell()">
          {{ $t('app.buttons.sell').toUpperCase() }}
        </button>
        <button class="button is-primary" type="button" v-bind:class="{ 'is-loading': !isReadyBuy }"
          v-if="isManualInput" @click="buy()">
          {{ $t('app.buttons.buy').toUpperCase() }}
        </button>
        <button v-if="!isManualInput && this.profile.type === 0" @click="getSubscriptionLink()"
          class="button is-success is-medium">
          <span class="icon">
            <i class="fa fa-check"></i>
          </span>
          <span>{{ $t('app.buttons.becomePro') }}</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";
import DropDown from "./custom/DropDown.vue";

export default {
  components: {
    DropDown,
  },
  computed: {
    monthlyPayLink() {
      return `https://buy.stripe.com/4gw6st2dZdY78UM5kw?client_reference_id=${this.view.clientReference}`
    },
    yearlyPayLink() {
      return `https://buy.stripe.com/5kAbMNaKvdY71skfZ8?client_reference_id=${this.view.clientReference}`
    },
  },
  methods: {
    async getSubscriptionLink() {
      const response = await Zune.getCartReference(this.view.mode);

      if (response.success) {
        this.view.clientReference = response.data;
        window.location.href = this.view.mode === 'yearly' ? this.yearlyPayLink : this.monthlyPayLink;
      }
    },
    swapModal(val) {
      this.$emit("swapModal", val);
    },
    async inputChange(text) {
      this.isReady = false;

      // js native call
      clearTimeout(this.searchTimeoutId);

      // set a new timeout to call Zune.search after a delay (e.g., 1000 milliseconds)
      this.searchTimeoutId = setTimeout(async () => {
        let response = await Zune.search(text);
        if (!text) {
          this.options = response.recent || [];
        } else {
          this.options = response.data;
        }
        this.isReady = true;
      }, 400);
    },
    actionTaken(msg) {
      this.$emit("actionTaken", true);
      this.$emit("notifyMessage", msg);
    },
    onSuggestionSelected(item) {
      this.newTransaction.symbol = item.symbol;
      this.newTransaction.name = item.name;
      this.newTransaction.exchange = item.exchange;
      this.newTransaction.priceCurrency = item.currency;
    },
    async buy() {
      if (
        !this.newTransaction.symbol ||
        !this.newTransaction.exchange ||
        !this.newTransaction.price ||
        !this.newTransaction.size ||
        !this.newTransaction.executionDate
      ) {
        this.errors = true;
      } else {
        this.errors = false;
        this.isReadyBuy = false;
        const response = await Zune.addActivity(
          this.$store.state.portfolio.id,
          {
            ticker: this.newTransaction.symbol,
            exchange: this.newTransaction.exchange,
            priceCurrency: this.newTransaction.priceCurrency,
            price: this.newTransaction.price,
            quantity: this.newTransaction.size,
            executionDate: this.newTransaction.executionDate,
            type: 'STOCK'
          }
        );
        this.isReadyBuy = true;

        if (response.success) {
          this.actionTaken(
            "Added " +
            this.newTransaction.size +
            " shares of " +
            this.newTransaction.symbol +
            " @" +
            this.newTransaction.priceCurrency +
            " " +
            this.newTransaction.price
          );
        }
      }
    },
    async sell() {
      if (
        !this.newTransaction.symbol ||
        !this.newTransaction.exchange ||
        !this.newTransaction.price ||
        !this.newTransaction.size ||
        !this.newTransaction.executionDate
      ) {
        this.errors = true;
      } else {
        this.errors = false;
        this.isReadySell = false;
        const response = await Zune.addActivity(
          this.$store.state.portfolio.id,
          {
            ticker: this.newTransaction.symbol,
            exchange: this.newTransaction.exchange,
            priceCurrency: this.newTransaction.priceCurrency,
            quantity: this.newTransaction.size * -1,
            price: this.newTransaction.price,
            executionDate: this.newTransaction.executionDate,
            type: 'STOCK'
          }
        );

        this.isReadySell = true;
        if (response.success) {
          this.actionTaken(
            "Sold " +
            this.newTransaction.size +
            " shares of " +
            this.newTransaction.symbol +
            " @" +
            this.newTransaction.priceCurrency +
            " " +
            this.newTransaction.price
          );
        }
      }
    },
    toggleTab() {
      this.isManualInput = !this.isManualInput;
    },
    closeModal() {
      this.$emit("swapModal", '');
    },
    handleFileUpload() {
      let uploadedFiles = this.$refs.files.files;
      this.uploadInformation.files.length = 0;

      if (uploadedFiles.length > 0) {
        this.uploadInformation.selectedFile = uploadedFiles[0].name;
        this.uploadInformation.files.push(uploadedFiles[0]);
      } else {
        this.uploadInformation.selectedFile = "";
      }

      // run this only if we allow for multiple files
      // for (var i = 0; i < uploadedFiles.length; i++) {
      //   this.uploadInformation.files.push(uploadedFiles[i]); // todo: validate files
      // }
    },
    submitFiles() {
      this.isReadyUpload = false;
      /*
        Initialize the form data
        */
      let formData = new FormData();

      /*
        Iteate over any file sent over appending the files
        to the form data.
        */
      for (var i = 0; i < this.uploadInformation.files.length; i++) {
        formData.append(
          "files",
          this.uploadInformation.files[i],
          this.uploadInformation.files[i].name
        );
      }

      formData.append("broker", this.uploadInformation.broker);
      formData.append("fileType", this.uploadInformation.fileType);
      formData.append("skipAlreadyImported", this.uploadInformation.skipAlreadyImported);

      Zune.fileUpload(formData, {
        onUploadProgress: function (progressEvent) {
          //this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          console.log(progressEvent);
        }.bind(this),
      })
        .then(
          function () {
            this.actionTaken("Activity imported.");
            this.isReadyUpload = true;
          }.bind(this)
        )
        .catch(
          function (error) {
            this.$emit("notifyMessage", "An error occurred.");
            this.isReadyUpload = true;
            console.log(JSON.stringify(error));
          }.bind(this)
        );
    },
  },
  props: {},
  async mounted() {
    var response = await Zune.profile();
    if (response.success) {
      this.profile = response.data;
    }
  },
  data() {
    return {
      profile: {},
      prefill: undefined,
      searchTimeoutId: undefined,
      selected: 'stocks',
      isReady: true,
      isReadyUpload: true,
      isReadyBuy: true,
      isReadySell: true,
      errors: false,
      dragAndDropCapable: false,
      isManualInput: true,
      newTransaction: {},
      uploadInformation: {
        selectedFile: '',
        files: [],
        skipAlreadyImported: true
      },
      options: [],
      view: {
        mode: 'monthly',
        monthlyPrice: 2.99,
        yearlyMonthlyPrice: 0.33,
        yearlyPrice: 4,
        clientReference: ''
      },
    };
  },
};
</script>