<script>
import { Doughnut } from "vue-chartjs";

export default {
    extends: Doughnut,
    props: ["values", "labels"],
    methods: {
        update() {
            this.$data._chart.update();
        },
    },
    mounted() {

        // function kFormatter(num) {
        //     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num).toFixed(2)
        // }

        // const formatedTotalValue = kFormatter(this.totalValue);

        // this.addPlugin({
        //   beforeDraw: function(chart) {
        //     var width = chart.chart.width,
        //         height = chart.chart.height,
        //         ctx = chart.chart.ctx;

        //     ctx.restore();
        //     var fontSize = (height / 114).toFixed(2);
        //     ctx.font = fontSize + "em sans-serif";
        //     ctx.textBaseline = "middle";

        //     var legendHeight =  chart.legend.height;

        //     var text = formatedTotalValue,
        //         textX = Math.round((width - ctx.measureText(text).width) / 2),
        //         textY = height / 2 + legendHeight/2;

        //     ctx.fillText(text, textX, textY);
        //     ctx.save();
        //   }
        // });

        // this.addPlugin(Colors);

        // function generateRandomColor() {
        //     const randomColor = () => Math.floor(Math.random() * 256);
        //     return `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`;
        // }

        
        var maxVal = 0;
        for(var val of this.values) {
            if (val > maxVal || maxVal === 0) {
                maxVal = val
            }
        }

        var valueKeys = this.values;

        function stringToColor(_, index) {
            // 1, 116, 188
            var opacity = valueKeys[index] / maxVal * 1.1
            return `rgb(1, 116, 118, ${opacity})`;
        }

        const colors = Array.from({ length: this.values.length }, stringToColor);

        const chartData = {
            labels: this.labels,
            datasets: [
                {
                    label: "",
                    data: this.values,
                    backgroundColor: colors,
                    hoverOffset: 4,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
        };

        this.renderChart(chartData, options);
    },
};
</script>
