<template>
    <section class="section">
        <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
            <div class="loader is-loading"></div>
        </div>
        <div class="section" v-if="ready">
            <!-- CURRENCY -->
            <div class="tabs">
                <ul>
                    <li class="is-active subtitle">{{ $t('swings.title') }}</li>
                </ul>
            </div>

            <div v-for="chart in charts" :key="chart.symbol">
                <line-chart
                :chartData="chart.data" :options="chart.options" ref="lineChart"></line-chart>
            </div>
        </div>
    </section>
</template>
    
<script>
// @ is an alias to /src

import LineChart from "../../components/zuneCharts/LineChart.vue";

import { Zune } from "@/services/zune.service";

// import SimpleDoughnutChart from "@/components/zuneCharts/SimpleDoughnutChart";

export default {
    name: "Allocation",
    components: {
        // SimpleDoughnutChart,
        LineChart
    },
    async mounted() {
        this.ready = false
        var allocationResponse = await Zune.portfolioHoldingStats();
        if (allocationResponse.success) {
            for(let holding of allocationResponse.data) {
                this.charts.push({
                    symbol: holding.symbol,
                    data: {
                        labels: holding.dates,
                        datasets: [
                            {
                                label: holding.symbol,
                                data: holding.close,
                                backgroundColor: "rgba(1, 116, 188, 0.50)",
                                borderColor: "rgba(1, 116, 188, 0.50)",
                                pointBackgroundColor: "rgba(171, 71, 188, 1)"
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: false,
                            text: holding.symbol,
                        },
                        elements: {
                            point: {
                                radius: 0,
                            },
                        },
                        scales: {
                            yAxes: [
                            {
                                ticks: {
                                // min: 0,
                                    callback: (value) => {
                                        return `${holding.currency} ${this.toLocaleNumber(value)}`;
                                    },
                                },
                            },
                            ],
                        },
                    },
                })
            }
        }

        this.ready = true
    },
    data() {
        return {
            ready: true,
            holdings: [],
            charts: [],
            growthChart: {
                data: {
                    labels: ['a', 'b', 'c', 'd'],
                    datasets: [
                        {
                            label: "Stock Holdings",
                            data: [1,2,3,4],
                            backgroundColor: "rgba(1, 116, 188, 0.50)",
                            borderColor: "rgba(1, 116, 188, 0.50)",
                            pointBackgroundColor: "rgba(171, 71, 188, 1)"
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                        text: "Stock Holdings",
                    },
                    elements: {
                        point: {
                            radius: 0,
                        },
                    },
                },
            },
        };
    },
};
</script>
    