<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('account.section.deleteSubscriptionTitle') }}</p>
        <button class="delete" aria-label="close" @click="closeModal()"></button>
      </header>
      <section class="modal-card-body">
        <div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">{{ $t('account.fields.deleteSubscriptionConfirmation') }}</label>
                <div class="control">
                  <input class="input" type="text" v-model="subscriptionCancellationText" v-bind:class="{
                    'is-danger': errors && !subscriptionCancellationText,
                  }" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="confirmCancellation()"
          :disabled="!subscriptionCancellationText || subscriptionCancellationText.trim().toLowerCase() !== 'cancel'">{{ $t('app.buttons.confirmUpperCase') }}</button>
        <button class="button is-small is-primary" style="justify-content: end" type="button" @click="closeModal()">
          {{ $t('app.buttons.cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>
  
<script>
import { Zune } from "@/services/zune.service";

export default {
  methods: {
    async confirmCancellation() {
      const response = await Zune.deleteSubscription();
      if (response.success) {
        window.location.href = 'https://app.zune.money';
      } else {
        alert(this.$t('account.messages.deleteSubscriptionError'))
      }
    },
    openModal() {
      this.isActive = true;
    },
    closeModal() {
      this.errors = false;
      this.isActive = false;
    },
  },
  data() {
    return {
      errors: false,
      isActive: false,
      subscriptionCancellationText: ""
    };
  },
};
</script>
  
<style scoped></style>