<template>
  <section class="section">
    <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
      <div class="loader is-loading"></div>
    </div>

    <div class="columns" v-if="ready">
      <div class="column">
        <!-- <div class="field">
          <label class="label">Look for company or symbol</label>
          <div class="control">
            <input class="input" type="text" v-model="activeFilters.text" />
          </div>
        </div> -->
        <div class="field">
          <div class="label">
            <label class="label">Country</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="activeFilters.country">
                  <option :value="country.code" v-for="country in activeFilters.metadata.countries" :key="country.code">{{
                    country.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="field">
          <label class="label">Country</label>
          <div class="control">
            <input class="input" type="text" v-model="activeFilters.country" />
          </div>
        </div> -->
        <!-- <div class="field">
          <label class="label">Currency</label>
          <div class="control">
            <input class="input" type="text" v-model="activeFilters.currency" />
          </div>
        </div> -->
        <div class="field">
          <label class="label">Exchange</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="activeFilters.exchange">
                <option :value="exchange.exchange" v-for="exchange in activeFilters.metadata.exchanges"
                  :key="exchange.exchange">{{ exchange.description }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Type</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="activeFilters.assetType">
                <option :value="assetType.code" v-for="assetType in activeFilters.metadata.assetTypes"
                  :key="assetType.code">{{ assetType.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field buttons">
          <button class="button is-primary is-fullwidth" type="button" v-bind:class="{ 'is-loading': loading }"
            @click="search(0)">
            Search
          </button>
          <!-- <button class="button is-secondary is-fullwidth" type="button" @click="clearSearch()">
            Clear
          </button> -->
        </div>
      </div>
    </div>
    <div class="columns" v-if="!loading">
      <div class="column">
        <div class="loader-wrapper columns is-centered is-vcentered is-mobile" style="min-height: 80vh" v-if="loading">
          <div class="loader is-loading"></div>
        </div>
        <table class="table is-fullwidth is-striped" v-if="!loading">
          <thead>
            <tr>
              <th>Company</th>
              <th>Symbol</th>
              <th>Exchange</th>
              <!-- <th>Price</th>
              <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in queryResults" :key="item.id">
              <td style="width:45%">
                <router-link :to="{
                  name: 'Company',
                  params: {
                    symbol: item.symbol,
                  },
                }" class="nav-link">
                  <!-- <span class="has-text-weight-bold is-size-7">{{ item.symbol }}</span> -->
                  <p class="is-size-6">{{ item.companyName }}</p>
                </router-link>
              </td>
              <td>
                {{ item.symbol }}
              </td>

              <td>{{ item.exchange }}</td>
              <!-- <td style="width:20%">
                <span v-if="item.currentPrice" class="is-size-7">{{ item.currentPriceCurrency }}&nbsp;</span>
                <span v-if="item.currentPrice" class="is-white">{{ toLocaleNumber(item.currentPrice) }}</span>
              </td> -->
              <!-- <td style="width:20%">
                <button class="button is-primary" type="button" v-bind:class="{ 'is-loading': loading }" @click="buy(item.symbol)">
                  BUY / SELL
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <pagination :currentPage="this.activeFilters.currentPage" :totalItems="this.activeFilters.totalItems"
          :pageSize="this.activeFilters.pageSize" @pageChanged="search"></pagination>
      </div>
    </div>

    <manage-position ref="positionManager" :portfolioId="75" @actionTaken="onActionTaken"></manage-position>

  </section>
</template>
  
<script>
// @ is an alias to /src
import { Zune } from "@/services/zune.service";
import { COUNTRIES } from './../.env.config'
import ManagePosition from "@/components/ManagePosition.vue";
import Pagination from '../components/Pagination.vue';


export default {
  components: {
    ManagePosition,
    Pagination,
  },
  computed: {
    selectedCountry() {
      return this.activeFilters.country;
    },
  },
  watch: {
    selectedCountry() {
      this.activeFilters.exchange = undefined;

      return this.loadMeta();
    },
  },
  methods: {
    async onActionTaken() {

    },
    buy(symbol) {
      // this.$refs.progressBar.start();
      this.$refs.positionManager.openModal(symbol, () => { });
    },
    async loadMeta() {
      let responseMeta = await Zune.stockScreenerMeta(this.activeFilters.country);
      this.activeFilters.metadata.exchanges = responseMeta.data;
    },
    async search(page) {
      this.loading = true;
      let response = await Zune.stockScreener(this.activeFilters.country, {
        exchange: this.activeFilters.exchange,
        type: this.activeFilters.assetType,
        page
      });
      let responseMeta = await Zune.stockScreenerMeta(this.activeFilters.country);

      if (response.success) {
        this.activeFilters.currentPage = page;
        this.activeFilters.totalItems = response.data.total;
        this.queryResults = response.data.items;

        this.activeFilters.metadata.exchanges = responseMeta.data;
      } else {
        // todo alert
      }
      this.loading = false;
    },
  },

  async mounted() {
    // this.ready = false;
    await this.search(0);
    // this.ready = true;
  },
  data() {
    return {
      activeFilters: {
        country: 'US',
        assetType: 'cs',
        exchange: 'XNYS',
        text: '',
        currentPage: 0,
        totalItems: 0,
        pageSize: 100,
        metadata: {
          "countries": COUNTRIES,
          assetTypes: [
            {
              code: 'ad',
              name: 'ADRs'
            },
            {
              code: 'cs',
              name: 'Common Stocks'
            },
            {
              code: 'ps',
              name: 'Preferred Stocks'
            },
            {
              code: 'cef',
              name: 'Closed End Fund'
            },
            {
              code: 'et',
              name: 'ETFs'
            },
            {
              code: 'oef',
              name: 'Open Ended Fund'
            },
            {
              code: 'struct',
              name: 'Structured Product'
            },
            {
              code: 'ut',
              name: 'Unit'
            },
            {
              code: 'wi',
              name: 'When Issued'
            },
            {
              code: 'wt',
              name: 'Warrant'
            },
          ],
          exchanges: []
        }
      },
      queryResults: [],
      total: 0,
      ready: true,
      loading: false
    };
  },
};
</script>
  