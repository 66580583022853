export class Cache {

  static set (key, content, secondsToExpire) {
    localStorage.setItem(key, JSON.stringify(content));
    setTimeout(() => { localStorage.removeItem(key); }, secondsToExpire * 1000);
  }

  static get (key) {
    return JSON.parse(localStorage.getItem(key));
  }
}