<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-one-third">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="title is-size-5-mobile">📈 Unlock PRO-Level Features</p>
            </div>
          </div>
        </div>
        <div class="tabs is-fullwidth">
          <ul>
            <li :class="{'is-active': view.mode == 'monthly'}"><a class="is-white" @click="switchMode('monthly')">Early bird - monthly plan</a></li>
            <!-- <li :class="{'is-active': view.mode == 'yearly'}"><a class="is-white" @click="switchMode('yearly')">Early bird - 12-months plan</a></li> -->
          </ul>
        </div>

        <div class="columns"  v-if="false">
          <!-- Left column with price and descriptions -->
          <div class="column">
            <div class="content">
                <p class="mb-1" v-if="view.mode === 'yearly'"><strong>€{{toLocaleNumber(view.yearlyMonthlyPrice)}}/Month</strong></p>
                <p class="mb-1"  v-if="view.mode === 'yearly'"><small>Billed annually (€{{toLocaleNumber(view.yearlyMonthlyPrice * 12)}})</small></p>
                <p class="mb-1" v-if="view.mode === 'monthly'"><strong>€{{toLocaleNumber(view.monthlyPrice)}}/Month</strong></p>
                <p><small>*Cancel anytime</small></p>
            </div>
          </div>

          <!-- Right column with the button -->
          <div class="column"  v-if="false">
            <div class="content has-text-centered">
              <a href="#" class="column">
                <button @click="getSubscriptionLink" class="button is-success">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>Subscribe Now</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div v-if="false" class="tabs">
          <ul>
            <li><label class="is-white">📈 Portfolio Management</label></li>
          </ul>
        </div>

        <ul>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Upload multiple transactions via <strong>Degiro's CSV transactions file</strong>
          </li>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Early access to new features
          </li>
        </ul>

        <ul>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Add unlimited assets to your portfolio.
          </li>
          <!-- <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Get daily performance reports for tracking.
          </li> -->
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Access all charts: Sectors, Currencies, Countries, Swings, and Yearly charts.
            <!-- <ul>
              <li>Currencies</li>
              <li>Sectors</li>
              <li>Countries</li>
            </ul> -->
          </li>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Priority support.
          </li>
        </ul>

        <div class="tabs mt-4" v-if="false">
          <ul>
            <li><label class="is-white">📅 Dividend Tracking</label></li>
          </ul>
        </div>

        <ul  v-if="false">
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Manage assets easily with unlimited capacity. 
          </li>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Stay updated with daily performance reports.
          </li>
        </ul>

        <div class="tabs mt-4"  v-if="false">
          <ul>
            <li ><label class="is-white">🕵️‍♂️ Research and Analysis</label></li>
          </ul>
        </div>

        <ul  v-if="false">
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Access advanced stock screening tools.
          </li>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Receive daily performance reports for insights.
          </li>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Enable index benchmarking (SP500, Dow, Nasdaq)
          </li>
        </ul>

        <!-- <div class="tabs mt-4">
          <ul>
            <li><label class="is-white">🔔 Notifications and Alerts</label></li>
          </ul>
        </div>

        <ul>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Set personalized price alerts for holdings.</li>
          <li>
            <span class="icon is-small has-text-success">
              <i class="fa fa-check"></i>
            </span> Get notified about dividend payments and announcements.</li>
        </ul> -->

        <div v-if="false" class="tabs is-fullwidth mt-4">
          <ul>
            <li :class="{'is-active': view.mode == 'monthly'}"><a class="is-white" @click="switchMode('monthly')">Monthly</a></li>
            <li :class="{'is-active': view.mode == 'yearly'}"><a class="is-white" @click="switchMode('yearly')">Yearly</a></li>
          </ul>
        </div>

        <div class="columns mt-4">
          <!-- Left column with price and descriptions -->
          <div class="column has-text-centered">
            <div class="content">
                <p class="mb-1 is-size-5" v-if="view.mode === 'yearly'"><strong>€ {{toLocaleNumber(view.yearlyPrice)}} / yearly</strong></p>
                <!-- <p class="mb-1"  v-if="view.mode === 'yearly'"><small>*Billed annually</small></p> -->
                <p class="mb-1" v-if="view.mode === 'monthly'"><strong>€{{toLocaleNumber(view.monthlyPrice)}}/Month</strong></p>
                <p><small>**Cancel anytime</small></p>
            </div>
          </div>

          <!-- Right column with the button -->
          <div class="column">
            <div class="content has-text-centered">
              <button @click="getSubscriptionLink()" class="button is-success is-medium">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>{{ $t('app.buttons.becomePro') }}</span>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- footer spacing -->
      <div class="column pb-6 mb-6" style="visibility: hidden"></div>
      <!-- / footer spacing-->
    </div>
    <!-- / FORM -->
  </section>
</template>

<script>
// @ is an alias to /src
import { Zune } from "@/services/zune.service";

export default {
  name: "ProfessionalSetup",
  components: {},
  computed: {
    monthlyPayLink() {
      return `https://buy.stripe.com/4gw6st2dZdY78UM5kw?client_reference_id=${this.view.clientReference}`
    },
    yearlyPayLink() {
      return `https://buy.stripe.com/5kAbMNaKvdY71skfZ8?client_reference_id=${this.view.clientReference}`
    },
  },
  methods: {
    switchMode(mode) {
      this.view.mode = mode
    },
    async getSubscriptionLink() {
      const response = await Zune.getCartReference(this.view.mode);

      if (response.success) {
        this.view.clientReference = response.data;
        window.location.href = this.view.mode === 'yearly' ? this.yearlyPayLink : this.monthlyPayLink;
      }
    }
  },
  async mounted() {
    await Zune.trace('premium');
  },
  data() {
    return {
      view: {
        // mode: 'yearly',
        // monthlyPrice: 0.33,
        // yearlyMonthlyPrice: 0.33,
        // yearlyPrice: 4,
        mode: 'monthly',
        monthlyPrice: 2.99,
        yearlyMonthlyPrice: 0.33,
        yearlyPrice: 4,
        clientReference: ''
      },
    };
  },
};
</script>
