<template>
    <section class="card events-card mt-3">
        <header class="card-header">
            <p class="card-header-title">📈 Stats</p>
        </header>
        <div v-if="!ready" style="min-height: 25vh" class="loader-wrapper columns is-centered is-vcentered is-mobile">
            <div class="loader is-loading"></div>
        </div>
        <div class="card-table">
            <div class="content">
                <table v-if="ready && visible" class="table
                      is-fullwidth is-striped is-hoverable is-fullwidth">
                    <tr v-if="this.stats.stocksOriginalAmount">
                        <td><span class="">Stocks:</span>  Total Invested</td>
                        <td>
                            {{ this.stats.currency }}
                            {{
                                toLocaleNumber(this.stats.stocksOriginalAmount)
                            }}
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="this.stats.stocksGains">
                        <td><span class="">Stocks:</span>  Price Gains</td>
                        <td>
                            <span>
                                {{ this.stats.currency }}
                                {{
                                    toLocaleNumber(
                                        this.stats.stocksGains
                                    )
                                }}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="this.stats.stocksActualAmount">
                        <td><span class="">Stocks:</span> Total</td>
                        <td>
                            {{ this.stats.currency }}
                            {{
                                toLocaleNumber(this.stats.stocksActualAmount)
                            }}
                        </td>
                        <td>
                            <span :class="{
                                        'has-text-success-dark': this.stats.stocksGainsPercentage > 0,
                                        'has-text-danger-dark': this.stats.stocksGainsPercentage < 0,
                                    }">
                                <span v-if="this.stats.stocksGainsPercentage > 0">+{{
                                    parseFloat(this.stats.stocksGainsPercentage * 100).toFixed(2)
                                }}%</span>
                                <span v-if="this.stats.stocksGainsPercentage < 0">{{
                                    parseFloat(this.stats.stocksGainsPercentage * 100).toFixed(2)
                                }}%</span>
                            </span>
                        </td>
                    </tr>
                    <tr v-if="this.stats.realEstateTotalAmount">
                        <td><span class="">Real Estate</span></td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(
                                        this.stats.realEstateTotalAmount
                                    )
                                }}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="this.stats.cashAllocation">
                        <td>Cashflow <span v-if="false">*total deposits minus withdraws from your cashholdings</span></td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(
                                        this.stats.cashAllocation
                                    )
                                }}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="false && this.stats.realizedAmount">
                        <td>Realized</td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(this.stats.realizedAmount)
                                }}
                            </span>
                        </td>
                        <td>
                            <span class="px-3 is-size-5" :class="{
                                        'has-text-success-dark': this.stats.realizedReturn > 0,
                                        'has-text-danger-dark': this.stats.realizedReturn < 0,
                                    }">
                                <span v-if="this.stats.realizedReturn > 0">+{{
                                    parseFloat(this.stats.realizedReturn).toFixed(2)
                                }}%</span>
                                <span v-if="this.stats.realizedReturn < 0">{{
                                    parseFloat(this.stats.realizedReturn).toFixed(2)
                                }}%</span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>5-y Dividends (gross)</td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(this.remoteStats.dividendsAmountGross)
                                }}
                            </span>
                        </td>
                        <td>
                            <span class="px-3 is-size-5" :class="{
                                        'has-text-success-dark': this.stats.dividendsReturn > 0,
                                        'has-text-danger-dark': this.stats.dividendsReturn < 0,
                                    }">
                                <span v-if="this.stats.dividendsReturn > 0">+{{
                                    parseFloat(this.stats.dividendsReturn).toFixed(2)
                                }}%</span>
                                <span v-if="this.stats.dividendsReturn < 0">{{
                                    parseFloat(this.stats.dividendsReturn).toFixed(2)
                                }}%</span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="">Portfolio:</span> Total</td>
                        <td>
                            {{ this.stats.currency }}
                            {{
                                toLocaleNumber(this.stats.totalValue)
                            }}
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="false">
                        <td>Total</td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(this.stats.gainsAmount)
                                }}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    <tr></tr>
                    <tr v-if="false && this.stats.feesAmount">
                        <td>Fees</td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(this.stats.feesAmount)
                                }}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="false">
                        <td>Net Total</td>
                        <td>
                            <span>
                                {{ this.stats.currency }} {{
                                    toLocaleNumber(this.stats.netGainsAmount)
                                }}
                            </span>
                        </td>
                        <td>

                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </section>
</template>
  
<script>
import { Zune } from "@/services/zune.service";

export default {
    props: {
        stats: Object
    },
    async mounted() {
        this.ready = false
        this.visible = true

        let response = await Zune.portfolioStats()
        if (response.success) {
            this.remoteStats.dividendsAmountGross = response.data.result;
        } else {
            this.visible = false
        }

        this.ready = true
    },
    data() {
        return {
            visible: true,
            ready: false,
            remoteStats: {
                currency: 'EUR',
                dividendsAmountGross: 0,
            },
        };
    },
};
</script>
  
<style scoped></style>
  