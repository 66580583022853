<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">ZuneMoney</p>
              <h1 class="title is-size-5-mobile">Net Worth Calculator</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-centered">
      <div class="column is-half">
        <NumericField v-model="flows.stocks.amount" label="Stocks" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
        <NumericField v-model="flows.cash.amount" label="Cash" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
        <NumericField v-model="flows.business.amount" label="Business" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
        <NumericField v-model="flows.crypto.amount" label="Crypto" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
      </div>
      <div class="column is-half">
        <NumericField  v-model="flows.credit.amount" label="Credit" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
        <NumericField  v-model="flows.loans.amount" label="Loans" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
        <NumericField  v-model="flows.other.amount" label="Other Debt" placeholder="Ex: $ 100.000" @input="updateChart"></NumericField>
      </div>
    </div>

    <div class="columns is-centered">
      <div class="column is-half">
        <div class="container has-text-centered">
          <p class="subtitle is-5">Your networth is:</p>
          <p class="title is-1">{{ toLocaleNumber(netWorth) }}</p>
          <!-- <p class="subtitle is-5">This is your net worth!</p> -->
        </div>

        <div class="container mt-6">
          <monthly-performance-chart ref="monthlyPerformanceChart" :flows="flows"></monthly-performance-chart>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import NumericField from "@/components/forms/NumericField";
import MonthlyPerformanceChart from "@/components/net-worth/Cashflow.vue";

export default {
  name: "Home",
  components: {
    NumericField,
    MonthlyPerformanceChart,
  },
  methods: {
    updateChart() {
      this.netWorth = Number(this.flows.stocks.amount) + Number(this.flows.cash.amount) + Number(this.flows.business.amount) + Number(this.flows.crypto.amount) - Number(this.flows.credit.amount) - Number(this.flows.loans.amount) - Number(this.flows.other.amount);
      this.$refs.monthlyPerformanceChart.update();
    },
  },
  data() {
    return {
      netWorth: 0,
      flows: {
        stocks: { amount: 0 },
        cash: { amount: 0 },
        business: { amount: 0 },
        crypto: { amount: 0 },
        credit: { amount: 0 },
        loans: { amount: 0 },
        other: { amount: 0 },
      },
    };
  },
};
</script>
