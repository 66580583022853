<template>
  <bar-chart
    :chartData="this.dividendsChart.data"
    :options="this.dividendsChart.options"
    v-if="this.ready && sortedPeriodList.length > 0"
  ></bar-chart>
</template>

<script>
import BarChart from "@/components/zuneCharts/BarChart";

export default {
  props: {
    dividends: Array,
    rates: Object,
    displayCurrency: String
  },
  components: {
    BarChart,
  },
  methods: {
    handleCurrency(fromCurrency) {
      if (fromCurrency == this.displayCurrency) {
        return 1; // default rate
      }

      return this.rates[fromCurrency + this.displayCurrency];
    },
  },
  mounted() {
    this.ready = false;
    var pastDividends = [];
    var futureDividends = [];
    var labels = new Set();

    this.dividends
    .filter((dividend) => !dividend.paymentDate.startsWith("0000-00-00"))
    .forEach((dividend) => {
      const paid = dividend.total * this.handleCurrency(dividend.currency);

      // period inn the format yyyy-MM
      var period = dividend.paymentDate.substring(0, 7);
      labels.add(period);

      const paymentDate = new Date(dividend.paymentDate);
      if (paymentDate <= Date.now()) {
        pastDividends[period] = (pastDividends[period] || 0) + paid;
      } else {
        futureDividends[period] = (futureDividends[period] || 0) + paid;
      }
    })

    this.sortedPeriodList = Array.from(labels).sort();

    this.dividendsChart.data = {
      labels: this.sortedPeriodList,
      datasets: [
        {
          label: "Received Dividends",
          backgroundColor: "#f87979",
          data: this.sortedPeriodList.map((period) => pastDividends[period] || 0),
        },
        {
          label: "Announced Dividends",
          backgroundColor: "#F8C1C1",//"#E89191",
          data: this.sortedPeriodList.map((period) => futureDividends[period] || 0),
        },
      ],
    };
    this.dividendsChart.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          ticks: {
            callback: (value) => {
              return this.displayCurrency + ' ' + this.toLocaleNumber(value);
            },
          },
          stacked: true
        }]
      }
    };
    this.ready = true;
    this.$emit("chartLoaded", true);
  },
  data() {
    return {
      ready: false,
      sortedPeriodList: [],
      dividendsChart: {},
    };
  },
};
</script>

<style scoped>
</style>