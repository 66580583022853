<template>
  <section class="section">
    <div
      class="loader-wrapper columns is-centered is-vcentered is-mobile"
      v-if="!ready"
      style="min-height: 80vh"
    >
      <div class="loader is-loading"></div>
    </div>

    <div v-if="ready">
      <!-- <div class="level level-right">
        <div class="field is-grouped is-grouped-multiline"> 
          <p class="control">
            <a class="button is-light" @click="period('-1m')" :class="{ 'is-active': activeFilters.lastMonth }">
              Last Month
            </a>
          </p>
          <p class="control">
            <a class="button is-light" @click="period('-3m')" :class="{ 'is-active': activeFilters.last3Months }">
              Last 3-Months
            </a>
          </p>
          <p class="control">
            <a class="button is-light" @click="period('-6m')" :class="{ 'is-active': activeFilters.last6Months }">
              Last 6-Months
            </a>
          </p>
          <p class="control">
            <a class="button is-light" @click="period('-1y')" :class="{ 'is-active': activeFilters.lastYear }">
              Last Year
            </a>
          </p>
          <p class="control">
            <a class="button is-light" @click="period('forever')" :class="{ 'is-active': activeFilters.forever }">
              Since dawn of humankind
            </a>
          </p>
        </div>
      </div> -->
      <div>
        <div class="field">
          <label class="label">Symbol</label>
          <div class="control">
            <input class="input" type="text" v-model="activeFilters.symbol" />
          </div>
        </div>
        <div class="field">
          <label class="label">From</label>
          <div class="control">
            <input class="input" type="date" v-model="activeFilters.from" />
          </div>
        </div>
        <div class="field">
          <label class="label">Until</label>
          <div class="control">
            <input class="input" type="date" v-model="activeFilters.until" />
          </div>
        </div>
        <div class="field buttons">
          <button class="button is-primary is-fullwidth" type="button" v-bind:class="{ 'is-loading': loadingActivity }" @click="paginate(0)">
            Search
          </button>
          <button class="button is-secondary is-fullwidth" type="button" @click="clearSearch()">
            Clear
          </button>
        </div>
      </div>
      <div
        class="loader-wrapper columns is-centered is-vcentered is-mobile"
        style="min-height: 80vh"
        v-if="loadingActivity"
      >
        <div class="loader is-loading"></div>
      </div>
      <table class="table is-fullwidth is-striped" v-if="!loadingActivity">
        <thead>
          <tr>
            <th>Date</th>
            <th class=""></th>
            <th class="level-right">Total</th>
            <th class=""></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="activity in activities" :key="activity.id">
            <td style="width:15%">
              <span class="has-text-weight-bold">{{ activity.ticker }}</span>
              <p class="is-size-7">
                {{ toLocaleDate(new Date(activity.executionDate)) }}
              </p>
            </td>
            <td>
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <div class="columns">
                      <div class="column">
                        <div class="has-text-weight-bold">{{ activity.type }}</div>
                        <div class="">{{ activity.quantity }} shares 
                          @ {{ activity.priceCurrency }} {{ toLocaleNumber(activity.price) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="level-right">

                </div> -->
              </div>
            </td>
            <td>
              <div class="level-right has-text-weight-bold">
                {{ activity.priceCurrency }} {{ toLocaleNumber(activity.price * activity.quantity) }}
              </div>
            </td>
            <td class="is-actions-cell is-hidden-mobile" style="width:15%">
                <div class="buttons is-right">
                  <button
                    v-if="!activity.isImportActivityGroup"
                    class="button is-small"
                    type="button"
                    @click="
                      deleteActivity(
                        activity.id,
                        activity.isImportActivityGroup
                      )
                    "
                  >
                    <span class="icon">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </button>
                  <button
                    v-if="activity.isImportActivityGroup"
                    class="button is-small"
                    type="button"
                    @click="
                      deleteActivity(
                        activity.id,
                        activity.isImportActivityGroup
                      )
                    "
                  >
                    <span class="icon">
                      <i class="fa fa-undo" aria-hidden="true"></i>
                    </span>
                  </button>
                  <!-- CURRENTLY DISABLED -->
                  <button
                    v-if="!activity.isImportActivityGroup && false"
                    class="button is-small"
                    type="button">
                    <span class="icon">
                      <i class="fa fa-edit" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
              </td>
          </tr>
        </tbody>
      </table>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a class="pagination-previous" @click="paginate(activeFilters.currentPage - 1)">Previous</a>
        <a class="pagination-next" @click="paginate(activeFilters.currentPage + 1)">Next page</a>
        <ul class="pagination-list">
          <li>
            <a class="pagination-link" @click="paginate(activeFilters.currentPage - 1)" v-if="activeFilters.currentPage > 0">{{ activeFilters.currentPage }}</a>
          </li>
          <li>
            <a class="pagination-link" :class="{ 'is-current': true }">{{ activeFilters.currentPage + 1}}</a>
          </li>
          <li>
            <a class="pagination-link" @click="paginate(activeFilters.currentPage + 1)" v-if="total > (activeFilters.currentPage + 1) * activeFilters.pageSize">{{ activeFilters.currentPage + 2 }}</a>
          </li>
          <li>
            <a class="pagination-link" @click="paginate(activeFilters.currentPage + 2)" v-if="total > (activeFilters.currentPage + 2) * activeFilters.pageSize">{{ activeFilters.currentPage + 3 }}</a>
          </li>
          <li>
            <span class="pagination-ellipsis" v-if="total > (activeFilters.currentPage + 3) * activeFilters.pageSize">&hellip;</span>
          </li>
          <!-- <li>
            <span class="pagination-ellipsis">&hellip;</span>
          </li>
          <li>
            <a class="pagination-link">45</a>
          </li>
          <li>
            <a class="pagination-link is-current">46</a>
          </li>
          <li>
            <a class="pagination-link">47</a>
          </li>
          <li>
            <span class="pagination-ellipsis">&hellip;</span>
          </li>
          <li>
            <a class="pagination-link">86</a>
          </li> -->
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { Zune } from "@/services/zune.service";

export default {
  components: {},
  methods: {
    clearSearch () {
      this.activeFilters.symbol = ''
      this.activeFilters.from = ''
      this.activeFilters.until = ''
      this.activeFilters.currentPage = 0

      return this.filter()
    },
    paginate (page) {
      this.activeFilters.currentPage = page
      return this.filter()
    },
    async filter () {
      this.loadingActivity = true

      var filter = {}
      if (this.activeFilters.symbol) {
        filter.symbol = this.activeFilters.symbol
      }

      if (this.activeFilters.from) {
        filter.fromDate = this.activeFilters.from
      }

      if (this.activeFilters.until) {
        filter.toDate = this.activeFilters.until
      }

      filter.offset = this.activeFilters.currentPage * this.activeFilters.pageSize
      filter.limit = this.activeFilters.pageSize

      await this.getActivity(filter);

      this.loadingActivity = false
    },
    async getActivity (filter) {
      const response = await Zune.activity(this.$route.params.portfolio, filter);
      if (response.success) {
        this.total = response.data.total;
        this.activities = response.data.activities;
      }
    },
    async fetchMetaData () {
      await this.getActivity({ includeImport: false, limit: 100 })
      // const response = await Zune.activity(this.$route.params.portfolio, { includeImport: false, limit: 100 });
      // if (response.success) {
      //   this.activities = response.data.activities;
      // }
    },
    async deleteActivityGroup(id) {
      var response = await Zune.removeActivityGroup(id);
      if (!response.success) {
        await Zune.logError(response.statusMessage + " " + response.message);
      }

      this.$emit("notifyMessage", "Removed uploaded transactions.");
      this.$emit("actionTaken", true);
    },
    async deleteActivity(id, isImportActivityGroup) {
      if (isImportActivityGroup) {
        await this.deleteActivityGroup(id);
        return;
      }

      var response = await Zune.removeActivity(id);
      if (!response.success) {
        await Zune.logError(response.statusMessage + " " + response.message);
      }

      this.$emit("notifyMessage", "Activity removed.");
      this.$emit("actionTaken", true);

      await this.filter();
    },
  },
  async mounted() {
    await this.fetchMetaData();
    this.ready = true;
  },
  data() {
    return {
      activeFilters: {
        symbol: '',
        from: '',
        until: '',
        // lastMonth: true,
        // last3Months: false,
        // last6Months: false,
        // lastYear: false,
        // forever: false,
        //currentPeriod: '-1m',
        currentPage: 0,
        pageSize: 100,
      },
      activities: [],
      total: 0,
      ready: true,
      loadingActivity: false
    };
  },
};
</script>
