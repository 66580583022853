<template>
  <!-- todo: SHELL cant be added? -->
  <!-- RELOAD ON CLOSE -->
  <div>
    <div
      v-if="!ready"
      style="min-height: 25vh"
      class="loader-wrapper columns is-centered is-vcentered is-mobile"
    >
      <div class="loader is-loading"></div>
    </div>
    <div class="card events-card mt-3" v-if="activities.length > 0">
      <header class="card-header">
        <p class="card-header-title">Recent Activity</p>
        <a href="#" class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </a>
      </header>
      <div class="card-table">
        <div class="content">
          <table class="table is-fullwidth is-striped">
            <tbody>
              <tr v-for="activity in activities" :key="activity.id">
                <td>
                  <span v-if="activity.isImportActivityGroup"
                    >Imported {{ activity.quantity }} transactions.</span
                  >
                  <span
                    v-if="
                      !activity.isImportActivityGroup && activity.type == 'SELL'
                    "
                    >Sold {{ activity.quantity }} of {{ activity.ticker }} @
                    {{ activity.priceCurrency }}
                    {{ activity.price }}</span
                  >
                  <span
                    v-if="
                      !activity.isImportActivityGroup && activity.type == 'BUY'
                    "
                    >Bought {{ activity.quantity }} of {{ activity.ticker }} @
                    {{ activity.priceCurrency }}
                    {{ activity.price }}</span
                  >
                  <p class="is-size-7">
                  {{ toLocaleDate(new Date(activity.executionDate)) }}
                  </p>
                </td>
                <td class="is-actions-cell is-hidden-mobile">
                  <div class="buttons is-right">
                    <button
                      v-if="!activity.isImportActivityGroup"
                      class="button is-small"
                      type="button"
                      @click="
                        deleteActivity(
                          activity.id,
                          activity.isImportActivityGroup
                        )
                      "
                    >
                      <span class="icon">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </button>
                    <button
                      v-if="activity.isImportActivityGroup"
                      class="button is-small"
                      type="button"
                      @click="
                        deleteActivity(
                          activity.id,
                          activity.isImportActivityGroup
                        )
                      "
                    >
                      <span class="icon">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                      </span>
                      <!-- <span>Undo</span> -->
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer class="card-footer">
        <router-link
          :to="{
            name: 'Activity',
            params: {
              portfolio
            },
          }"
          class="card-footer-item"
          >View All</router-link
        >
      </footer>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";

export default {
  props: {
    portfolio: Number,
  },
  methods: {
    async deleteActivityGroup(id) {
      var response = await Zune.removeActivityGroup(id);
      if (!response.success) {
        await Zune.logError(response.statusMessage + " " + response.message);
      }

      this.$emit("notifyMessage", "Removed uploaded transactions.");
      this.$emit("actionTaken", true);
    },
    async deleteActivity(id, isImportActivityGroup) {
      if (isImportActivityGroup) {
        await this.deleteActivityGroup(id);
        return;
      }

      var response = await Zune.removeActivity(id);
      if (!response.success) {
        await Zune.logError(response.statusMessage + " " + response.message);
      }

      this.$emit("notifyMessage", "Activity removed.");
      this.$emit("actionTaken", true);
    },
  },
  async mounted() {
    this.ready = false;
    const response = await Zune.activity(this.portfolio, { includeImport: true });
    this.activities = response.data.activities;
    this.ready = true;
  },
  data() {
    return {
      ready: false,
      activities: []
    };
  },
};
</script>

<style scoped>
</style>