<template>
  <section class="section has-background-light">
    <div class="columns">
      <div class="column">
        <!-- <h6 class="subtitle is-6">🔥 Trending</h6>
        <div class="columns">
          <div class="column">
            <ul>
                <li href="#" class="">
                  <div class="level">
                    <div class="level-left">
                      <span class="level-item">#TSLA</span>
                      <span class="level-item is-size-7">USD 450(+5.5%)</span>
                    </div>
                  </div>
                </li>
                <li href="#" class="">
                  <div class="level">
                    <div class="level-left">
                      <span class="level-item">#NFLX</span>
                      <span class="level-item is-size-7">USD 731(+2.1%)</span>
                    </div>
                  </div>
                </li>
                <li href="#" class="">
                  <div class="level">
                    <div class="level-left">
                      <span class="level-item">#AMZN</span>
                      <span class="level-item is-size-7">USD 2450(+0.3%)</span>
                    </div>
                  </div>
                </li>
                <li href="#" class="">
                  <div class="level">
                    <div class="level-left">
                      <span class="level-item">#MSFT</span>
                      <span class="level-item is-size-7">USD 130(+3.7%)</span>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </div> -->
        <h6 class="subtitle is-6" v-if="gainers && gainers.length > 0">{{ $t('community.gainers') }}</h6>
        <div class="columns">
          <div class="column">
            <div href="#" v-for="symbol in gainers" :key="symbol.symbol" class="pb-3">
              <div>
                <router-link :to="{
                  name: 'Company',
                  params: {
                    symbol: symbol.symbol,
                  },
                }" class="nav-link">
                  #{{ symbol.symbol }}
                </router-link>
              </div>
              <div class="level is-size-7">
                <div class="level-left">
                  <span>{{ symbol.currency }} {{ symbol.close }}</span>
                </div>
                <div class="level-right">
                  <span class="level-item has-text-success-dark">(+{{ toPercentNumber(symbol.changePercent) }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 class="subtitle is-6" v-if="gainers && gainers.length > 0">{{ $t('community.losers') }}</h6>
        <div class="columns">
          <div class="column">
            <div href="#" v-for="symbol in losers" :key="symbol.symbol" class="pb-3">
              <div>
                <router-link :to="{
                  name: 'Company',
                  params: {
                    symbol: symbol.symbol,
                  },
                }" class="nav-link">
                  #{{ symbol.symbol }}
                </router-link>
              </div>
              <div class="level is-size-7">
                <div class="level-left">
                  <span>{{ symbol.currency }} {{ symbol.close }}</span>
                </div>
                <div class="level-right">
                  <span class="level-item has-text-danger">({{ toPercentNumber(symbol.changePercent) }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <h6 class="subtitle is-6">👑 Top investors</h6>
        <div class="columns">
          <div class="column">
            <ul>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </div> -->
        <!-- <h6 class="subtitle is-6">🗣️ Who to follow</h6>
        <div class="columns">
          <div class="column">
            <ul>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li href="#" class="mb-3">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                      <figure class="image is-32x32">
                          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                      </figure>
                      <span class="ml-3">@alex_gomes</span>
                      </div>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="column is-three-fifths px-6">
        <div class="columns">
          <div class="column">
            <div class="post my-5 has-background-white p-5">
              <div class="tabs">
                <ul>
                  <li class="is-active"><a class="button is-white">{{ $t('community.title') }}</a></li>
                </ul>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="columns">
                    <!-- <div class="column">
                        <figure class="image is-64x64">
                            <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                        </figure>
                      </div> -->
                    <div class="column">
                      <textarea class="textarea" :placeholder="$t('community.messageInput.placeHolder')" v-model="text" v-bind:class="{
                        'is-danger': errors && !text,
                      }"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                      <button class="level-item button is-primary" @click="send">
                        <span>{{ $t('community.buttons.publish') }}</span>
                        <span class="icon is-small">
                          <i class="fa fa-paper-plane">
                          </i>
                        </span>
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div class="tabs">
              <ul>
                <!-- <li class="is-active"><a class="button is-white">Trending</a></li> -->
                <li :class="{ 'is-active': activeFeed == 'recent' }" @click="setFeed('recent')"><a
                    class="button is-white p-5">{{ $t('community.subtitle') }}</a></li>
                <!-- <li :class="{'is-active': activeFeed == 'following'}" @click="setFeed('following')"><a class="button is-white">Following 👀</a></li> -->
                <!-- <li :class="{'is-active': activeFeed == 'holdings'}" @click="setFeed('holdings')"><a class="button is-white">Holdings 💰</a></li> -->
                <!-- <li class=""><a class="button is-white">Following</a></li>
                  <li class=""><a class="button is-white">Bookmarks</a></li> -->
              </ul>
            </div>
            <div class="post my-5 has-background-white p-5" v-for="post in feed" :key="post.id">
              <div class="columns">
                <!-- <div class="column">
                    <figure class="image is-64x64">
                        <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                    </figure>
                  </div> -->
                <div class="column is-full">
                  <div class="level">
                    <div>
                      <div>
                        <!-- <router-link :to="{
                          name: 'Profile',
                          params: {
                            id: post.id,
                          },
                        }" class="nav-link"> -->
                        <span class="is-size-5">
                          {{ post.usersDisplayName }}
                        </span>
                        <!-- </router-link> -->
                        <span class="tag is-primary is-light" v-if="post.userType == 1">{{ $t('community.tags.pro') }}</span>
                      </div>
                      <div>
                        <span class="is-size-7"
                          v-if="new Date(post.createdAtUtc).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)">Today,
                          {{
                            toLocaleTime(new Date(post.createdAtUtc)) }}</span>
                        <span class="is-size-7"
                          v-if="new Date(post.createdAtUtc).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)">{{
                            toLocaleDateTime(new Date(post.createdAtUtc)) }}</span>
                      </div>
                    </div>
                    <div class="level-right" v-if="session.userId == post.userAccount">
                      <button class="level-item button is-danger is-light" @click="removePost(post)">
                        <span class="icon is-small">
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div style="white-space: pre-line" v-html="renderHtmlText(post.htmlText)"></div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                      <!-- <p class="buttons">
                          <button class="button is-link is-light">TSLA</button>
                          <button class="button is-link is-light">NFLX</button>
                          <button class="button is-link is-light">AAPL</button>
                        </p> -->
                      <div class="level-right">
                        <small v-if="post.likes > 1">{{ post.likes }} {{ $t('community.likes') }}</small>
                        <small v-if="post.likes == 1">1 {{ $t('community.like') }}</small>
                      </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                      <button v-if="session.userId !== post.userAccount" class="level-item button"
                        :class="{ 'is-link': post.liked }" @click="sendLike(post)">
                        <span class="icon is-small">
                          <i class="fa fa-thumbs-up"></i>
                        </span>
                        <span v-if="!post.liked">{{ $t('community.likeCamelCase') }}</span>
                        <span v-if="post.liked">{{ $t('community.likedCamelCase') }}</span>
                      </button>
                      <!-- <button class="level-item button" v-if="!post.commentOption" @click="setCommentOption(post)">
                          <span class="icon is-small">
                            <i class="fa fa-comment"></i>
                          </span>
                        </button>
                        <div class="level-item" v-if="post.commentOption">
                          <div class="field has-addons">
                            <div class="control has-icons-left">
                              <input type="text" class="input" placeholder="Comment ...">
                              <span class="icon is-left">
                                <i class="fa fa-comment"></i>
                              </span>
                            </div>
                            <div class="control">
                                <button class="level-item button">
                                  <span class="icon is-small">
                                    <i class="fa fa-send"></i>
                                  </span>
                                </button>
                            </div>
                          </div>
                        </div> -->
                      <!-- <button class="level-item button">
                          <span class="icon is-small">
                            <i class="fa fa-share"></i>
                          </span>
                        </button> -->
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <!-- Quotes -->
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

import { Community } from "@/services/community.service";
// import { Zune } from "@/services/zune.service";

export default {
  name: "Community",
  methods: {
    setCommentOption(post) {
      post.commentOption = true
    },
    renderHtmlText(postContents) {
      // Replace hashtags with clickable links
      const htmlTextWithLinks = this.replaceHashtagsWithLinks(postContents);
      return htmlTextWithLinks;
    },
    replaceHashtagsWithLinks(text) {
      // Define the regex pattern to match texts with a dollar sign
      const dollarPattern = /\$(\w+)/g;
      const dollarReplacedText = text.replace(dollarPattern, '<a href="https://app.zune.money/company/$1">$$$1</a>');
      return dollarReplacedText;
      //const pattern = /#(\w+)/g;
      //return text.replace(pattern, '<a href="https://app.zune.money/company/$1">#$1</a>');
    },
    setFeed(selected) {
      this.activeFeed = selected;
    },
    async getFeed() {
      const response = await Community.getFeed()
      if (response.success) {
        this.feed = response.data.feed.map(post => ({
          ...post,
          commentOption: false
        }))
        //this.feed = response.data.feed.mp
        this.session = {
          userId: response.data.userId
        }
      } else {
        console.error('something bad happened')
      }
    },
    async sendLike(post) {

      if (!post.liked) {
        const response = await Community.sendLike(post.id)
        if (response.success) {
          post.likes++;
          post.liked = true;
        } else {
          // console.error('something bad happened')
        }
      } else {
        const response = await Community.sendDislike(post.id)
        if (response.success) {
          post.likes--;
          post.liked = false;
        } else {
          // console.error('something bad happened')
        }
      }
    },
    async send() {
      const response = await Community.post({
        message: this.text
      })
      if (response.success) {
        await this.getFeed();
        this.text = ''
      } else {
        console.error('something bad happened')
      }
    },
    async removePost(post) {
      const response = await Community.remove(post.id)
      if (response.success) {
        await this.getFeed();
      } else {
        console.error('something bad happened')
      }
    },
    async getGainers() {
      // const response = await Zune.gainers()
      // if (response.success) {
      //   this.gainers = response.data
      // } else {
      //   this.gainers = []
      // }
    },
    async getLosers() {
      // const response = await Zune.losers()
      // if (response.success) {
      //   this.losers = response.data
      // } else {
      //   this.losers = []
      // }
    },
  },
  async mounted() {
    await this.getGainers();
    await this.getLosers();
    await this.getFeed();
  },
  data() {
    return {
      activeFeed: 'recent',
      session: undefined,
      errors: undefined,
      text: '',
      feed: [],
      gainers: [],
      losers: []
    };
  },
};
</script>
