<template>
  <div class="modal" v-bind:class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Manage Cash</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading has-text-weight-bold">Currency</p>
              <p>
                {{ position.currency }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading has-text-weight-bold">Total</p>
              <p>
                {{ position.currency }}
                {{ toLocaleNumber(position.amount) }}
              </p>
            </div>
          </div>
        </div>
        <div class="tabs">
          <ul>
            <li class="is-active"><a>Update Position</a></li>
          </ul>
        </div>
        <div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">New Amount</label>
                    <div class="control">
                      <input class="input" type="number" step="0.01" v-model="newTransaction.amount" v-bind:class="{
                        'is-danger': errors && !newTransaction.amount,
                      }" />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">As Of Date</label>
                    <div class="control">
                      <input class="input" type="date" v-model="newTransaction.executionDate" v-bind:class="{
                        'is-danger': errors && !newTransaction.executionDate,
                      }" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content: end">
        <button class="button" @click="closeModal()">Close</button>
        <button
          class="button is-small is-primary"
          type="button"
          @click="addCash()"
        >
          UPDATE
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Zune } from "@/services/zune.service";

export default {
  props: {
    portfolioId: Number
  },
  methods: {
    actionTaken(msg) {
      this.$emit("actionTaken", true);
      this.$emit("notifyMessage", msg);
    },
    async addCash() {
      const response = await Zune.updateCash(
          this.portfolioId,
          {
            ticker: 'CASH',
            exchange: 'ZUNE',
            priceCurrency: this.newTransaction.currency,
            price: this.newTransaction.amount,
            quantity: 1,
            executionDate: this.newTransaction.executionDate,
            type: 'CASH'
          }
        );
        this.isReadyBuy = true;

        if (response.success) {
          this.actionTaken(`Added ${this.newTransaction.currency} ${this.newTransaction.amount}`);
          this.closeModal()
        }
    },
    openModal(position, onModalOpen) {
      this.position = position
      this.newTransaction = {
        amount: this.position.amount,
        executionDate: this.position.executionDate,
        currency: this.position.currency
      }

      this.isActive = true;
      onModalOpen();
    },
    closeModal() {
      this.isActive = false;
      this.position = {};
      this.newTransaction = {};
      this.errors = false;
    },
  },
  data() {
    return {
      position: {},
      newTransaction: {},
      transactions: [],
      isActive: false,
      errors: false,
    };
  },
};
</script>

<style scoped>
</style>