<template>
  <section class="section">
    <div class="loader-wrapper columns is-centered is-vcentered is-mobile" v-if="!ready" style="min-height: 80vh">
      <div class="loader is-loading"></div>
    </div>
    <div v-if="ready">
      <!-- <div class="notification is-success is-light">
        <button class="delete" @click="onClosed"></button>
        <slot></slot>
      </div> -->

      <!-- SUMMARY -->
      <div class="columns">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">{{ $t('dashboard.portfolioValue') }}</p>
                <p class="title is-size-5-mobile">
                  <span class="is-size-5">{{ this.portfolio.currency }}</span>
                  {{ toLocaleNumber(this.portfolioActualValue) }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered is-hidden-mobile">
              <div>
                <p class="heading">{{ $t('app.gains') }}</p>
                <p class="title is-size-5-mobile">
                  <span :class="{
                    'has-text-success-dark':
                      this.portfolioActualValue -
                      this.portfolioOriginalValue >
                      0,
                    'has-text-danger-dark':
                      this.portfolioActualValue -
                      this.portfolioOriginalValue <
                      0,
                  }">
                    <span class="is-size-5">{{
                      this.portfolio.currency
                    }}</span>
                    {{
                      toLocaleNumber(
                        this.portfolioActualValue - this.portfolioOriginalValue
                      )
                    }}</span>
                  <span class="px-3 is-size-5" :class="{
                        'has-text-success-dark': this.portfolioAggregatedReturn > 0,
                        'has-text-danger-dark': this.portfolioAggregatedReturn < 0,
                      }">
                    <span v-if="this.portfolioAggregatedReturn > 0">+{{
                      parseFloat(this.portfolioAggregatedReturn).toFixed(2)
                    }}%</span>
                    <span v-if="this.portfolioAggregatedReturn < 0">{{
                      parseFloat(this.portfolioAggregatedReturn).toFixed(2)
                    }}%</span>
                  </span>
                </p>
              </div>
            </div>
            <!-- <div class="level-item has-text-centered is-hidden-mobile">
              <div>
                <p class="heading">Dividends (12-month)</p>
                <p class="title is-size-5-mobile">
                  <span class="is-size-5">{{ this.portfolio.currency }}</span>
                  {{ toLocaleNumber(this.dividendAmount) }}
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- / SUMMARY END -->

      <!-- PORTFOLIO VIEW -->
      <div id="portfolio" v-if="hasHoldings">
        <!-- PERIOD CHARTS -->
        <div class="level is-hidden-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="buttons">
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'week' }"
                @click="period('week')">
                {{ $t('app.charts.week').toUpperCase() }}
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '3-months' }"
                @click="period('3-months')">
                {{ $t('app.charts._3Months').toUpperCase() }}
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '12-months' }"
                @click="period('12-months')">
                {{ $t('app.charts._12Months').toUpperCase() }}
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '3-years' }"
                @click="period('3-years')">
                {{ $t('app.charts._3Years').toUpperCase() }}
              </button>
              <!-- <button
                type="button"
                class="button is-white"
                :class="{ 'is-light': selectedPeriod == '5-years' }"
                @click="period('5-years')"
              >
                5-YEARS
              </button> -->
            </div>
          </div>
        </div>
        <div class="columns">
          <!-- todo: cash in EUR is not accounted when portfolio is in BRL. -->
          <doughnut-chart :totalValue="this.portfolioActualValue"
            :invested="this.portfolioOriginalValue - this.cashTotal - this.realEstateTotal" :cash="this.cashTotal"
            :realEstate="this.realEstateTotal" :gains="this.portfolioActualValue - this.portfolioOriginalValue"
            :dividends="this.dividendAmount" v-if="this.portfolioActualValue"
            class="column is-one-third"></doughnut-chart>
          <div class="columns is-hidden-tablet">
            <div class="column">
              <div class="level is-mobile">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">{{ $t('app.gains') }}</p>
                    <p class="title is-size-5-mobile">
                      <span class="is-white">
                        {{ this.portfolio.currency }}
                        {{
                          toLocaleNumber(
                            this.portfolioActualValue -
                            this.portfolioOriginalValue
                          )
                        }}</span>
                    </p>
                  </div>
                </div>
                <!-- <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Dividends (12-month)</p>
                    <p class="title is-size-5-mobile">
                      {{ this.portfolio.currency }}
                      <span>{{ toLocaleNumber(this.dividendAmount) }}</span>
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <!-- MOBILE ONLY -->
          <div class="column">
            <div class="level is-hidden-tablet">
              <div class="level-left"></div>
              <div class="level-right">
                <div class="buttons is-centered">
                  <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'week' }"
                    @click="period('week')">
                    {{ $t('app.gains').toUpperCase() }}
                  </button>
                  <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '3-months' }"
                    @click="period('3-months')">
                    {{ $t('dashboard._3months').toUpperCase() }}
                  </button>
                  <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '12-months' }"
                    @click="period('12-months')">
                    {{ $t('dashboard._12months').toUpperCase() }}
                  </button>
                  <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '3-years' }"
                    @click="period('3-years')">
                    {{ $t('dashboard._3years').toUpperCase() }}
                  </button>
                  <!-- <button
                    type="button"
                    class="button is-white"
                    :class="{ 'is-light': selectedPeriod == '5-years' }"
                    @click="period('5-years')"
                  >
                    5-YEARS
                  </button> -->
                </div>
              </div>
            </div>
            <div class="columns">
              <timeline-chart class="column" :portfolio="this.portfolio.id"
                :period="this.selectedPeriod"></timeline-chart>
            </div>
          </div>
        </div>
        <!-- / PERIOD CHARTS END -->
        <div class="columns">
          <div class="column is-4">
            <upcoming-dividends-panel :displayCurrency="this.portfolio.currency" v-if="false"
              :rates="this.rates"></upcoming-dividends-panel>
            <!-- <upcoming-dividends-market-panel v-if="false"></upcoming-dividends-market-panel> -->
            <activity-panel class="mt-3" :portfolio="portfolio.id" @notifyMessage="onNotifyMessage"
              @actionTaken="onActionTaken"></activity-panel>
          </div>
          <div class="column is-8">
            <monthly-performance-chart :portfolio="this.portfolio.id"
              :period="this.selectedPeriod"></monthly-performance-chart>

            <portfolio-stats v-if="false && portfolio.stockHoldings && portfolio.stockHoldings.length > 0"
              :stats="this.portfolio"></portfolio-stats>

            <div class="card events-card mt-3" v-if="portfolio.cashHoldings && portfolio.cashHoldings.length > 0">
              <header class="card-header">
                <p class="card-header-title">💵 {{ $t('app.cash') }}</p>
              </header>
              <div class="card-table">
                <div class="content">
                  <table class="
                      table
                      is-fullwidth is-striped is-hoverable is-fullwidth
                    ">
                    <thead>
                      <tr class="is-hidden-mobile">
                        <th></th>
                        <th></th>
                        <th>{{ $t('app.relativeSize') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cashHolding in portfolio.cashHoldings" :key="cashHolding.currency">
                        <td data-label="Quantity" style="width:35%">
                          {{ cashHolding.currency }} {{ toLocaleNumber(cashHolding.amount) }}
                        </td>
                        <td data-label="Quantity" style="width:35%">
                          {{ portfolio.currency }} {{ toLocaleNumber(cashHolding.amount *
                            handleCurrency(cashHolding.currency)) }}
                        </td>
                        <td data-label="RelativeSize">
                          {{
                            toLocaleNumber((cashHolding.amount * handleCurrency(cashHolding.currency)) /
                              portfolioActualValue * 100) + "%"
                          }}
                        </td>
                        <td class="is-actions-cell is-hidden-mobile">
                          <div class="buttons is-right">
                            <button class="button is-small" type="button" @click="buyCash(cashHolding)">
                              <span class="icon"><i class="fa fa-edit" aria-hidden="true"></i></span>
                            </button>
                            <button class="button is-small" type="button" @click="removeCash(cashHolding)">
                              <span class="icon"><i class="fa fa-trash" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <footer class="card-footer">
                <!-- <a href="#" class="card-footer-item">View All</a> -->
              </footer>
            </div>
            <!-- <div class="tabs">
              <ul>
                <li>
                  <a class="button is-white" @click="swapModal('stocks')">📈 Stocks</a>
                </li>
                <li class="is-active">
                  <a class="button is-white">💵 Cash</a>
                </li>
                <li>
                  <a class="button is-white" @click="swapModal('realestate')">🏘️ Real Estate</a>
                </li>
              </ul>
            </div> -->

            <div class="card events-card mt-3"
              v-if="portfolio.realEstateHoldings && portfolio.realEstateHoldings.length > 0">
              <header class="card-header">
                <p class="card-header-title">🏠 {{ $t('app.realEstate') }}</p>
              </header>
              <div class="card-table">
                <div class="content">
                  <table class="
                      table
                      is-fullwidth is-striped is-hoverable is-fullwidth
                    ">
                    <thead>
                      <tr class="is-hidden-mobile">
                        <th></th>
                        <th></th>
                        <th>{{ $t('app.relativeSize') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="realEstateHolding in portfolio.realEstateHoldings" :key="realEstateHolding.currency">
                        <td data-label="Name" style="width:20%">
                          {{ realEstateHolding.name }}
                        </td>
                        <td data-label="Value" style="width:30%">
                          <span>{{ realEstateHolding.currency }} {{ toLocaleNumber(realEstateHolding.amount) }}</span>
                          <br />
                          <span v-if="portfolio.currency !== realEstateHolding.currency" class="is-size-7">{{
                            portfolio.currency }} {{ toLocaleNumber(realEstateHolding.amount *
    handleCurrency(realEstateHolding.currency)) }}</span>
                        </td>
                        <td data-label="RelativeSize">
                          {{
                            toLocaleNumber((realEstateHolding.amount * handleCurrency(realEstateHolding.currency)) /
                              portfolioActualValue * 100) + "%"
                          }}
                        </td>
                        <td class="is-actions-cell is-hidden-mobile">
                          <div class="buttons is-right">
                            <button class="button is-small" type="button" @click="buyRealEstate(realEstateHolding)">
                              <span class="icon"><i class="fa fa-edit" aria-hidden="true"></i></span>
                            </button>
                            <button class="button is-small" type="button" @click="removeRealEstate(realEstateHolding)">
                              <span class="icon"><i class="fa fa-trash" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <footer class="card-footer">
                <!-- <a href="#" class="card-footer-item">View All</a> -->
              </footer>
            </div>
            <div class="card events-card mt-3" v-if="portfolio.invalidAssets && portfolio.invalidAssets.length > 0">
              <header class="card-header">
                <p class="card-header-title">📈 {{ $t('dashboard.invalidTransactionsTitle') }}</p>
              </header>
              <div class="card-table">
                <div class="content">
                  <table class="
                      table
                      is-fullwidth is-striped is-hoverable is-fullwidth
                    ">
                    <thead>
                      <tr>
                        <td colspan="3">
                          <small>{{ $t('dashboard.invalidTransactionsMessage') }}</small>
                        </td>
                      </tr>
                      <tr class="is-hidden-mobile">
                        <th>{{ $t('app.product') }}</th>
                        <th>{{ $t('app.isin') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="invalidAsset in portfolio.invalidAssets" :key="invalidAsset.id">
                        <td data-label="Product" style="width:90%">
                          {{ invalidAsset.name }}
                        </td>
                        <td data-label="isin" style="width:10%">
                          <span>{{ invalidAsset.key }}</span>
                        </td>
                        <td data-label="key" style="width:10%">
                          <div class="buttons is-right">
                            <button class="button is-warning" type="button" @click="configureAsset(invalidAsset)">
                              <span class="icon"><i class="fa fa-exclamation" aria-hidden="true"></i></span>
                              <span>{{ $t('app.fixIt') }}</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <footer class="card-footer">
                <!-- <a href="#" class="card-footer-item">View All</a> -->
              </footer>
            </div>
            <div class="card events-card mt-3" v-if="portfolio.stockHoldings && portfolio.stockHoldings.length > 0">
              <header class="card-header">
                <p class="card-header-title">📈 {{ $t('app.stocks') }}</p>
                <!-- <a href="#" class="card-header-icon" aria-label="more options">
                  <span class="icon">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </span>
                </a> -->
              </header>
              <div class="card-table">
                <div class="content">
                  <table class="
                      table
                      is-fullwidth is-striped is-hoverable is-fullwidth
                    ">
                    <thead>
                      <tr class="is-hidden-mobile">
                        <!-- <th></th> -->
                        <th @click="sort('ticker')">{{ $t('app.symbol') }}</th>
                        <th @click="sort('size')">{{ $t('app.quantity') }}</th>
                        <th @click="sort('averagePrice')">{{ $t('app.avgPrice') }}</th>
                        <!-- <th>Invested</th> -->
                        <th @click="sort('lastKnownPrice')">{{ $t('app.price') }}</th>
                        <th @click="sort('actualTotalAmount')">{{ $t('app.total') }}</th>
                        <th @click="sort('gains')">{{ $t('app.gains') }}</th>
                        <th @click="sort('totalAmountLocalCurrency')">{{ $t('app.relativeSize') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="position in sortedData" :key="position.ticker">
                        <td data-label="Symbol">
                          <router-link :to="{
                            name: 'Company',
                            params: {
                              symbol: position.ticker,
                            },
                          }" class="nav-link">
                            <span>{{ position.ticker }}</span>
                          </router-link>
                          <span class="is-hidden-tablet is-block">
                            <span v-if="position.lastKnownPrice" class="is-size-7">{{ position.priceCurrency }} </span>
                            <span v-if="position.lastKnownPrice">{{ toLocaleNumber(position.lastKnownPrice) }}</span>
                            <span class="is-size-7">&nbsp;x {{ position.size }}</span></span>
                        </td>
                        <td data-label="Quantity" class="is-hidden-mobile">
                          {{ position.size }}
                        </td>
                        <td data-label="Average" class="is-hidden-mobile" v-if="position.size > 0">
                          <span class="is-size-7">{{ position.priceCurrency }}</span>
                          {{ toLocaleNumber(position.averagePrice) }}
                        </td>
                        <td data-label="Average" class="is-hidden-mobile" v-if="position.size <= 0">
                          -
                        </td>
                        <td data-label="Price" class="is-hidden-mobile">
                          <span v-if="position.lastKnownPrice" class="is-size-7">{{ position.priceCurrency }}</span>
                          <span v-if="position.lastKnownPrice">
                            {{
                              position.lastKnownPrice &&
                              toLocaleNumber(position.lastKnownPrice)
                            }}
                          </span>
                        </td>
                        <td data-label="Total" class="has-text-right-mobile">
                          <span class="is-block is-hidden-tablet">&nbsp;</span>
                          <span v-if="position.lastKnownPrice" class="is-size-7">{{ position.priceCurrency }}</span>
                          <span v-if="position.lastKnownPrice">
                            {{
                              position.lastKnownPrice &&
                              toLocaleNumber(
                                position.size * position.lastKnownPrice
                              )
                            }}
                          </span>
                        </td>
                        <td data-label="Gains" class="is-hidden-mobile" :class="{
                              'has-text-success has-text-weight-bold':
                                position.gains > 0,
                              'has-text-danger has-text-weight-bold':
                                position.gains < 0,
                            }">
                          <span v-if="position.lastKnownPrice" class="is-size-7">{{ position.priceCurrency }}</span>
                          <span v-if="position.lastKnownPrice" :class="{ blink: position.blink }">
                            {{
                              position.gains &&
                              toLocaleNumber(
                                position.gains,
                                {
                                  signDisplay: "exceptZero",
                                }
                              )
                            }}
                          </span>
                        </td>
                        <td data-label="RelativeSize" class="is-size-7 is-hidden-mobile has-text-centered">
                          {{
                            position.lastKnownPrice &&
                            toLocaleNumber(
                              (position.totalAmountLocalCurrency / portfolioActualValue) * 100) + "%"
                          }}
                          <progress v-if="position.lastKnownPrice" class="progress is-small"
                            :value="(position.totalAmountLocalCurrency / portfolioActualValue) * 100"
                            max="100"></progress>
                        </td>
                        <td class="is-actions-cell is-hidden-mobile">
                          <div class="buttons is-right">
                            <button class="button is-small" type="button" @click="buy(position)">
                              <span class="icon"><i class="fa fa-edit" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <footer class="card-footer">
                <!-- <a href="#" class="card-footer-item">View All</a> -->
              </footer>
            </div>
          </div>
          <!-- footer spacing -->
          <div class="column pb-6 mb-6" style="visibility: hidden"></div>
          <!-- / footer spacing-->
        </div>
      </div>
      <!-- / PORTFOLIO VIEW -->

      <!-- EMPTY PORTFOLIO -->

      <!-- UPDATE THESE WITH INTERESTING HOLDINGS AND THINGS THAT ARE HAPPENING -->
      <div v-if="!hasHoldings">
        <div class="columns">
          <div class="column has-text-centered">
            <p>{{ $t('app.emptyPortfolioMessage') }}</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="level">
              <div class="level-item has-text-centered">
                <button class="button is-primary has-text-weight-bold" @click="swapModal('stocks')">
                  🚀 <span class="ml-2">{{ $t('dashboard.createPortfolio') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / EMPTY PORTFOLIO -->
    </div>
    <progress-bar ref="progressBar"></progress-bar>
    <manage-position ref="positionManager" :portfolioId="portfolio.id" @actionTaken="onActionTaken"></manage-position>
    <manage-invalid-position ref="invalidPositionManager" :portfolioId="portfolio.id"
      @actionTaken="onActionTaken"></manage-invalid-position>
    <manage-position-cash ref="positionManagerCash" :portfolioId="portfolio.id" @notifyMessage="onNotifyMessage"
      @actionTaken="onActionTaken"></manage-position-cash>
    <manage-position-real-estate ref="positionManagerRealEstate" :portfolioId="portfolio.id"
      @notifyMessage="onNotifyMessage" @actionTaken="onActionTaken"></manage-position-real-estate>
  </section>
</template>

<script>
//import DoughnutPercentChart from "./zuneCharts/DoughnutPercentChart";

// doughnut chart not loading real estates in different currencies
import TimelineChart from "./dashboardComponents/TimelineChart";
import MonthlyPerformanceChart from "./dashboardComponents/MonthlyPerformanceChart.vue";
import DoughnutChart from "./zuneCharts/DoughnutChart";

import ManageInvalidPosition from "./managePosition/ManageInvalidPosition";
import ManagePosition from "./ManagePosition";
import ManagePositionCash from "./ManagePositionCash";
import ManagePositionRealEstate from "./managePosition/ManagePositionRealEstate";
import ProgressBar from "./ProgressBar";

import ActivityPanel from "./dashboardComponents/ActivityPanel.vue";
import UpcomingDividendsPanel from "./dashboardComponents/UpcomingDividendsPanel.vue";

import PortfolioStats from "./dashboard/PortfolioStats.vue";
// import UpcomingDividendsMarketPanel from "./dashboardComponents/UpcomingDividendsMarketPanel.vue";

import datasort from "./../ux/datasort"

import { Zune } from "@/services/zune.service";

export default {
  props: {
    appContext: Object,
  },
  computed: {
    actionTaken() {
      return this.appContext.actionTaken;
    },
    sortedData() {
      return datasort(this.sortColumn, this.sortDirection, this.portfolio.stockHoldings)
    },
    hasHoldings() {
      if (this.portfolio == null) {
        return false;
      }

      if (this.portfolio.hasHoldings) {
        return true;
      }

      return false;
    }
  },
  watch: {
    actionTaken() {
      this.onActionTaken();
    },
  },
  components: {
    TimelineChart,
    MonthlyPerformanceChart,
    //DoughnutPercentChart,
    DoughnutChart,
    ManagePosition,
    ManagePositionCash,
    ProgressBar,
    ActivityPanel,
    UpcomingDividendsPanel,
    //UpcomingDividendsMarketPanel,
    ManagePositionRealEstate,
    ManageInvalidPosition,
    PortfolioStats
  },
  methods: {
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }

      this.portfolio.stockHoldings = this.sortedData
    },
    swapModal(selected) {
      this.$emit("swapModal", selected);
    },
    onNotifyMessage(msg) {
      this.$emit("notifyMessage", msg);
    },
    onForwardMessage(type, msg) {
      this.$emit(type, msg);
    },
    async onActionTaken() {

      this.initiateState();

      await this.fetchMetaData();

      if (this.hasHoldings) {
        await this.calculatePortfolioOverview();
      }
    },
    async period(period) {
      // if (this.$store.state.account.type === 1 || (period !== '12-months' && period !== '3-years')) {
      //   this.selectedPeriod = period;
      // } else if (this.$store.state.account.appVersion === '1.0.0' && period === '12-months') {
      //   this.selectedPeriod = period;
      // } else {
      //   this.$router.push('/premium')
      // }
      if (this.$store.state.accountType === 1 || (period !== '3-years')) {
        this.selectedPeriod = period;
      } else {
        this.$router.push('/premium')
      }
    },
    buy(position) {
      this.$refs.progressBar.start();
      this.$refs.positionManager.openModal(position.ticker, () =>
        this.$refs.progressBar.finish()
      );
    },
    buyCash(cashHolding) {
      this.$refs.progressBar.start();
      this.$refs.positionManagerCash.openModal(cashHolding, () =>
        this.$refs.progressBar.finish()
      );
    },
    async removeCash(cashHolding) {
      const response = await Zune.removeCash(this.portfolio.id, cashHolding)
      if (response.success) {
        this.onNotifyMessage(`${cashHolding.currency} ${cashHolding.amount} was removed.`)
        await this.onActionTaken()
      }
    },
    buyRealEstate(realEstateHolding) {
      this.$refs.progressBar.start();
      this.$refs.positionManagerRealEstate.openModal(realEstateHolding, () =>
        this.$refs.progressBar.finish()
      );
    },
    async removeRealEstate(realEstateHolding) {
      const response = await Zune.removeRealEstate(this.portfolio.id, realEstateHolding)
      if (response.success) {
        this.onNotifyMessage(`${realEstateHolding.name} was removed.`)
        await this.onActionTaken()
      }
    },
    configureAsset(position) {
      this.$refs.progressBar.start();
      this.$refs.invalidPositionManager.openModal(position, () =>
        this.$refs.progressBar.finish()
      );
    },
    async fetchMetaData() {
      const portfolioOverview = await Zune.portfolio();

      if (portfolioOverview.data.message !== "NOT_FOUND") {
        this.portfolio = portfolioOverview.data.result;

        if (this.portfolio.hasHoldings) {
          this.portfolio.stockHoldings.forEach(
            (position) => (position.blink = false)
          );

          this.rates = portfolioOverview.data.result.rates;
        }
      }

      this.$emit("loaded", this.portfolio);
      this.ready = true;
    },
    calculatePortfolioOverview() {
      for (const position of this.portfolio.stockHoldings) {

        if (position.lastKnownPrice === undefined || position.lastKnownPrice === null) {
          continue;
        }

        var currencyRate = this.handleCurrency(position.priceCurrency);

        position.originalValueLocalCurrency = position.totalAmount * currencyRate;
        position.actualTotalAmount = position.lastKnownPrice * position.size;
        position.totalAmountLocalCurrency = position.lastKnownPrice * position.size * currencyRate;
        position.gains = (position.lastKnownPrice * position.size) - position.totalAmount;

        this.portfolioOriginalValue += position.originalValueLocalCurrency;
        this.portfolioActualValue += position.totalAmountLocalCurrency;

        // position.dividends.forEach((dividend) => {
        //   this.dividendAmount += dividend.amount * position.size * currencyRate;

        //   // if (Date.now() < new Date(dividend.paymentDate)) {
        //   //   dividend.positionSize = position.size;
        //   //   dividend.positionName = position.ticker;
        //   //   dividend.totalAmount = position.size * dividend.amount;

        //   //   this.nextDividends.push(dividend);
        //   // }
        // });
      }

      if (this.portfolio.cashHoldings) {
        for (const cash of this.portfolio.cashHoldings) {
          currencyRate = this.handleCurrency(cash.currency);

          var cashAmount = cash.amount * currencyRate;

          this.portfolioOriginalValue += cashAmount;
          this.portfolioActualValue += cashAmount;
          this.cashTotal += cashAmount;
        }
      }

      if (this.portfolio.realEstateHoldings) {
        for (const realEstate of this.portfolio.realEstateHoldings) {
          currencyRate = this.handleCurrency(realEstate.currency);

          var realEstateValue = realEstate.amount * currencyRate;

          this.portfolioOriginalValue += realEstateValue;
          this.portfolioActualValue += realEstateValue;
          this.realEstateTotal += realEstateValue;
        }
      }

      // if (this.portfolio.invalidHoldings) {
      //   var invalidHoldings = {};
      //   for (const invalidHolding of this.portfolio.invalidHoldings) {
      //     if (!invalidHoldings[invalidHolding.isin]) {
      //       invalidHoldings[invalidHolding.isin] = {
      //         isin: invalidHolding.isin,
      //         product: invalidHolding.product,
      //         transactions: []
      //       }
      //     }

      //     invalidHoldings[invalidHolding.isin].transactions.push(invalidHolding)
      //   }

      //   this.portfolio.invalidHoldings = invalidHoldings;
      // }

      this.portfolioAggregatedReturn = (this.portfolioActualValue / this.portfolioOriginalValue - 1) * 100;
    },
    async autoUpdateQuotes(session) {
      this.wsConnectionManager.connection = {};
      this.wsConnectionManager.send = (message) => {
        if (this.wsConnectionManager.connection) {
          this.wsConnectionManager.connection.send(message);
        } else {
          console.log("connection couldn't be made");
        }
      };

      var wsConnectionHandler = {
        onmessage: (event) => {
          this.wsConnectionManager.send("accepted");

          this.portfolio.stockHoldings.forEach((holding) => {
            var message = JSON.parse(event.data);

            var lastKnownPrice = message[holding.ticker];
            if (lastKnownPrice != holding.lastKnownPrice && lastKnownPrice) {
              // blink ;)
              holding.blink = true;
              setTimeout(function () {
                holding.blink = false;
              }, 2000);

              holding.lastKnownPrice = lastKnownPrice;
            }
          });
        },
        onopen: () => {
          // console.log(event);
          // console.log("Successfully connected to the zune server...");
          this.wsConnectionManager.send("accepted");
        },
        onclose: () => {
          // console.log(event);
          // console.log("Closing connection...");
        },
        onerror: () => {
          console.log("Error occurred...");
        },
      };

      this.wsConnectionManager.connection = await Zune.connect(
        wsConnectionHandler,
        session.data
      );
    },
    handleCurrency(fromCurrency) {
      if (fromCurrency == this.portfolio.currency) {
        return 1; // default rate
      }

      return this.rates[fromCurrency + this.portfolio.currency];
    },
    initiateState() {

      // add new flags here
      this.ready = false;
      this.portfolio = {};
      this.portfolioActualValue = 0;
      this.portfolioOriginalValue = 0;
      this.portfolioAggregatedReturn = 0;
      this.cashTotal = 0;
      this.realEstateTotal = 0;
      this.dividendAmount = 0;
      this.selectedPeriod = "3-months";
    },
  },
  async mounted() {
    var init = [
      Zune.session(), // init user analytics
      this.fetchMetaData(), // load dashboard
      ///this.mismatch()
    ]
    await Promise.all(init)

    if (this.hasHoldings) {
      this.calculatePortfolioOverview();
      // this.autoUpdateQuotes(session);
    }

    if (!this.portfolio.id) {
      this.$gtm.trackEvent({
        event: 'addPortfolio', // Event type [default = 'interaction'] (Optional)
        category: 'Subscribe',
        action: 'click',
        label: 'Home page',
        value: 1,
        noninteraction: false, // Optional
      });
    }
  },
  data() {
    return {
      sortColumn: "ticker",
      sortDirection: "asc",
      wsConnectionManager: {},
      portfolio: {},
      portfolioActualValue: 0,
      portfolioOriginalValue: 0,
      portfolioAggregatedReturn: 0,
      cashTotal: 0,
      realEstateTotal: 0,
      rates: {},
      dividendAmount: 0,
      selectedPeriod: "3-months",
      debug: false,
      ready: false,
    };
  },
};
</script>

<style>
/* .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
} */

.is-active {
  opacity: 1;
  z-index: 1;
}

.loader {
  height: 80px;
  width: 80px;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  -webkit-animation: blinker 1s 1;
  -moz-animation: blinker 1s 1;
  animation: blinker 1s 1;
}
</style>
