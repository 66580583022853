<template>
  <transition>
    <div
      class="is-clearfix"
      style="
        z-index: 100;
        float: right;
        bottom: 5vh;
        right: 5vh;
        width: 100%;
        position: fixed;
      "
    >
      <div class="columns">
        <div class="column is-fourth-fifths"></div>
        <div class="column is-one-fifth notification is-primary">
          <!-- <button class="delete"></button> -->
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {},
  props: {
    message: String
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.enter-from {
  opacity: 0;
}

.enter-to {
  opacity: 1;
}

.enter-active {
  transition: opacity 2s ease;
}
</style>