<template>
  <Dashboard 
    @notifyMessage="onNotifyMessage" 
    @notifyMounted="onNotifyMounted" 
    @swapModal="onSwapModal"
    @loaded="onLoaded"
    :appContext="appContext" />
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "Home",
  props: {
    appContext: Object
  },
  components: {
    Dashboard,
  },
  methods: {
    onSwapModal(msg) {
      this.$emit("swapModal", msg);
    },
    onNotifyMessage(msg) {
      this.$emit("notifyMessage", msg);
    },
    onNotifyMounted(args) {
      this.$emit("notifyMounted", args);
    },
    onLoaded(args) {
      this.$emit("loaded", args);
    },
  },
  data() {
    return {
      
    };
  },
};
</script>
